import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Filter } from 'src/app/views/shared/models/filter';
import { Labels } from 'src/app/views/shared/models/labels';
import { PaginatedResult } from 'src/app/views/shared/models/paginated-result';
import { Venue } from 'src/app/views/shared/models/venues';
import { environment } from 'src/environments/environment';
import { ServiceHelper } from '../helpers/service-helper';
import { Song } from 'src/app/views/shared/models/song';
import { RegisterVenue } from 'src/app/views/shared/models/register-venue';

const Venues_API = environment.apiUrl + '/api/Venues';

@Injectable({
  providedIn: 'root',
})
export class VenuesService {
  private venues: BehaviorSubject<Venue[]> = new BehaviorSubject<Venue[]>([]);
  isInitialized = false;

  setVenues(data: Venue[]) {
    this.venues.next(data);
    this.isInitialized = true;
  }
  storedVenuesSubscription() {
    return this.venues;
  }
  constructor(private http: HttpClient, private serviceHelper: ServiceHelper) {}

  getVenues(teamId: number) {
    return this.http.get<Venue[]>(`${Venues_API}/GetVenues?teamId=${teamId}`, {
      responseType: 'json',
    });
  }
  UpdateVenue(venue: RegisterVenue) {
    return this.http.post<Venue[]>(
      `${Venues_API}/updateVenue`,
      this.mapVenueToBackendModel(venue),
      {
        responseType: 'json',
      }
    );
  }

  getVenuesPaged(
    teamId: number,
    pageNo: number = 1,
    pageSize: number = 10,
    filters: Filter[] = []
  ): Observable<PaginatedResult<Venue>> {
    const { filterFields, filterValues } =
      this.serviceHelper.buildFilterQueryString(filters);

    const url = `${Venues_API}/GetVenuesPaged?teamId=${teamId}&pageNo=${pageNo}&pageSize=${pageSize}${
      filterFields ? '&filterFields=' + filterFields : ''
    }${filterValues ? '&filterValues=' + filterValues : ''}`;

    return this.http.get<PaginatedResult<Venue>>(url);
  }

  mapVenueToBackendModel(v: RegisterVenue): any {
    return {
      venueId: v.id,
      contactName: v.contactName,
      email: v.email,
      firstName: v.firstName,
      lastName: v.lastName,
      phoneNumber: v.phoneNumber,
      venueName: v.venueName,
    };
  }
}
