import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-playlist-detail',
  templateUrl: './playlist-detail.component.html',
  styleUrls: ['./playlist-detail.component.scss'],

})
export class PlaylistDetailComponent implements OnInit {
  data: any[] = [];
  groupedData: any[] = [];
  @ViewChild('myTable') mytable: DatatableComponent;
  @Input() playlists: any[];
  @Input() venue: string;

  constructor(private router: Router) { }
   JSON: any;
  ngOnInit(): void {
    this.data = this.playlists;
    this.groupedData = this.groupBySeason(this.data);


  }
  toggleExpandRow(row) {
    //console.log('Toggled Expand Row!', row);
    this.mytable.rowDetail.toggleExpandRow(row);


  //  this.table.rowDetail.toggleExpandRow(row);
  }

  logValue(row) {
    //console.log(row);
  }

  groupBySeason(data) {
    const groups = data.reduce((acc, item) => {
      (acc[item.season.name] = acc[item.season.name] || []).push(item);
      return acc;
    }, {});

    return Object.keys(groups).map((key) => ({
      season: key,
      playlists: groups[key],
    }));
  }

  editPlaylist(playlist) {
  }

  deletePlaylist(playlist) {
  }


  managePlaylist(playlist) {
    const id = playlist.id;
    this.router.navigate(['/playlist'], { state: { id } });

  }
}
