import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { DJService } from 'src/app/shared/services/dj.service';
import { Dj } from 'src/app/views/shared/models/dj';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit, OnDestroy {

  dj : Dj | null;

  isEditMode: boolean = false;

  profileForm: FormGroup;

  private destroy$ = new Subject<void>();


  constructor(
    private djService: DJService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.djService.dj$.pipe(takeUntil(this.destroy$)).subscribe((dj) => {
      this.dj = dj;
      this.createForm();
    });

     // Only call getDj() if the DJ hasn't been loaded yet.
     if (!this.djService.isDjLoaded()) {
      this.djService.getDj()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
    }
  }

  createForm() {
    this.profileForm = this.formBuilder.group({
      firstName: new FormControl(this.dj?.firstName || '', Validators.required),
      lastName: new FormControl(this.dj?.lastName || '', Validators.required),
      email: new FormControl(this.dj?.email || '', [Validators.required, Validators.email]),
      country: new FormControl(this.dj?.country || ''),
      timeZone: new FormControl(this.dj?.timeZone || ''),
      currency: new FormControl(this.dj?.currency || ''),
      nickName: new FormControl(this.dj?.nickName || '', Validators.required),
      phoneNumber: new FormControl(this.dj?.phoneNumber || ''),
      profilePicturePath: new FormControl(this.dj?.base64ProfileImage || ''),
    });
  }

  editProfile() {
    this.isEditMode = true;
  }

  saveProfile() {
    if (this.profileForm.valid) {
      const updatedProfile = this.profileForm.value;

      // Send an HTTP POST request to update the profile using the DJService
      this.djService.updateDJ(updatedProfile)
        .pipe(
          take(1),
          takeUntil(this.destroy$)
          )
        .subscribe({
          next: (dj) => {

            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              title: 'Profile has been saved',
              icon: 'info',
              iconColor: 'black',
              background: '#21f367CC',
            });

            //update the this.dj with the data in the reponsee
            this.djService.updateDJ(dj);

            this.isEditMode = false;
          },
          error: (error) => {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              title: `Error saving profile.`,
              icon: 'error',
              iconColor: 'black',
              background: '#EF5350CC',
            });
            console.error(error);
          },
        });
    }
  }


  cancelEdit() {
    // If the user cancels editing, reset the form to the original profile data.
    this.isEditMode = false;
    this.profileForm.reset(this.dj);
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
