import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CalendarView } from 'src/app/views/shared/models/CalendarView.enum';
import { PublishScheduleModel } from 'src/app/views/shared/models/PublishScheduleModel';
import { AddEditSongInPlayListModel } from 'src/app/views/shared/models/add-edit-playlist-song';
import { CreatePlaylistModel } from 'src/app/views/shared/models/create-playlist';
import { CreateScheduleRequest } from 'src/app/views/shared/models/create-schedule-request';
import { CreateSlotRequest } from 'src/app/views/shared/models/create-slot-request';
import { Filter } from 'src/app/views/shared/models/filter';
import { FolderHierarchy } from 'src/app/views/shared/models/folder-hierarchy';
import { PaginatedResult } from 'src/app/views/shared/models/paginated-result';
import { SchedulesResponse } from 'src/app/views/shared/models/schedules-response';
import { SlotResponse } from 'src/app/views/shared/models/slot-response';
import { Song } from 'src/app/views/shared/models/song';
import { SongPeaks } from 'src/app/views/shared/models/song-peaks';
import { Songs } from 'src/app/views/shared/models/songs';
import { VenueFolder } from 'src/app/views/shared/models/venue-folder';
import { VenuePlaylist } from 'src/app/views/shared/models/venue-playlist';
import { environment } from 'src/environments/environment';

const Library_API = environment.apiUrl + '/api/VenueLibrary';

@Injectable({
  providedIn: 'root',
})
export class VenueLibraryService {
  serviceHelper: any;
  folders: Subject<FolderHierarchy> = new Subject<FolderHierarchy>();
  foldersForTeam: BehaviorSubject<VenueFolder[]> = new BehaviorSubject<
    VenueFolder[]
  >([]);
  isInitialized = false;
  data: any;
  constructor(private http: HttpClient) {}

  StreamSong(songId: number): Observable<ArrayBuffer> {
    return this.http.get(`${Library_API}/StreamSong?songId=${songId}`, {
      responseType: 'arraybuffer',
    });
  }

  getAudioStreamUrl(songId: number): string {
    return `${Library_API}/StreamSong?songId=${songId}`;
  }

  getSongPeaks(songId: number): Observable<any> {
    return this.http.get<SongPeaks>(
      `${Library_API}/GetSongPeaks?songId=${songId}`,
      {
        responseType: 'json',
        headers: {
          'X-Priority': 'High',
        },
      }
    );
    //return this.http.get<any>(`./assets/norm.seal.json`);
  }

  downloadFolders(teamId: number, venueId: number) {
    this.http
      .get<FolderHierarchy>(
        `${Library_API}/Folders?teamId=${teamId}&venueId=${venueId}`,
        { responseType: 'json' }
      )
      .subscribe((data) => {
        this.folders.next(data);
      });
  }
  getFolders(): Subject<FolderHierarchy> {
    return this.folders;
  }

  downloadFoldersForTeam(teamId: number) {
    this.http
      .get<VenueFolder[]>(`${Library_API}/FoldersForTeam?teamId=${teamId}`, {
        responseType: 'json',
      })
      .subscribe((data) => {
        this.foldersForTeam.next(data);
        this.isInitialized = true;
      });
  }
  downloadFoldersForTeam$(teamId: number): Observable<any> {
    return this.http.get<VenueFolder[]>(
      `${Library_API}/FoldersForTeam?teamId=${teamId}`,
      {
        responseType: 'json',
      }
    );
  }

  getFoldersForTeam(): BehaviorSubject<VenueFolder[]> {
    return this.foldersForTeam;
  }

  addSongToCustomerLibrary(
    destinationFolderId: string,
    songId: number,
    teamId: number,
    sequence: number
  ): Observable<string[]> {
    return this.http.post<string[]>(
      `${Library_API}/AddSongToCustomerLibrary`,
      {
        destinationFolderId: destinationFolderId,
        songId: songId,
        teamId: teamId,
        sequence: sequence,
      },
      {
        responseType: 'json',
      }
    );
  }

  getSongsInFolder(
    folderId: string,
    venueId: number = 0,
    searchString: string = '',
    pageNo: number = 1,
    pageSize: number = 50,
    orderBy: string = 'Title',
    desc = false,
    tagIds: number[] = [],
    filterUsedSongs: boolean = false
  ): Observable<PaginatedResult<Song>> {
    let params = new HttpParams()
      .set('folderId', folderId)
      .set('searchString', searchString)
      .set('pageNo', pageNo.toString())
      .set('pageSize', pageSize.toString())
      .set('orderBy', orderBy)
      .set('desc', desc.toString())
      .set('venueId', venueId.toString())
      .set('FilterUsedSongs', filterUsedSongs);

    if (tagIds.length > 0) {
      tagIds.forEach((tagId) => {
        params = params.append('tagIds', tagId.toString());
      });
    }

    return this.http.get<PaginatedResult<Song>>(
      `${Library_API}/GetSongsInFolder`,
      {
        params,
        responseType: 'json',
      }
    );
  }

  deleteFolder(folderId: string): Observable<string> {
    return this.http.post<string>(
      `${Library_API}/DeleteFolder`,
      {
        folderId: folderId,
      },
      {
        responseType: 'json',
      }
    );
  }

  createFolder(
    teamId: number,
    parentFolder: string,
    folderName: string
  ): Observable<string> {
    return this.http.post<string>(
      `${Library_API}/CreateFolder`,
      {
        teamId: teamId,
        parentFolder: parentFolder,
        folderName: folderName,
      },
      {
        responseType: 'json',
      }
    );
  }

  renameFolder(folderId: string, name: string): Observable<string> {
    return this.http.post<string>(
      `${Library_API}/RenameFolder`,
      {
        folderId: folderId,
        name: name,
      },
      {
        responseType: 'json',
      }
    );
  }

  //GetTeamPlaylists
  getTeamPlaylists(
    teamId: number,
    includeSongs: boolean = false,
    pageNo: number = 1,
    pageSize: number = 1000,
    filters: Filter[] = []
  ): Observable<VenuePlaylist[]> {
    // const { filterFields, filterValues } =
    //   this.serviceHelper.buildFilterQueryString(filters);
    const url = `${Library_API}/GetTeamPlaylists?teamId=${teamId}&pageNo=${pageNo}&pageSize=${pageSize}&includeSongs=${includeSongs}`;

    return this.http.get<VenuePlaylist[]>(url, { responseType: 'json' });
  }

  //Create Playlist
  createPlaylist(model: CreatePlaylistModel): Observable<string> {
    return this.http.post<string>(`${Library_API}/CreatePlaylist`, model, {
      responseType: 'json',
    });
  }

  //Create Playlist
  updatePlaylist(model: CreatePlaylistModel): Observable<string> {
    return this.http.patch<string>(`${Library_API}/UpdatePlaylist`, model, {
      responseType: 'json',
    });
  }
  //getSlots

  getSlots(
    teamId: number,
    venueId: number,
    calendarView: number,
    dateTime: string
  ): Observable<SlotResponse[]> {
    const url = `${Library_API}/GetSlots?teamId=${teamId}&venueId=${venueId}&calendarView=${calendarView}&dateTime=${dateTime}`;
    return this.http.get<SlotResponse[]>(url, { responseType: 'json' });
  }
  getSeasons(teamId: number): Observable<[]> {
    const url = `${Library_API}/GetSeasons?teamId=${teamId}`;
    return this.http.get<[]>(url, { responseType: 'json' });
  }
  getMoodOfDay(teamId: number): Observable<[]> {
    const url = `${Library_API}/GetMoodOfDay?teamId=${teamId}`;
    return this.http.get<[]>(url, { responseType: 'json' });
  }
  GetSongsInSlot(slotId: number): Observable<Song[]> {
    const url = `${Library_API}/GetSongsInSlot?slotId=${slotId}`;
    return this.http.get<Song[]>(url, { responseType: 'json' });
  }

  publishSchedule(model: PublishScheduleModel): Observable<string> {
    return this.http.post<string>(`${Library_API}/PublishSchedule`, model, {
      responseType: 'json',
    });
  }

  deleteSlot(slotId: number): Observable<string> {
    return this.http.delete<string>(
      `${Library_API}/DeleteSlot?slotId=${slotId}`,
      {
        responseType: 'json',
      }
    );
  }

  deleteSchedule(scheduleId: number): Observable<string> {
    return this.http.delete<string>(
      `${Library_API}/DeleteSchedule?scheduleId=${scheduleId}`,
      {
        responseType: 'json',
      }
    );
  }

  getVenuePlaylists(
    teamId: number,
    venueId: number,
    playlistId: number,
    includeSongs: boolean = false,
    pageNo: number = 1,
    pageSize: number = 10
  ): Observable<any[]> {
    const url = `${Library_API}/GetVenuePlaylists?teamId=${teamId}&venueId=${venueId}&pageNo=${pageNo}&pageSize=${pageSize}&includeSongs=${includeSongs}&playlistId=${playlistId}`;
    return this.http.get<any[]>(url, { responseType: 'json' });
  }

  addEditSongInPlayList(model: AddEditSongInPlayListModel): Observable<any> {
    if (model.startTime == null) {
      model.startTime = 0;
    }
    if (model.mixTime == null) {
      model.mixTime = 0;
    }
    if (model.fadeInDuration == null) {
      model.fadeInDuration = 0;
    }
    if (model.fadeOutDuration == null) {
      model.fadeOutDuration = 0;
    }

    return this.http.post<any>(`${Library_API}/AddEditSongInPlayList`, model, {
      responseType: 'json',
    });
  }

  //get songs in playlist
  getSongsInPlaylist(
    playlistId: number,
    pageNo: number = 1,
    pageSize: number = 10
  ): Observable<any[]> {
    const url = `${Library_API}/GetSongsInPlaylist?playlistId=${playlistId}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any[]>(url, { responseType: 'json' });
  }

  //DeleteSongInPlaylist
  deleteSongInPlaylist(playlistId: number, songId: number): Observable<any> {
    const data = {
      playlistId: playlistId,
      songId: songId,
    };
    const url = `${Library_API}/DeleteSongInPlaylist`;

    return this.http.post<any>(url, data, { responseType: 'json' });
  }

  //DeleteSongFromFolder
  deleteSongFromFolder(folderId: string, songId: number): Observable<any> {
    const data = {
      folderId: folderId,
      songId: songId,
    };
    const url = `${Library_API}/DeleteSongFromFolder`;

    return this.http.post<any>(url, data, { responseType: 'json' });
  }

  CreateSchedule(
    model: CreateScheduleRequest
  ): Observable<CreateScheduleRequest> {
    const url = `${Library_API}/CreateSchedule`;
    return this.http.post<any>(url, model, { responseType: 'json' });
  }

  GetSchedules(
    teamId: number,
    venueId: number
  ): Observable<SchedulesResponse[]> {
    const url = `${Library_API}/GetSchedules?teamId=${teamId}&venueId=${venueId}`;
    return this.http.get<any[]>(url, { responseType: 'json' });
  }

  CreateSlot(model: CreateSlotRequest): Observable<any> {
    const url = `${Library_API}/CreateSlot`;
    return this.http.post<any>(url, model, { responseType: 'json' });
  }
}
