import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondMillisecondFormat'
})
export class SecondMillisecondFormatPipe implements PipeTransform {

  transform(value: number): string {
    if(value === null || value === undefined ) return '';
    const time = Math.abs(value);
    const datePipe = new DatePipe('en-US');
    const date = new Date(time * 1000); // convert seconds to milliseconds
    return (value<0? "-":"") + datePipe.transform(date, 'ss.SS')?? '';
  }

}
