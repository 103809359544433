import { PublishScheduleModel } from './../../../shared/models/PublishScheduleModel';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import { VenuePlaylist } from 'src/app/views/shared/models/venue-playlist';

import {
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventApi,
  EventSourceInput,
  EventInput,
  FullCalendarComponent,
} from '@fullcalendar/angular';
import { Draggable } from '@fullcalendar/interaction'; // for dateClick
// import { INITIAL_EVENTS, createEventId } from './event-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarView } from 'src/app/views/shared/models/CalendarView.enum';
import { Subject, takeUntil } from 'rxjs';
import { Team } from 'src/app/views/shared/models/team';
import { Playlist } from 'src/app/views/shared/models/playlist';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { VenueCalendarMenuComponent } from './venue-calendar-menu/venue-calendar-menu.component';
import { PlaylistService } from 'src/app/shared/services/playlist.service';

@Component({
  selector: 'app-venue-calendar',
  templateUrl: './venue-calendar.component.html',
  styleUrls: ['./venue-calendar.component.scss'],
})
export class VenueCalendarComponent implements OnInit, OnDestroy {
  @ViewChild('externalEvents', { static: true }) externalEvents: ElementRef;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('calendarButton') calendarButton: ElementRef;

  currentEventTarget: HTMLElement; // To hold the current clicked event's DOM element
  selectedNode: any; // This should be bound to the selected node in your tree

  private destroy$ = new Subject<void>();

  localTimeString = 'local';

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,today,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    initialView: 'timeGridWeek',
    initialEvents: [],
    weekends: true,
    nextDayThreshold: '00:00:00',
    plugins: [dayGridPlugin, interactionPlugin],
    timeZone: this.localTimeString,
    editable: false,

    selectable: false, // set selectable to false to prevent creating new events
    selectMirror: true,
    dayMaxEvents: true,
    droppable: true, // this allows things to be dropped onto the calendar
    drop: this.handleDrop.bind(this),
    scrollTime: '00:00:00',
    allDaySlot: false,
    eventContent: this.renderEventContent.bind(this),

    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    datesSet: this.handleDatesSet.bind(this),
    eventDidMount: this.handleEventDidMount.bind(this),
    businessHours: {
      daysOfWeek: [1, 2, 3, 4, 5],
      startTime: '00:00:00',
      endTime: '24:00:00',
    },
    eventMouseEnter: function (info) {
      const eventElement = info.el; // Get the event element
      const eventTitle = info.event.title; // Get the event title

      // Check if the event element height is less than 20px
      if (eventElement.offsetHeight < 50) {
        eventElement.style.height = '50px';
        eventElement.style.backgroundColor = 'rgba(254, 152, 20, 1)';
        eventElement.classList.add('expanded-event');
        const parentElement = eventElement.parentElement;
        if (parentElement != null) {
          parentElement.style.zIndex = '2';
        }
        eventElement.style.overflow = 'hidden';
        // Ensure the event content is visible
        const eventMain = eventElement.querySelector('.fc-event-main');
        if (eventMain instanceof HTMLElement) {
          // Check and cast to HTMLElement
          eventMain.style.display = 'block';
        }
      }
    },
    eventMouseLeave: function (info) {
      const eventElement = info.el; // Get the event element

      // Check if the event element has been expanded
      if (eventElement.offsetHeight === 50) {
        // Restore the event element height to its original state
        eventElement.style.height = ''; // Remove the inline height style
        eventElement.style.backgroundColor = 'rgba(254, 152, 20, 0.25)';
        eventElement.style.zIndex = '';
        // Remove the class added for styling
        eventElement.classList.remove('expanded-event');

        // Restore the event content to its original state
        const eventMain = eventElement.querySelector('.fc-event-main');
        if (eventMain instanceof HTMLElement) {
          // Check and cast to HTMLElement
          eventMain.style.display = '';
        }
      }
    },
    nowIndicator: true,
    eventResizableFromStart: false,
    eventDurationEditable: false,
    eventStartEditable: true,
    eventMinHeight: 5,
    eventShortHeight: 5,
    slotEventOverlap: true,
    slotDuration: '00:15:00',
    slotLabelInterval: { hours: 1 },
  };

  currentEvents: EventApi[] = [];
  treeData: TreeNode<any>[] = [];
  data: VenuePlaylist[];
  venueId: number;
  teamId: number;
  team: Team;
  allPlaylists: any;
  events: EventInput[] = [];

  constructor(
    private venueLibraryService: VenueLibraryService,
    private teamsService: TeamsService,
    private modalService: NgbModal,
    private playlistService: PlaylistService,
    private renderer: Renderer2,
    private el: ElementRef,
    private route: ActivatedRoute
  ) {}
  ngOnDestroy(): void {
    this.externalEvents.nativeElement.remove();
    this.destroy$.next();
    this.destroy$.complete();
  }

  renderEventContent(eventInfo) {
    const eventId = eventInfo.event.id;
    const eventTitle = eventInfo.event.title;

    // Construct the HTML content with or without the title attribute on the parent div
    const htmlContent = `
      <div title="${eventTitle}"}>
        <button
          #menubutton
          id="menubutton${eventId}"
          class="hamburger-btn"
          data-event-id="${eventId}"
        >
          &#9776;
        </button>
        <div class="eventTitle">${eventTitle}</div>

      </div>
    `;

    return {
      html: htmlContent,
    };
  }

  handleEventDidMount(mountArg: any): void {
    const eventId = mountArg.event.id;
    if (mountArg.event) {
      const menuButton = document.getElementById(
        `menubutton${eventId}`
      ) as HTMLElement;
      if (menuButton) {
        menuButton.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();
          this.openMenuModal(mountArg.event);
        });
      }
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.venueId = parseInt(params.get('id') || '0');
      console.log('Venue ID:', this.venueId);
    });

    new Draggable(this.externalEvents.nativeElement, {
      itemSelector: '.fc-event',
      eventData: function (eventEl) {
        return {
          title: eventEl.innerText,
          backgroundColor: eventEl.getAttribute('bgColor'),
          borderColor: eventEl.getAttribute('bdColor'),
        };
      },
    });

    this.teamsService
      .getSelectedTeamUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((team) => {
        if (team != undefined) {
          this.teamId = team.id;
          this.venueLibraryService
            .getTeamPlaylists(team.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((venuePlayList) => {
              this.data = venuePlayList;
              this.getTeamPlaylists(venuePlayList);
            });
          this.team = team;
          this.getSlots(this.team, new Date().toISOString());
        }
      });
  }

  private getSlots(team: Team, date: string) {
    if (team == undefined) {
      return;
    }
    this.venueLibraryService
      .getSlots(team.id, this.venueId, CalendarView.Week, date)
      .pipe(takeUntil(this.destroy$))
      .subscribe((slots) => {
        this.currentEvents.length = slots.length;

        this.events = slots.map((slot) => {
          var startDateTime = new Date(slot.startDateTime);
          var endDateTime = new Date(slot.startDateTime);

          endDateTime.setSeconds(startDateTime.getSeconds() + slot.duration);

          return {
            title: slot.playlistName,
            start: new Date(slot.startDateTime),
            duration: slot.duration,
            end: endDateTime,
            backgroundColor: 'rgba(254, 152, 20, 0.25)',
            borderColor: '#f89814',
            editable: false,
            interactive: true,
            id: slot.id.toString(),
            scheduleId: slot.scheduleId,
            isSplit: slot.isSplit,
            playlistId: slot.playlistId,
          } as EventInput;
        });
        this.calendarOptions.events = this.events;
      });
  }

  private getTeamPlaylists(data) {
    this.allPlaylists = data.items.map((venuePlayList) => {
      return venuePlayList.playlists.map((playlist) => {
        return playlist;
      });
    });

    let treeData: TreeNode[] = data.items.map((venue) => {
      const venueNode: TreeNode = {
        label: venue.name,
        expandedIcon: 'pi pi-folder-open',
        collapsedIcon: 'pi pi-folder',
        children: venue.playlists.reduce((seasonNodes, playlist) => {
          const seasonNodeIndex = seasonNodes.findIndex(
            (node) => node.label === playlist.season.name
          );
          if (seasonNodeIndex === -1) {
            // Create a new season node if it doesn't exist
            const seasonNode: TreeNode = {
              label: playlist.season.name,
              expandedIcon: 'pi pi-folder-open',
              collapsedIcon: 'pi pi-folder',
              children: [
                {
                  label: playlist.moodOfDay.name,
                  expandedIcon: 'pi pi-folder-open',
                  collapsedIcon: 'pi pi-folder',
                  children: [
                    {
                      label: playlist.name,
                      data: playlist,
                      type: 'calendar-event',
                      styleClass: 'music-node',
                      children: [],
                    },
                  ],
                },
              ],
            };
            seasonNodes.push(seasonNode);
          } else {
            const seasonNode = seasonNodes[seasonNodeIndex];
            const moodNodeIndex = seasonNode.children.findIndex(
              (node) => node.label === playlist.moodOfDay.name
            );
            if (moodNodeIndex === -1) {
              // Create a new mood node if it doesn't exist
              seasonNode.children.push({
                label: playlist.moodOfDay.name,
                expandedIcon: 'pi pi-folder-open',
                collapsedIcon: 'pi pi-folder',
                children: [
                  {
                    label: playlist.name,
                    data: playlist,
                    type: 'calendar-event',
                    styleClass: 'music-node',
                    children: [],
                  },
                ],
              });
            } else {
              // Add the playlist to the existing mood node
              seasonNode.children[moodNodeIndex].children.push({
                label: playlist.name,
                data: playlist,
                type: 'calendar-event',
                styleClass: 'music-node',
                children: [],
              });
            }
          }
          return seasonNodes;
        }, []),
      };
      return venueNode;
    });
    setTimeout(() => {
      this.treeData = treeData;

      const customerName = this.playlistService.currentCustomer.getValue();
      if (!customerName) return;
      let selectedNode = this.treeData.find((c) => c.label === customerName)!;

      if (selectedNode) {
        treeData = treeData.filter((c) => c.label !== customerName);

        // Add the selectedNode to the beginning of the array
        treeData.unshift(selectedNode);
      }
      if (!selectedNode) {
        selectedNode = this.treeData[0];
      }
      selectedNode.expanded = true;
      if (selectedNode && selectedNode.children) {
        // Expand all children
        selectedNode.children.forEach((c) => {
          c.expanded = true;
        });
      }
      this.treeData = treeData;
    }, 100);

    // For music node dragging
    const musicNodes = document.querySelectorAll('.pi-music');
    musicNodes.forEach((node) => {
      new Draggable(node as HTMLElement, {
        eventData: function (eventEl) {
          return {
            title: eventEl.innerText,
          };
        },
      });
    });
  }

  handleDateSelect(selectInfo: DateSelectArg) {}

  handleEventClick(info): void {}

  openMenuModal(event: any) {
    const modalRef = this.modalService.open(VenueCalendarMenuComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: true,
    });

    const slot = event;

    this.allPlaylists.some((playlists) => {
      return playlists.some((playlist) => {
        if (playlist.id == slot?._def?.extendedProps?.playlistId) {
          modalRef.componentInstance.Playlist = playlist;
          return true;
        }
        return false;
      });
    });

    if (slot) {
      modalRef.componentInstance.Slot = slot;
      modalRef.componentInstance.TeamId = this.teamId;
      modalRef.componentInstance.VenueId = this.venueId;
      modalRef.result.then((result) => {
        if (result !== 'Closed' && result !== 'Dismissed') {
          // console.log(result);
        }
        if (result.action == 'deleteSlot') {
          this.deleteSlot(result.data);
        }
        if (result.action == 'deleteSchedule') {
          this.deleteSchedule(result.data);
        }
        if (result.action == 'splitSlot') {
          this.publishSchedule(result.data);
        }
      });
    }
  }

  onDragStart(event, node) {
    event.preventDefault();
    event.dataTransfer.setData(
      'text/plain',
      JSON.stringify(Object.assign({}, node))
    );
  }

  handleDrop(arg) {
    //console.log(arg);
    const dateDropped = arg.date as Date;
    const eventData = JSON.parse(arg.draggedEl.getAttribute('data-event')).data;
    //console.log(eventData);

    //remove all the events from the calendar

    const droppedTime = new Date(dateDropped);
    //const startTime = droppedTime.toISOString().split('T')[1].split('.')[0];
    //const endTime = new Date(
    //  droppedTime.setSeconds(dateDropped.getSeconds() + eventData.duration)
    //)
    //  .toISOString()
    //  .split('T')[1]
    //  .split('.')[0];

    //Get the day of the week
    //const dayOfWeek = dateDropped.getDay();

    this.treeData = [...this.treeData];

    // Remove all events

    const publishScheduleModel: PublishScheduleModel = {
      playlistId: eventData.id,
      venueId: this.venueId,
      teamId: this.teamId,
      scheduleDate: dateDropped.toISOString(),
      splits: [
        //{ dayOfWeek: dayOfWeek, startTime: startTime, endTime: endTime },
      ],
    };

    this.publishSchedule(publishScheduleModel);
  }

  publishSchedule(publishScheduleModel: PublishScheduleModel) {
    const calendarApi = this.calendarComponent.getApi();
    this.venueLibraryService
      .publishSchedule(publishScheduleModel)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          calendarApi.removeAllEvents();
          this.getSlots(
            this.team,
            calendarApi.currentData.currentDate.toISOString()
          );
        },
        (error) => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            title:
              'Conflict Detected with an existing slot.  Scheduling Aborted. Please chose a different time.',
            icon: 'error',
            iconColor: 'black',
            background: '#EF5350CC',
          });
          calendarApi.removeAllEvents();
          this.getSlots(
            this.team,
            calendarApi.currentData.currentDate.toISOString()
          );
        }
      );
  }

  deleteSlot(slotId: number) {
    const calendarApi = this.calendarComponent.getApi();
    this.venueLibraryService
      .deleteSlot(slotId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        calendarApi.removeAllEvents();
        this.getSlots(
          this.team,
          calendarApi.currentData.currentDate.toISOString()
        );
      });
  }

  deleteSchedule(scheduleId: number) {
    const calendarApi = this.calendarComponent.getApi();
    this.venueLibraryService
      .deleteSchedule(scheduleId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        calendarApi.removeAllEvents();
        this.getSlots(
          this.team,
          calendarApi.currentData.currentDate.toISOString()
        );
      });
  }

  handleDatesSet(arg) {
    //console.log(arg.view); // prints the current view type and the start/end of the current date range
    let startDate: Date = arg.view.getCurrentData().currentDate;
    //console.log(startDate);
    this.getSlots(this.team, startDate.toISOString());
  }

  stringifyNode(node: any): string {
    const data = this.stringifyWithoutCircularRef(node);
    return data;
  }

  stringifyWithoutCircularRef(
    obj: any,
    replacer?: (key: string, value: any) => any,
    spaces?: number
  ): string {
    const seen = new WeakSet();
    return JSON.stringify(
      obj,
      (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return; // If we've seen this object before, skip it
          }
          seen.add(value);
        }
        return replacer ? replacer(key, value) : value;
      },
      spaces
    );
  }
}
