import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwIfEmpty } from 'rxjs';
import { Song } from 'src/app/views/shared/models/song';
import { VenueLibraryService } from './venue-library.service';
import { PlaylistSong } from 'src/app/views/shared/models/playlist-song';
import { WaveSurferService } from './wave-surfer.service';
import { createWaveSurferService } from '../helpers/wavesurferFactory';

@Injectable({
  providedIn: 'root',
})


export class PlaylistPlayerService implements OnDestroy {

  private destroy$ = new Subject<void>();
  private interval = 100;
  private monitoring: boolean;
  private monitorInterval: NodeJS.Timeout;

  private playlist: Song[];
  private songA: Song | null;
  private songB: Song | null;

  private playlistSongs = new Subject<Song[]>;

  private waveSurferServiceA: WaveSurferService;
  private waveSurferServiceB: WaveSurferService;

  private currentTimeA: number;
  private currentTimeB: number;

  private activePlayer: "A" | "B" = "A";
  private currentIndex$ = new BehaviorSubject<number>(0);
  private songPlayingA$ = new Subject<number | null>;
  private songPlayingB$ = new Subject<number | null>;

  private isMixing: boolean = false;
  private isFadingOut: boolean = false;
  private stopTime : number =0;

  private playedOnce: boolean = false;
  private dummyPeaks = [[0]];

  constructor(
    private venueLibraryService: VenueLibraryService,
    private injector: Injector
    ) {
      this.waveSurferServiceA = createWaveSurferService(this.injector);
      this.waveSurferServiceB = createWaveSurferService(this.injector);

      //<div id="waveformA"></div>
      //<div id="waveformB"></div>

      //Dynamically create these two divs in the DOM, as the WaveSurferService will need them to create the WaveSurfer instances.
      //make the waveforms hidden, as they are not needed to be seen by the user.
      const waveformA = document.createElement('div');
      waveformA.id = "waveformA";
      waveformA.style.display = "none";
      document.body.appendChild(waveformA);


      const waveformB = document.createElement('div');
      waveformB.id = "waveformB";
      waveformB.style.display = "none";
      document.body.appendChild(waveformB);


      this.playlistSongs.subscribe((songs) => {
        this.playlist = songs;
      });
  }

  getSongPlayingA$() : Observable<number | null> {
    return this.songPlayingA$.asObservable();
  }

  getSongPlayingB$() : Observable<number | null> {
    return this.songPlayingB$.asObservable();
  }

  private setSongPlayingA(id: number | null) {
    this.songPlayingA$.next(id);
  }

  private setSongPlayingB(id: number | null) {
    this.songPlayingB$.next(id);
  }

  getCurrentIndex$() : Observable<number> {
    return this.currentIndex$.asObservable();
  }

  getCurrentIndex() : number{
    return this.currentIndex$.value;
  }

  private setCurrentIndex(value: number) {
    this.currentIndex$.next(value);
  }

  private runMonitor(): void {

    this.monitoring = true;
    this.currentTimeA =  this.waveSurferServiceA.getCurrentTime();
    this.currentTimeB =  this.waveSurferServiceB.getCurrentTime();


   this.mixMusic();

    //End of the Playlist
    if (this.getCurrentIndex() > this.playlist.length - 1)
    {
      this.stopMonitor();
      return;
    }
  }

  private startMonitor(): void {
    if (!this.monitoring)
      this.monitorInterval = setInterval(() => this.runMonitor(), this.interval);
  }

  private stopMonitor(): void {
    clearInterval(this.monitorInterval);
    this.monitoring = false;
  }


  private initializeSongs(index: number = 0){
    if(this.playlist.length === 0)
      return;
    const audioStreamUrl1 = this.venueLibraryService.getAudioStreamUrl(this.songA!.id);

    this.activePlayer = "A";
    this.isMixing = false;
    this.isFadingOut = false;
    this.stopTime = 0;
    this.playedOnce = false;

    this.waveSurferServiceA.destroy();
    this.waveSurferServiceA.getWaveSurfer('#waveformA', audioStreamUrl1, this.dummyPeaks, this.songA!.duration);

    this.waveSurferServiceA.on('ready', () => {
        this.startMonitor();
        this.Play();
    });

    // If the song is the first song in the playlist, we want to start the song at the start time.
    // Otherwise, we want to start the song at the triggerFadeOutTime - 5 seconds. of the Previous Song.
    if(this.getCurrentIndex() === 0 && index === 0){
      this.waveSurferServiceA.setTime(this.songA!.songProperties.startTime);
      this.setVolume(this.songA!.songProperties.volume)
    }else{
      const triggerFadeOutTime =this.songA!.songProperties.fadeOutDuration < 0 ? this.songA!.songProperties.mixTime - Math.abs(this.songA!.songProperties.fadeOutDuration) : this.songA!.songProperties.mixTime;
      this.waveSurferServiceA.seekTo(triggerFadeOutTime - 5)
      this.setVolume(this.songA!.songProperties.volume)
    }


    if(this.songB)
    {
      const audioStreamUrl2 = this.venueLibraryService.getAudioStreamUrl(this.songB!.id);
      this.waveSurferServiceB.destroy();
      // generate empty peaks
      this.waveSurferServiceB.getWaveSurfer("#waveformB", audioStreamUrl2, this.dummyPeaks, this.songB!.duration);

      this.waveSurferServiceB.seekTo(this.songB!.songProperties.startTime);
      this.setVolume2(this.songB!.songProperties.volume);
      //Investigate if I need to set the volume here?
    }
  }

  setPlaylistSongs(songs: Song[]) {
      // The very first time the service is initialized, we want to subscribe to the playlistSongs subject.
      // This will be called everytime the playlist is changed, and we will get the latest playlist, when the play button is pressed.
    this.Stop();
    this.playlistSongs.next(songs);

  }

  playSong(index: number){
    //set the currentIndex to the chosen song -1 if it's not the 1st song in the playlist.
    //The currentIndex is used to determine which song is playing, and which song is next.
    // In the event that the song is not the first song in the list, it means that we will always set the current index to the previous song as to allow the MIX to happen.
    this.stopMonitor();
    this.Stop();


    if(index > 0)
      this.setCurrentIndex (index -1);
    else
      this.setCurrentIndex(index);

    if(index === 0)
    {
      this.songA = this.playlist[index];
      this.songB = this.playlist[index + 1];
    }
    else if(index > this.playlist.length - 1)
    {
      this.songA = this.playlist[index];
      this.songB = null;
    }
    else{
          this.songA = this.playlist[index - 1];
          this.songB = this.playlist[index];
    }
    this.setSongPlayingA(this.songA?.id);
    this.initializeSongs(index);

  }


  private mixMusic() {

    const waveSurferInstance = this[`waveSurferService` + this.activePlayer];
    const nextWaveSurferInstance = this.activePlayer === 'A' ? this.waveSurferServiceB : this.waveSurferServiceA;
    const song = this[`song`+this.activePlayer];
    const nextSong = this["song" + (this.activePlayer === 'A' ? "B" : "A")];
    const currentTime = this[`currentTime`+this.activePlayer];

    if(!song)
    {
      this.Stop();
      return;
    }


    if(!waveSurferInstance.isPlaying() && !this.playedOnce)
      return;

    this.playedOnce = true;
    const fadeOutEnabled = song.songProperties.fadeOutDuration != 0;
    const fadeInEnabled = nextSong?.songProperties.fadeInDuration != 0;


    // StopTime is when the current active song should Stop.
    // This is calculated in 3 different scenarios:
    // 1) If the FadeOut is before the MixTime, it means that the Mixtime is the StopTime
    // 2) If the FadeOut is after the MixTime, it means that the FadeOut is the StopTime
    // 3) If there is No Fadeout, it means that the StopTime is the end of the song.
    if(!this.isMixing && this.stopTime === 0 ){
      if( this.getCurrentIndex() + 2 >= this.playlist.length)
          this.stopTime = song.duration;

      else if(song.songProperties.fadeOutDuration === 0)
        this.stopTime = song.duration;
      else
          this.stopTime =  song.songProperties.fadeOutDuration < 0
            ? song.songProperties.mixTime
            : song.songProperties.mixTime + song.songProperties.fadeOutDuration;
      //console.log("***Setting stop time for song ",song.artist, " to ", this.stopTime, " at time", currentTime, " with ActivePlayer ", this.activePlayer);
    }


    if (currentTime >= this.stopTime || currentTime === 0){
      waveSurferInstance?.stop();
      this[`waveSurferService` + this.activePlayer].destroy();

      this.stopTime = 0;
      this.isMixing = false;
      this.isFadingOut = false;
      this.playedOnce = false;
      this.activePlayer === "A" ? this.setSongPlayingA(null) : this.setSongPlayingB(null);
      //console.log("***Stopping Song ",song.artist, " at StopTime ", this.stopTime, " at currentTime ",currentTime, " with ActivePlayer ", this.activePlayer);

      // If the current song is the last song in the playlist, we need to stop the player.
      if(this.getCurrentIndex() + 1 >= this.playlist.length){
        this.Stop();
        //console.log("*** Exiting Player ",this.activePlayer);
        return;
      }
      else{
        // If a song has stopped on for Example, PlayerA, then we swap the context to PlayerB, and set the next song to PlayerA.
        if(this.getCurrentIndex() + 2 >= this.playlist.length)
        {
          this["song" + this.activePlayer ] = null; // Reset the song to null, as we are done with the playlist.
          this.stopTime = 0;
          this.isMixing = false;
          this.isFadingOut = false;
          this.playedOnce = false;
          this.activePlayer =  this.activePlayer === 'A' ? 'B' : 'A';
          this.setCurrentIndex(this.getCurrentIndex() + 1);

          //console.log("***Last Song Playing at Index ", this.getCurrentIndex(), " at currentTime ",currentTime, " with ActivePlayer ", this.activePlayer);
          return;
        }

        // Due to wrong configuration, it's possible that the next song starts before the mixtime is evert hit, and the current song ends.
        // We must handle this case by starting the next song regardless, as the current song is being unloaded!
        // We also have the case that the fadeout has happened, and stoptime is hit, but next song not started yet, now is the time to start it.
        if(!nextWaveSurferInstance.isPlaying())
        {
          nextWaveSurferInstance?.seekTo(nextSong.songProperties.startTime);
          //If the Fade-in is enabled, we need to have fade in behavior, otherwise, we don't fade, and just set the to the tracks volume settings (default 85%)
          if (fadeInEnabled) {
            nextWaveSurferInstance.fadeIn(nextSong!.songProperties.fadeInDuration * 1000, nextSong!.songProperties.volume,);
          } else {
            this.setVolume2(nextSong!.songProperties.volume);
          }
          const songPlayingFunction = this.activePlayer === "A" ? "setSongPlayingB" : "setSongPlayingA";
          this[songPlayingFunction](nextSong?.id);
          nextWaveSurferInstance.play();
        }

        // Enqueue the next stop in the example above in PlayerA (Since we have not yet swapped the context to PlayerB, however, PlayerB would be playing at this point)
        this.enqueueNextSong(waveSurferInstance, (wavesurfer) => this[`waveSurferService` + this.activePlayer] = wavesurfer);



        //Directly after enqueuing the next song, we swap the context to PlayerB, which will allow the next iteration of this MixMusic function to play the next song on PlayerB.
        // This will put the context on SongB to set the variables appropriately for SongB, hence we reset the stopTime, isMixing, isFadingOut so they are re-initialized for SongB.


        this.activePlayer =  this.activePlayer === 'A' ? 'B' : 'A';
        this.setCurrentIndex(this.getCurrentIndex() + 1);
        //console.log("***Enqueueing Next Song at Index ", this.getCurrentIndex(), " at currentTime ",currentTime, " with ActivePlayer ", this.activePlayer);
        // After resetting everything, we should directly stop the current loop, as the next loop will be with the new context.
        return;
      }
    }



    // The Trigger time for Fading Out a song is calculated in 2 different scenarios:
    // 1) If the FadeOut is a Negative Number, it means that it's before the mix time. The Fadeout should be triggered at fadeOutTime which is the mixtime - the absolute value of the fadeout duration.
    // 2) If the Fadeout is a Positive Number, it means that it's after the mix time. The Fadeout should be triggered at the MixTime.
    const triggerFadeOutTime =
          song.songProperties.fadeOutDuration < 0
            ? song.songProperties.mixTime - Math.abs(song.songProperties.fadeOutDuration)
            : song.songProperties.mixTime;


    // If the Fade Out checkbox is selected, we need to trigger the fadeout at the appropriate time which we calculated as the triggerFadeOutTime.
    // If the Fade Out is not enabled, the song should play until the end of the Track, which is controlled by the StopTime. and stopTime Routine.
      if (fadeOutEnabled) {
        if (currentTime >= triggerFadeOutTime && !this.isFadingOut){
          this.isFadingOut = true;
          const fade_out_duration = Math.abs(song.songProperties.fadeOutDuration) * 1000;
            waveSurferInstance.fadeOut(fade_out_duration, song.songProperties.volume);
         //console.log("***Fading out song ",song.artist, " for ", fade_out_duration, " at currentTime ",currentTime, " with ActivePlayer ", this.activePlayer);
        }
      }


      // If the music is playing, and we have not yet started mixing the two songs, we check if the current song time hits the mix time target, and if so, we need to start mixing.
      if ((song.songProperties.mixTime === 0 ? (currentTime >= song.duration || currentTime == 0): (currentTime >= song.songProperties.mixTime)) && !this.isMixing ) {
        //console.log("***Mixing Song ", song.artist, " with ", nextSong.artist," at currentTime ",currentTime, " with ActivePlayer ", this.activePlayer);

        this.isMixing = true;
        nextWaveSurferInstance?.seekTo(nextSong.songProperties.startTime);
        //If the Fade-in is enabled, we need to have fade in behavior, otherwise, we don't fade, and just set the to the tracks volume settings (default 85%)
        if (fadeInEnabled) {
          nextWaveSurferInstance.fadeIn(nextSong!.songProperties.fadeInDuration * 1000, nextSong!.songProperties.volume);
        } else {
          this.setVolume2(nextSong!.songProperties.volume);
        }
        const songPlayingFunction = this.activePlayer === "A" ? "setSongPlayingB" : "setSongPlayingA";
        this[songPlayingFunction](nextSong?.id);
        nextWaveSurferInstance.play();
      }
  };

  private enqueueNextSong(waveSurferServiceInstance: WaveSurferService, out: (waveSurferInstance: WaveSurferService) => void){

    if(this.getCurrentIndex() + 2> this.playlist.length)
      return;

    var song = this.playlist[this.getCurrentIndex()+2];
    if(!song)
      return;

    // songA is always Played on PlayerA and songB is always Played on PlayerB.
    // If the howerInstance is that of playerA in this function, it means that the playerA has already Stopped, and we are ready to load the next song into it.
    this["song"+ this.activePlayer ] = song;

    var audioStreamUrl = this.venueLibraryService.getAudioStreamUrl(song.id);
    waveSurferServiceInstance.getWaveSurfer("#waveform"+this.activePlayer, audioStreamUrl, this.dummyPeaks, song.duration);

    waveSurferServiceInstance.seekTo(song.songProperties.startTime);
    waveSurferServiceInstance.setVolume(song.songProperties.volume);
    out(waveSurferServiceInstance);
  }

  private setVolume(volume: number) {
    if (volume >= 0) {
      this.waveSurferServiceA?.setVolume(volume);
    }
  }

  private setVolume2(volume: number) {
    if (volume >= 0) {
      this.waveSurferServiceB?.setVolume(volume);
    }
  }


  Stop() {
    this.stopMonitor();
    this.songA =  null;
    this.songB = null;
    this.waveSurferServiceA?.stop();
    this.waveSurferServiceB?.stop();
    this.setSongPlayingA(null);
    this.setSongPlayingB(null);
  }

  private Play() {
    if (this.activePlayer === "A") {
      this.setVolume(this.songA!.songProperties.volume);
      this.waveSurferServiceA!.play();
    } else {
      this.setVolume2(this.songB!.songProperties.volume);
      this.waveSurferServiceB!.play();
    }
  }

  ngOnDestroy() {
    this.stopMonitor();
    this.waveSurferServiceA.destroy();
    this.waveSurferServiceB.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }


}
