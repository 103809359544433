import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MusicItemComponent } from './music-item/music-item.component';
import { MiniPlayerComponent } from './components/mini-player/mini-player.component';
import { FeatherIconModule } from '../../../../src/app/core/feather-icon/feather-icon.module';
import { MinuteSecondFormatPipe } from './pipes/minute-second-format.pipe';
import { SecondMillisecondFormatPipe } from './pipes/second-millisecond-format.pipe';
import { EditorPlayerComponent } from './components/editor-player/editor-player.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { PaginatorModule } from 'primeng/paginator';
import { LabelSelectorComponent } from './components/label-selector/label-selector.component';
import { TreeSelectModule } from 'primeng/treeselect';
import { ColorPickerModule } from 'primeng/colorpicker';
import { TooltipModule } from 'primeng/tooltip';
import { SortablejsModule } from 'ngx-sortablejs';
import { TranslateModule } from '@ngx-translate/core';
import { LibraryViewComponent } from '../pages/dashboard/library-view/library-view.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {
  UppyAngularDashboardModule,
  UppyAngularStatusBarModule,
  UppyAngularDashboardModalModule,
  UppyAngularDragDropModule,
  UppyAngularProgressBarModule,
} from '@uppy/angular';
import { ManagePlaylistComponent } from '../pages/playlists/manage-playlist/manage-playlist.component';
import { PlaylistComponent } from '../pages/playlists/playlist/playlist.component';
import { EditLabelComponent } from '../pages/labels/edit-label/edit-label.component';
import { LabelsComponent } from '../pages/labels/labels.component';
import { EditPlaylistComponent } from '../pages/playlists/edit-playlist/edit-playlist.component';
import { PlaylistDetailComponent } from '../pages/playlists/playlist-detail/playlist-detail.component';
import { EditVenueComponent } from '../pages/venues/edit-venue/edit-venue.component';
import { VenuesComponent } from '../pages/venues/venues.component';
import { PlaylistsComponent } from '../pages/playlists/playlists.component';
import { PlaylistItemComponent } from './playlist-item/playlist-item.component';
import { PlaylistMixerComponent } from './components/playlist-mixer/playlist-mixer.component';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { DragDropModule } from 'primeng/dragdrop';
import { PlaylistMusicItemComponent } from './music-item/playlist-music-item/playlist-music-item.component';
import { PlayerButtonsComponent } from './components/player-buttons/player-buttons.component';
import { PlayerTimesComponent } from './components/player-times/player-times.component';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { HourMinuteSecondFormatPipe } from './pipes/hour-minute-second-format.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WaveSurferService } from 'src/app/shared/services/wave-surfer.service';
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [
    MusicItemComponent,
    MiniPlayerComponent,
    HourMinuteSecondFormatPipe,
    MinuteSecondFormatPipe,
    SecondMillisecondFormatPipe,
    EditorPlayerComponent,
    ColorPickerComponent,
    LabelSelectorComponent,
    LibraryViewComponent,
    ManagePlaylistComponent,
    PlaylistsComponent,
    VenuesComponent,
    PlaylistDetailComponent,
    LabelsComponent,
    EditLabelComponent,
    EditVenueComponent,
    EditPlaylistComponent,
    PlaylistComponent,
    PlaylistItemComponent,
    PlaylistMixerComponent,
    PlaylistMusicItemComponent,
    PlayerButtonsComponent,
    PlayerTimesComponent,
  ],
  imports: [
    SortablejsModule,
    CommonModule,
    FeatherIconModule,
    NgbAlertModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    PaginatorModule,
    TreeSelectModule,
    ColorPickerModule,
    TooltipModule,
    MultiSelectModule,
    TranslateModule,
    ContextMenuModule,
    OverlayPanelModule,
    UppyAngularDashboardModule,
    UppyAngularStatusBarModule,
    UppyAngularDashboardModalModule,
    UppyAngularDragDropModule,
    UppyAngularProgressBarModule,
    ListboxModule,
    TableModule,
    DragDropModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatButtonModule,
    FontAwesomeModule,
    MatMenuModule,
    MatIconModule,
  ],
  exports: [
    MusicItemComponent,
    MiniPlayerComponent,
    EditorPlayerComponent,
    ColorPickerComponent,
    LibraryViewComponent,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
  ],
  providers: [MinuteSecondFormatPipe, DatePipe, WaveSurferService],
})
export class SharedModule {}
