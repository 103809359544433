import { Injectable } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import { StorageService } from '../storage.service';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';

@Injectable()
export class WaveSurferService {

  private waveSurfer: WaveSurfer | null = null;

  interval = 5;
  waveClicked: boolean;
  zoomLevel: number;
  fadeAudio: any;
  wsRegions: RegionsPlugin | null;
  wsTimeline: TimelinePlugin | null;

  constructor(private storageService: StorageService) {}

  destroy(): void {

    if (this.wsRegions) {
      this.wsRegions.destroy();
      this.wsRegions = null;
    }
    if (this.wsTimeline) {
      this.wsTimeline.destroy();
      this.wsTimeline = null;
    }

    //console.log('Destroy method called'); // Add log
    if (this.waveSurfer) {
      //console.log('WaveSurfer instance exists'); // Add log
      this.waveSurfer.destroy();
      this.waveSurfer = null;
    }
    //console.log(this.waveSurfer); // Add log
  }

  loadAudio(url: string): void {
    this.waveSurfer?.load(url);
  }
  play(): void {
    if (this.waveSurfer) {
      this.waveSurfer.play();
      console.log('Playing');
    }
  }
  playPause(): void {
    this.waveSurfer?.playPause();
  }

  isPlaying() {
    return this.waveSurfer?.isPlaying();
  }

  createWaveSurfer(container: string, songUrl: string, peaks: any, duration: number): WaveSurfer {


    this.waveSurfer = WaveSurfer.create({
      container: container,
      waveColor: 'white',
      cursorColor: 'yellow',
      barHeight: 0.5,
      height: 70,
      url: songUrl,
      peaks: peaks,
      duration: duration,
      fetchParams: {
        headers: {
          Authorization: 'Bearer ' + this.storageService.getToken(),
        },
      },
    });
    this.wsRegions = this.waveSurfer!.registerPlugin(RegionsPlugin.create())

    return this.waveSurfer;
  }

  createTimeline(container: string, duration: number, interval: number )
  {
    this.wsTimeline = this.waveSurfer!.registerPlugin(TimelinePlugin.create({
      container: container,
      timeInterval: interval,
      height: 20,
    //  duration: duration,
      //primaryLabelInterval: Math.ceil(duration / interval / 10),
      secondaryLabelInterval: 0,
    }))
  }

  updateTimeline(container: string, duration: number, interval: number) {
    if (interval < 1 || interval > 10) {
      return;
    }
    this.wsTimeline!.destroy();
    this.createTimeline(container, duration, interval);
  }


  getWaveSurfer(container: string, songUrl: string, peaks: any, duration: number): WaveSurfer {
    if (this.waveSurfer) {
      return this.waveSurfer;
    } else {
      const s = this.createWaveSurfer(container, songUrl, peaks, duration);
      return s;
    }
  }

  stop() {
    if(this.fadeAudio) {
      clearInterval(this.fadeAudio);
    }
    this.waveSurfer?.stop();
  }

  setTime(currentTime: number) {
    this.waveSurfer!.setTime(currentTime);
  }
  getCurrentTime() {
    if(!this.waveSurfer) {
      return 0;
    }
    return this.waveSurfer!.getCurrentTime();
  }

  setVolume(arg0: number) {
    this.waveSurfer?.setVolume(arg0);
  }

  seekTo(arg0: number) {
    this.waveSurfer!.setTime(arg0);
  }

  zoom(zoomLevel: number) {
    this.waveSurfer!.zoom(zoomLevel);
  }
  load(songUrl: string, peaks: any, duration: number) {
    this.waveSurfer!.load(songUrl, peaks);
  }

  getDuration() {
    return this.waveSurfer!.getDuration();
  }
  pause() {
    this.waveSurfer!.pause();
  }
  getVolume() {
    if(!this.waveSurfer) {
      return 0;
    }
    return this.waveSurfer!.getVolume();
  }

  fadeIn(duration: number, targetVolume: number) {
    if (this.fadeAudio) {
      cancelAnimationFrame(this.fadeAudio);
    }
    if (!this.waveSurfer) {
      return;
    }

    this.waveSurfer!.setVolume(0);
    this.waveSurfer!.play();

    const step = 0.01;
    const interval = 1000 / 30; // Limit to 30 FPS
    const startTime = performance.now();
    let volume = 0;

    const fade = (timestamp: number) => {
      const elapsedTime = timestamp - startTime;
      let progress = elapsedTime / duration;
      progress = Math.min(1, Math.max(0, progress)); // Clamp progress between 0 and 1

      volume = progress * targetVolume;
      this.waveSurfer!.setVolume(volume);

      if (progress < 1) {
        this.fadeAudio = requestAnimationFrame(fade);
      }
    };

    this.fadeAudio = requestAnimationFrame(fade);
  }


  fadeOut(duration: number, targetVolume: number) {
    if (this.fadeAudio) {
      cancelAnimationFrame(this.fadeAudio);
    }
    if (!this.waveSurfer) {
      return;
    }

    const step = 0.01;
    const interval = 1000 / 30; // Limit to 30 FPS
    const startTime = performance.now();
    const initialVolume = this.waveSurfer!.getVolume();

    const fade = (timestamp: number) => {
      const elapsedTime = timestamp - startTime;
      let progress = elapsedTime / duration;
      progress = Math.min(1, Math.max(0, progress)); // Clamp progress between 0 and 1

      const volume = initialVolume - progress * (initialVolume);
      this.waveSurfer!.setVolume(volume);

      if (progress < 1) {
        this.fadeAudio = requestAnimationFrame(fade);
      } else {
        this.waveSurfer!.pause();
      }
    };

    this.fadeAudio = requestAnimationFrame(fade);
  }

  cancelFade() {
    if (this.fadeAudio) {
      cancelAnimationFrame(this.fadeAudio);
    }
  }

  addRegion( id: string, start: number,  color: string, content: string | HTMLElement) {
    this.wsRegions!.addRegion({
      id: id,
      start: start,
      color: color,
      drag: true,
      resize: false,
      content: content
    });
  }

  removeRegion(id: string) {
    // add a function to the regions plugin that will allow me to remove a specific region by id by implementing a prototype function
    var regions = this.wsRegions!.getRegions();

    const updatedRegions = regions.filter(region => region.id !== id);
    this.wsRegions!.clearRegions();

    for (var i = 0; i < updatedRegions.length; i++) {
      this.addRegion(updatedRegions[i].id, updatedRegions[i].start, updatedRegions[i].color, updatedRegions[i].content as HTMLElement | string);
    }
  }


  on(event: any, func: (e: any) => void) {
    // switch for event
        this.waveSurfer!.on(event, (e) => {
          func(e);
        });
  }

  onRegion(event: any, func: (e: any) => void) {
    // switch for event
        this.wsRegions!.on(event, (e) => {
          func(e);
        });
  }

}
