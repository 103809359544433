import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SongDatabaseService {
  private _treeSource = new BehaviorSubject<TreeNode<any>[]>([]);
  private _songTree: TreeNode<any>[];

  constructor() {}

  get songTree(): TreeNode<any>[] {
    return this._songTree;
  }

  set songTree(songTree: TreeNode<any>[]) {
    this._songTree = songTree;
    this._treeSource.next(songTree);
  }

  get songDatabaseTree(): Observable<TreeNode<any>[]> {
    return this._treeSource.asObservable();
  }
}
