import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'console';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  throwError,
} from 'rxjs';
import { DeleteResponse } from 'src/app/views/shared/models/playlist';
import { Venue } from 'src/app/views/shared/models/venues';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

const Venue_API = environment.apiUrl + '/api/VenueLibrary';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  private selectedItem: any;
  private _selectedItemEvents$ = new BehaviorSubject<any>(null);

  private currentVenue: number;
  private _currentVenueEvents$ = new BehaviorSubject<number>(0);

  private _songDeletedFromPlaylist$ = new Subject<any>();
  private _songAddedToPlaylist$ = new Subject<any>();
  private _playlistDeleted$ = new Subject<any>();
  currentCustomer = new BehaviorSubject<string>('');
  constructor(private http: HttpClient) {}

  setSelectedItem(item: any) {
    this.selectedItem = item;
    this._selectedItemEvents$.next(item);
  }

  getSelectedItem() {
    return this.selectedItem;
  }

  selectedItemEvents$(): Observable<any> {
    return this._selectedItemEvents$.asObservable();
  }

  setCurrentVenue(venue: any) {
    this.currentVenue = venue;
    this._currentVenueEvents$.next(venue);
  }

  getCurrentVenue() {
    return this.currentVenue;
  }

  currentVenueEvents$(): Observable<any> {
    return this._currentVenueEvents$.asObservable();
  }

  get songDeletedFromPlaylist$(): Observable<any> {
    return this._songDeletedFromPlaylist$.asObservable();
  }

  setSongDeletedFromPlaylist$(songId: any) {
    this._songDeletedFromPlaylist$.next(songId);
  }

  get songAddedToPlaylist$(): Observable<string> {
    return this._songAddedToPlaylist$.asObservable();
  }

  setSongAddedToPlaylist$(songId: any) {
    this._songAddedToPlaylist$.next(songId);
  }

  setPlaylistDeleted$(playlistId: number) {
    this._playlistDeleted$.next(playlistId);
  }

  get playlistDeleted$(): Observable<any> {
    return this._playlistDeleted$.asObservable();
  }

  deletePlaylist(playlistId: number): Observable<HttpResponse<string>> {
    return this.http
      .post<string>(
        `${Venue_API}/DeletePlaylist`,
        { playlistId },
        {
          observe: 'response', // Observe the full HTTP response
          responseType: 'json', // Expect a plain string as the response
        }
      )
      .pipe(
        catchError((error) => {
          let errorStatus;
          if (error.status >= 400 && error.status < 500) {
            errorStatus = error.error.message;
          } else {
            errorStatus = 'Error. Please contact Groovit support';
          }

          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            title: errorStatus,
            icon: 'error',
            iconColor: 'black',
            background: '#EF5350CC',
          });

          return throwError(() => error);
        })
      );
  }
}
