import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { WeekDay } from './weekday';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateScheduleDataModel } from 'src/app/views/shared/models/CreateScheduleDataModel';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-publish-playlist-modal',
  templateUrl: './publish-playlist-modal.component.html',
  styleUrls: ['./publish-playlist-modal.component.scss'],
})
export class PublishPlaylistModalComponent implements OnInit{
  date: Date;
  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe
    ) {}

  @Input() venueId: number;
  @Input() teamId: number;
  @Input() event: any;
  @Input() scheduleId: number;
  @Input() scheduleDate: Date;

  isContainerVisible = false;

  playlistForm = new FormGroup({
    startDate: new FormControl(''),
    customize: new FormControl(false),
    days: new FormArray([]),
  });

  weekDays: WeekDay[] = [];

  ngOnInit(): void {
    // Calculate startTime and endTime using the scheduleDate for each day of the week
    for (let i = 0; i < 7; i++) {
      const scheduledDate = new Date(this.scheduleDate);

      // Calculate the StartTime based on the scheduleDate
      scheduledDate.setDate(scheduledDate.getDate() + i); // Move to the next day
      const startTime = new Date(scheduledDate); // Clone the date
      startTime.setMinutes(Math.round(startTime.getMinutes() / 30) * 30); // Round to nearest 30 minutes

      // Calculate the EndTime by adding 30 minutes to the StartTime
      const endTime = new Date(startTime.getTime() + 30 * 60 * 1000); // 30 minutes in milliseconds

      // Format StartTime and EndTime as strings in HH:mm format
      const formattedStartTime =  this.datePipe.transform(startTime, 'HH:mm') || '';
      const formattedEndTime = this.datePipe.transform(endTime, 'HH:mm') || '';

      // Initialize the corresponding day in the weekDays array
      this.weekDays.push({
        day: this.getDayOfWeek(i),
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        checked: true,
      });
    }
  }

  getDayOfWeek(dayIndex: number): string {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return days[dayIndex];
  }

  closeModal() {
    this.activeModal.close('Closed');
  }

  publishPlaylist() {
    const selectedDays = this.weekDays
      .filter((day) => day.checked)
      .map((day) => ({
        dayOfWeek: this.getDayNumber(day.day),
        //UTC Dates
        startTime: day.startTime,
        endTime: day.endTime,
      }));

    // get utc adjusted to local time

    const today  = new Date(this.scheduleDate);


    // convert the TimeString selectedDays to UTC TimeString
    selectedDays.forEach((day) => {
      const startTime = new Date(today);
      startTime.setHours(parseInt(day.startTime.split(':')[0]));
      startTime.setMinutes(parseInt(day.startTime.split(':')[1]));
      startTime.setSeconds(0);
      startTime.setMilliseconds(0);
      const endTime = new Date(today);
      endTime.setHours(parseInt(day.endTime.split(':')[0]));
      endTime.setMinutes(parseInt(day.endTime.split(':')[1]));
      endTime.setSeconds(0);
      endTime.setMilliseconds(0);
      day.startTime = startTime.toISOString();
      day.endTime = endTime.toISOString();
    });

    //console.log(selectedDays);

    const playlistData: CreateScheduleDataModel = {
      playlistId: this.event.id,
      venueId: this.venueId,
      teamId: this.teamId,
      scheduleId: this.scheduleId,
      splits: selectedDays.map((day) => ({
        dayOfWeek: day.dayOfWeek,
        startTime: day.startTime.split('T')[1].split('.')[0],
        endTime: day.endTime.split('T')[1].split('.')[0],
      })),
    };
    //console.log(playlistData);

    this.activeModal.close(playlistData);
  }



  getDayNumber(day: string): number {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return days.indexOf(day);
  }

  createDayFormGroup() {
    return this.fb.group({
      isOpen: this.fb.control(false),
      opening_at: this.fb.control({ hour: 9, minute: 0 }),
      closing_at: this.fb.control({ hour: 17, minute: 0 }),
    });
  }

  get days() {
    return this.playlistForm.get('days') as FormArray;
  }

  toggleContainer() {
    this.isContainerVisible = !this.isContainerVisible;
  }

  publish() {
    // handle the publish event
  }

  cancel() {
    // handle the cancel event
  }
}
