import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private _changeFolderEvents$ = new Subject<string>();
  private _removeItemFromTree$ = new Subject<any>();

  constructor() {}

  get changeFolderEvents$(): Observable<string> {
    return this._changeFolderEvents$.asObservable();
  }

  removeItemFromTree(item: any) {
    this._removeItemFromTree$.next(item);
  }

  get removeItemFromTree$(): Observable<any> {
    return this._removeItemFromTree$.asObservable();
  }

  changeFolder(value: string) {
    this._changeFolderEvents$.next(value);
  }
}
