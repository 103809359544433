import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Label } from 'src/app/views/shared/models/label';

@Component({
  selector: 'app-edit-label',
  templateUrl: './edit-label.component.html',
  styleUrls: ['./edit-label.component.scss'],
})
export class EditLabelComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}
  @Input() label: Label | null = null;
  title: string = '';
  labelForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    colour: new FormControl('', [Validators.required]),
  });

  ngOnInit(): void {
    if (this.label != null) {
      this.title = 'Edit Label';
      this.labelForm.patchValue(this.label);
    } else {
      this.title = 'Add Label';
      this.label = { name: '', colour: '', sequence: 0 } as Label;
    }
  }
  saveLabel() {
    const label = this.labelForm.value as Label;
    if (this.label != null) {
      label.id = this.label.id;
      label.sequence = this.label.sequence;
      label.colour = this.label.colour;
      label.name = this.labelForm.value?.name!;
    }
    this.activeModal.close(label);
  }
  closeModal() {
    this.activeModal.close('Closed');
  }
  colorChanged(colour: string) {
    if (this.label !== null) {
      this.label.colour = colour;
    }
  }
}
