import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'home',
    icon: 'home',
    link: '/dashboard',
  },

  {
    label: 'playlists',
    icon: 'headphones',
    link: '/playlists',
  },
  {
    label: 'Customers',
    icon: 'users',
    link: '/venues',
  },
];
