import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MenuItem, TreeDragDropService, TreeNode } from 'primeng/api';
import { LabelsService } from 'src/app/shared/services/labels.service';
import { MusicLibraryService } from 'src/app/shared/services/music-library.service';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import { Venue } from 'src/app/views/shared/models/venues';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { LanguageService } from 'src/app/shared/services/language.service';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { VenuesService } from 'src/app/shared/services/venues.service';
import { Label } from 'src/app/views/shared/models/label';
import { PlaylistPlayerService } from 'src/app/shared/services/playlist-player.service';

@Component({
  selector: 'app-manage-playlist',
  templateUrl: './manage-playlist.component.html',
  styleUrls: ['./manage-playlist.component.scss'],
  providers: [TreeDragDropService],
})
export class ManagePlaylistComponent implements OnInit, OnDestroy {
  playlistId: number;
  venueId: number;
  songLibraryTree: TreeNode[] = [];
  customerLibraryTree: TreeNode[] = [];
  songLibraryContextMenuItems: MenuItem[];
  customerLibraryContextMenuItems: MenuItem[];
  showModal = false;
  venues: Venue[];
  labels: Label[] = [];

  libraryService: MusicLibraryService | VenueLibraryService;

  leftTitle = 'customerLibrary';
  toggleLibrary = new BehaviorSubject<boolean>(true);
  changedFolder: string;
  private destroy$ = new Subject<void>();
  playlistName: string;
  customerName: string;
  constructor(
    private router: Router,
    private labelsService: LabelsService,
    public musicLibraryService: MusicLibraryService,
    public venueLibraryService: VenueLibraryService,
    private venuesService: VenuesService,
    private teamService: TeamsService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private playlistPlayerService: PlaylistPlayerService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.playlistId = navigation.extras.state.playlistId;
      this.venueId = navigation.extras.state.venueId;
      this.playlistName = navigation.extras.state.playlistName;
      this.customerName = navigation.extras.state.customerName;
    }
  }
  ngOnInit(): void {
    //Debug Only.
    //console.log('playlist id--> ', this.playlistId);
    if (this.playlistId == undefined) this.router.navigateByUrl('/dashboard');
    this.labelsService.labelsSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((labels) => {
        this.labels = labels;
      });

    this.libraryService = this.venueLibraryService;
    this.initializeContextMenu();
    this.teamService
      .getTeams()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.teamService.setLoadedTeams(data.teams);
        this.subscribeToTeamUpdates();
      });

    this.languageService.currentLanguage
      .pipe(takeUntil(this.destroy$))
      .subscribe((language) => {
        this.translateService.use(language);
      });

    this.labelsService.labelsSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((labels) => {
        this.labels = labels;
      });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.playlistPlayerService.Stop();
      }
    });
  }

  private subscribeToTeamUpdates() {
    this.teamService
      .getSelectedTeamUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {});
    const team = this.teamService.getSelectedTeam();

    this.venuesService
      .getVenues(team.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.venues = data;
        this.venues[0].id;
      });
  }

  private initializeContextMenu() {
    this.songLibraryContextMenuItems = [
      {
        label: 'New Folder',
        icon: 'pi pi-folder',
        command: () => {},
      },
      {
        label: 'Rename Folder',
        icon: 'pi pi-book',
        command: () => {},
      },
      {
        label: 'Delete Folder',
        icon: 'pi pi-times',
        command: () => {},
      },
      {
        label: 'Upload',
        icon: 'pi pi-upload',
        command: () => {},
      },
    ];

    this.customerLibraryContextMenuItems = [
      {
        label: 'New Folder',
        icon: 'pi pi-folder',
        command: () => {},
      },
      {
        label: 'Rename Folder',
        icon: 'pi pi-book',
        command: () => {},
      },
      {
        label: 'Delete Folder',
        icon: 'pi pi-times',
        command: () => {},
      },
    ];
  }

  toggleDatabase() {
    if (this.leftTitle === 'songDatabase') {
      this.libraryService = this.venueLibraryService;
      this.leftTitle = 'customerLibrary';
    } else {
      this.libraryService = this.musicLibraryService;
      this.leftTitle = 'songDatabase';
    }
    this.toggleLibrary.next(!this.toggleLibrary.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
