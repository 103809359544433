import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, forkJoin, of } from 'rxjs';
import { switchMap, take, takeUntil, catchError, delay } from 'rxjs/operators';

import { MusicLibraryService } from 'src/app/shared/services/music-library.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import { VenuesService } from 'src/app/shared/services/venues.service';
import { Venue } from 'src/app/views/shared/models/venues';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  teams: any[];
  selectedTeam: any;
  informationText = 'Loading...';
  urlToNavigate: string | null;

  constructor(
    private venuesService: VenuesService,
    private teamService: TeamsService,
    public musicLibraryService: MusicLibraryService,
    public venueLibraryService: VenueLibraryService,
    private paymentsService: PaymentService,
    private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.urlToNavigate = navigation?.extras?.state?.url || null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.teamService
      .getTeams()
      .pipe(
        take(1),
        switchMap((data) => {
          this.teams = data.teams;
          this.selectedTeam = this.teams.find((team) => team.teamType === 'P');
          this.teamService.setSelectedTeam(this.selectedTeam);
          console.log('get Teams switchmap');
          return this.teamService.getSelectedTeamUpdates();
        }),
        takeUntil(this.destroy$),
        catchError((error) => {
          console.error('Error fetching teams:', error);
          this.informationText = 'Error loading teams. Please try again.';
          return of([]); // Return an observable of an empty array on error
        })
      )
      .subscribe({
        next: (team) => {
          console.log('get Teams subscribe');
          this.loadTeamData(team);
        },
      });
  }

  private loadTeamData(team: any): void {
    of(null)
      .pipe(delay(1000), takeUntil(this.destroy$))
      .subscribe(() => {
        this.informationText = 'Get ready! 🎶';
      });

    this.musicLibraryService.downloadFolders(team.id);
    this.venueLibraryService.downloadFoldersForTeam(team.id);
    this.venueLibraryService.foldersForTeam
      .pipe(
        switchMap(() =>
          this.venueLibraryService.getTeamPlaylists(team.id, false, 1, 1000, [])
        ),
        take(1),
        takeUntil(this.destroy$),
        catchError((error) => {
          console.error('Error fetching team playlists:', error);
          return of([]); // Return an observable of an empty array on error
        })
      )
      .subscribe({
        next: (data) => {
          console.log('load team data subscribe');
          this.venueLibraryService.data = data;
          this.informationText = 'Setting up your workspace...';
          this.navigateAfterSetup();
        },
        error: (error) => console.error(error),
      });
  }

  private navigateAfterSetup(): void {
    forkJoin({
      venues: this.venuesService.getVenues(this.selectedTeam.id).pipe(take(1)),
      subscriptions: this.paymentsService.getSubscriptions().pipe(take(1))
    })
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: ({ venues, subscriptions }) => {
        this.venuesService.setVenues(venues);
        this.paymentsService.setSubscriptions(subscriptions);
        const navigateTo = this.urlToNavigate || '/dashboard';
        this.router.navigate([navigateTo]);
      },
      error: (error) => {
        console.error('Error fetching data:', error);
        this.informationText = 'Error setting up your workspace. Please try again.';
      }
    });
  }


}
