import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Filter } from 'src/app/views/shared/models/filter';
import { FolderHierarchy } from 'src/app/views/shared/models/folder-hierarchy';
import { Label } from 'src/app/views/shared/models/label';
import { Labels } from 'src/app/views/shared/models/labels';
import { PaginatedResult } from 'src/app/views/shared/models/paginated-result';
import { Song } from 'src/app/views/shared/models/song';
import { environment } from 'src/environments/environment';
import { TeamsService } from './teams.service';
import { ServiceHelper } from '../helpers/service-helper';
import { UpdateSongModel } from 'src/app/views/shared/models/update-song-model';
import { DeleteSongResponse } from 'src/app/views/shared/models/delete-song-response';

const Library_API = environment.apiUrl + '/api/MusicLibrary';

@Injectable({
  providedIn: 'root',
})
export class MusicLibraryService {
  folders: BehaviorSubject<FolderHierarchy> =
    new BehaviorSubject<FolderHierarchy>({} as FolderHierarchy);

  constructor(
    private http: HttpClient,
    private teamsService: TeamsService,
    private serviceHelper: ServiceHelper
  ) {}

  downloadFolders(teamId: number) {
    this.http
      .get<FolderHierarchy>(`${Library_API}/Folders?teamId=${teamId}`, {
        responseType: 'json',
      })
      .subscribe((data) => {
        this.folders.next(data);
      });
  }

  getFolders(): BehaviorSubject<FolderHierarchy> {
    return this.folders;
  }

  createFolder(
    teamId: number,
    parentFolder: string,
    folderName: string
  ): Observable<string> {
    return this.http.post<string>(
      `${Library_API}/CreateFolder`,
      {
        teamId: teamId,
        parentFolder: parentFolder,
        folderName: folderName,
      },
      {
        responseType: 'json',
      }
    );
  }

  renameFolder(folderId: string, name: string): Observable<string> {
    return this.http.post<string>(
      `${Library_API}/RenameFolder`,
      {
        folderId: folderId,
        name: name,
      },
      {
        responseType: 'json',
      }
    );
  }

  getSongsInFolder(
    folderId: string,
    venueId: number = 0,
    searchString: string = '',
    pageNo: number = 1,
    pageSize: number = 50,
    orderBy: string = 'Title',
    desc = false,
    tagIds: number[] = [],
    filterUsedSongs: boolean = false
  ): Observable<PaginatedResult<Song>> {
    let params = new HttpParams()
      .set('folderId', folderId)
      .set('searchString', searchString)
      .set('pageNo', pageNo.toString())
      .set('pageSize', pageSize.toString())
      .set('orderBy', orderBy)
      .set('desc', desc.toString())
      .set('venueId', venueId.toString())
      .set('FilterUsedSongs', filterUsedSongs);

    if (tagIds.length > 0) {
      tagIds.forEach((tagId) => {
        params = params.append('tagIds', tagId.toString());
      });
    }

    return this.http.get<PaginatedResult<Song>>(
      `${Library_API}/GetSongsInFolder`,
      {
        params,
        responseType: 'json',
      }
    );
  }

  deleteFolder(folderId: string): Observable<string> {
    return this.http.post<string>(
      `${Library_API}/DeleteFolder`,
      {
        folderId: folderId,
      },
      {
        responseType: 'json',
      }
    );
  }

  //get Labels for team

  getLabels(teamId: number, filters: Filter[] = []): Observable<Labels> {
    const { filterFields, filterValues } =
      this.serviceHelper.buildFilterQueryString(filters);

    // Build the full URL with query string
    const url = `${Library_API}/GetLabels?teamId=${teamId}${
      filterFields ? '&filterFields=' + filterFields : ''
    }${filterValues ? '&filterValues=' + filterValues : ''}`;

    return this.http.get<Labels>(url);
  }

  //update label
  updateLabel(label: Label): Observable<void> {
    return this.http.post<void>(`${Library_API}/UpdateLabel`, label, {
      responseType: 'json',
    });
  }

  //add label
  addLabel(label: Label): Observable<void> {
    const payLoad = label as any;
    payLoad.teamId = this.teamsService.getSelectedTeam().id;

    return this.http.post<void>(`${Library_API}/AddLabel`, payLoad, {
      responseType: 'json',
    });
  }

  updateSong(song: UpdateSongModel): Observable<Song> {
    return this.http.post<Song>(`${Library_API}/UpdateSong`, song, {
      responseType: 'json',
    });
  }

  deleteSong(songId: number): Observable<DeleteSongResponse> {
    return this.http.post<any>(
      `${Library_API}/DeleteSong`,
      { songId: songId },
      {
        responseType: 'json',
      }
    );
  }
}
