import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  enableProdMode,
} from '@angular/core';
import {
  ColumnMode,
  DatatableComponent,
  DatatableRowDetailDirective,
} from '@swimlane/ngx-datatable';
import { Page } from '../../shared/models/page';
import { Filter } from '../../shared/models/filter';
import { EditPlaylistComponent } from './edit-playlist/edit-playlist.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Playlist } from '../../shared/models/playlist';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import { CreatePlaylistModel } from '../../shared/models/create-playlist';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { Team } from '../../shared/models/team';
import { Subject, map, pipe, takeUntil, filter } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  faArrowUpRightFromSquare,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { DeletePlaylistDialogComponent } from 'src/app/delete-playlist-dialog/delete-playlist-dialog.component';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PlaylistService } from 'src/app/shared/services/playlist.service';
import { Season } from '../../shared/models/season';

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlaylistsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  columns: any[] = [];
  data: any[];
  columnMode = ColumnMode.force;
  groupedData: any[];
  page = new Page();
  loading = false;
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  faPen = faPen;
  faTrash = faTrash;
  displayedColumns: string[] = [
    'position',
    'venuename',
    'name',
    'description',
    'mood',
    'season',
    'actions',
  ];

  customersList = [];
  seasonList = [];
  moodList = [];

  dataSource = new MatTableDataSource();
  totalItems = 100;

  rowDetail: DatatableRowDetailDirective;

  @ViewChild('myTable') mytable: DatatableComponent;
  sortedBy: string;
  sortedDirection: string;
  filters: Filter[] = [];
  selectedPlaylist: PlayListItem | null;
  selectedTeam: Team;
  initialData: any;
  selectedMoodName = '';
  selectedSeasonName = '';
  selectedCustomerName = '';
  actualData: any;
  searchTextFilterValue = '';
  seasons: Season[];
  moodsOfDay: any;
  pageIndex: number;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private venueLibraryService: VenueLibraryService,
    private teamsService: TeamsService,
    private playlistService: PlaylistService,
    public dialog: MatDialog
  ) {}
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.pageIndex = 0;
    this.selectedTeam = this.teamsService.selectedTeam;
    if (this.venueLibraryService.isInitialized === false) {
      this.router.navigate(['/loading'], { state: { url: '/playlists' } });
      return;
    }
    this.dataSource = new MatTableDataSource();
    this.venueLibraryService
      .getSeasons(this.teamsService.getSelectedTeam().id)
      .subscribe((data: Season[]) => {
        this.seasons = data;
        console.log(data);
      });
    this.venueLibraryService
      .getMoodOfDay(this.teamsService.getSelectedTeam().id)
      .subscribe((data: any) => {
        this.moodsOfDay = data;
        console.log(data);
      });
    this.totalItems = this.venueLibraryService.data.items.length;
    this.initialData = this.venueLibraryService.data;
    if (this.teamsService.selectedTeam) this.getPlaylists(this.initialData);

    this.playlistService.playlistDeleted$.subscribe((id) => {
      this.getPlaylists();
    });

    this.refreshPlaylistData$.subscribe((result) => {
      if (result && result.name != '') {
        this.venueLibraryService
          .getTeamPlaylists(this.selectedTeam.id, false, 1, 1000, [])
          .subscribe((data: any) => {
            this.venueLibraryService.data = data;
            const playlists = data.items?.map((p: any) => p.playlists).flat();
            const playlistToNavigate = playlists.filter(
              (p) =>
                p.name === result.name && p.description === result.description
            );
            this.router.navigate(['/playlist'], {
              state: {
                playlistId: playlistToNavigate[0].id,
                venueId: result.venueId,
                playlistName: playlistToNavigate[0].name,
                customerName: result.name,
              },
            });
          });
      }
    });
  }
  goToPlayList(event) {
    const id = event.id;
    const venueId = event.venueid;
    console.log(event.name);
    this.router.navigate(['/playlist'], {
      state: {
        playlistId: id,
        venueId: venueId,
        playlistName: event.name,
        customerName: event.venuename,
      },
    });
  }

  deletePlaylist(event) {
    //console.warn(event);
    const id = event.id;
    const venueId = event.venueid;
    console.log(event);

    //refresh the playlist after the deletion operation is successful
    this.dialog.open(DeletePlaylistDialogComponent, { data: event });
  }
  editDetails(event) {}
  onPaginateChange(event?: PageEvent) {
    console.log(event);
    this.page.pageNumber = event?.pageIndex ?? 0;
    this.page.size = event?.pageSize ?? 10;
    this.doSelectFiltering();
  }
  getPlaylists(data: any = null) {
    if (data) {
      this.showData(data);
    } else
      this.venueLibraryService
        .getTeamPlaylists(this.selectedTeam.id, false, 1, 1000, this.filters)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {
          // Update the data source for the table
          // this.dataSource.data = paginatedItems;
          this.actualData = data;
          this.doSelectFiltering();
        });
  }

  private showData(data: any, filteredDataToShow: PlayListItem[] = []) {
    this.data = data.items;
    // I want the custmerlist to be order by name
    this.customersList = data.items?.map((d: any) => d.name).sort();
    this.moodList = data.items
      ?.map((d: any) => d.playlists.map((p: any) => p.moodOfDay.name))
      .flat(); // Flatten the array

    // Remove duplicates
    this.moodList = Array.from(new Set(this.moodList));
    this.moodList = this.moodList.sort();
    this.seasonList = data.items
      ?.map((d: any) => d.playlists.map((p: any) => p.season.name))
      .flat(); // Flatten the array
    this.seasonList = Array.from(new Set(this.seasonList)); // Remove duplicates
    this.seasonList = this.seasonList.sort();
    let dataToShow: any = [];
    let sequence = 1;
    for (let index = 0; index < data.items.length; index++) {
      for (
        let plIndex = 0;
        plIndex < data.items[index].playlists.length;
        plIndex++
      ) {
        dataToShow.push(
          new PlayListItem(
            sequence++, //index
            data.items[index].id, // venueId
            data.items[index].name, // venueName
            data.items[index].playlists[plIndex].name, //Playlist Name
            data.items[index].playlists[plIndex].description, //Playlist Description
            data.items[index].playlists[plIndex].moodOfDay.name, //Mood,
            data.items[index].playlists[plIndex].moodOfDay.id, //Mood Id
            data.items[index].playlists[plIndex].season.id, //Season Id
            data.items[index].playlists[plIndex].season.name, //Season
            data.items[index].playlists[plIndex].id //Playlist Id
          )
        );
      }
    }
    if (filteredDataToShow.length > 0) {
      dataToShow = dataToShow.filter(
        (d) => filteredDataToShow.findIndex((fd) => fd.id === d.id) > -1
      );
    }
    this.actualData = data;
    this.totalItems = dataToShow.length;
    const startIndex = this.page.pageNumber * this.page.size;
    const endIndex = startIndex + this.page.size;
    dataToShow = dataToShow.filter((x) => {
      return (
        x.name
          .toLowerCase()
          .includes(this.searchTextFilterValue.toLowerCase()) ||
        x.venuename
          .toLowerCase()
          .includes(this.searchTextFilterValue.toLowerCase()) ||
        x.description
          .toLowerCase()
          .includes(this.searchTextFilterValue.toLowerCase()) ||
        x.mood
          .toLowerCase()
          .includes(this.searchTextFilterValue.toLowerCase()) ||
        x.season
          .toLowerCase()
          .includes(this.searchTextFilterValue.toLowerCase())
      );
    });
    this.page.totalPages = Math.ceil(this.totalItems / this.page.size);
    const paginatedItems = dataToShow.slice(startIndex, endIndex);

    this.dataSource.data = paginatedItems;
    this.totalItems = dataToShow.length;
  }

  onFilter(a, b) {}

  getFilterValue(columnName: string) {
    var filterColumn = this.columns.find(
      (x) => x.prop == columnName
    ).filterProperty;

    const filterEntry = this.filters.find((x) => x.field == filterColumn);
    if (filterEntry) {
      return filterEntry.value;
    } else {
      return '';
    }
  }

  openModal(playlist: PlayListItem | null) {
    console.log(playlist);
    const modalRef = this.modalService.open(EditPlaylistComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    this.selectedPlaylist = playlist;

    console.log(playlist);
    modalRef.componentInstance.data = playlist;
    modalRef.componentInstance.seasons = this.seasons;
    modalRef.componentInstance.moodsOfDay = this.moodsOfDay;
    modalRef.componentInstance.label = playlist;
    modalRef.result.then((result: CreatePlaylistModel) => {
      console.log(result);
      if (result.id === null) {
        console.log('create');
        this.createPlaylist(result);
      } else {
        console.log('update');
        console.log(this.selectedPlaylist);
        this.updatePlaylist(result, this.selectedPlaylist!);
      }
    });
  }
  refreshPlaylistData$ = new Subject<CreatePlaylistModel>();
  createPlaylist(model: CreatePlaylistModel) {
    model.moodOfDayId = model.moodOfDayId;
    model.seasonId = model.seasonId;
    model.moodOfDay = model.moodOfDay;
    model.season = model.season;

    this.venueLibraryService
      .createPlaylist(model)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.refreshPlaylistData$.next(model);
      });
  }

  updatePlaylist(model: CreatePlaylistModel, playlist: PlayListItem) {
    model.moodOfDayId = model.moodOfDayId;
    model.seasonId = model.seasonId;
    model.moodOfDay = model.moodOfDay;
    model.season = model.season;
    this.venueLibraryService.updatePlaylist(model).subscribe((result) => {
      this.getPlaylists(null);
    });
  }

  sortBy(columnName: string, sortFn: Function) {
    this.sortedBy = columnName;
    this.sortedDirection = this.sortedDirection == 'desc' ? 'asc' : 'desc';
    sortFn();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTextFilterValue = filterValue;
    this.page.pageNumber = 0;
    this.pageIndex = 0;
    this.paginator.pageIndex = 0;
    this.doSelectFiltering();
  }
  onCustomerChange(event: any) {
    this.selectedCustomerName = event.target.value;
    this.page.pageNumber = 0;
    this.pageIndex = 0;
    this.paginator.pageIndex = 0;

    this.doSelectFiltering();
  }
  onMoodChange(event: any) {
    this.selectedMoodName = event.target.value;
    this.page.pageNumber = 0;
    this.pageIndex = 0;
    this.paginator.pageIndex = 0;

    this.doSelectFiltering();
  }
  onSeasonChange(event: any) {
    this.selectedSeasonName = event.target.value;
    this.page.pageNumber = 0;
    this.pageIndex = 0;
    this.paginator.pageIndex = 0;

    this.doSelectFiltering();
  }

  doSelectFiltering() {
    // Filter or manipulate your data based on the selected customer
    let filteredDataToShow: PlayListItem[] = [];
    let sequence = 1;

    // Assuming `this.data` holds your original items array
    this.data.forEach((item) => {
      if (
        item.name === this.selectedCustomerName ||
        this.selectedCustomerName === ''
      ) {
        item.playlists.forEach((playlist) => {
          if (
            (playlist.moodOfDay.name === this.selectedMoodName ||
              this.selectedMoodName === '') &&
            (this.selectedSeasonName === '' ||
              playlist.season.name === this.selectedSeasonName)
          )
            filteredDataToShow.push(
              new PlayListItem(
                sequence++,
                item.id,
                item.name,
                playlist.name,
                playlist.description,
                playlist.moodOfDay.name,
                playlist.moodOfDay.id,
                playlist.season.id,
                playlist.season.name,
                playlist.id
              )
            );
        });
      }
    });

    // Update the table data source
    this.showData(this.actualData, filteredDataToShow);
  }
}

class PlayListItem {
  constructor(
    public index: number,
    public venueid: number,
    public venuename: string,
    public name: string,
    public description: string,
    public mood: string,
    public moodId: number,
    public seasonId: number,
    public season: string,
    public id: number
  ) {}
}
