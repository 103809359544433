import { DATE_PIPE_DEFAULT_TIMEZONE, DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourMinuteSecondFormat'
})
export class HourMinuteSecondFormatPipe implements PipeTransform {

  transform(value: number): string {
    if(value === null || value === undefined ) return '';
    const datePipe = new DatePipe('en-US');
    const date = new Date(0,0,0).setSeconds(value); // convert seconds to milliseconds
    //set timezone to utc

    return datePipe.transform(date, 'HH:mm:ss')?? '';
  }

}
