import {
  AfterViewInit,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import { StorageService } from 'src/app/shared/storage.service';
import { Song } from '../../models/song';

import { SongDatabaseService } from 'src/app/shared/services/song-database.service';
import { Label } from '../../models/label';
import { MusicLibraryService } from 'src/app/shared/services/music-library.service';
import { UpdateSongModel } from '../../models/update-song-model';
import { TreeNode } from 'primeng/api';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import {
  DeleteSongResponse,
  SongUsedInFolder,
  SongUsedInPlayList,
} from '../../models/delete-song-response';
import { Howl } from 'howler';
import { SongPeaks } from '../../models/song-peaks';
import { WaveSurferService } from 'src/app/shared/services/wave-surfer.service';
import { createWaveSurferService } from 'src/app/shared/helpers/wavesurferFactory';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { GoogleTagManagerImplService } from 'src/app/shared/services/google-tag-manager.service';

@Component({
  selector: 'app-editor-player',
  templateUrl: './editor-player.component.html',
  styleUrls: ['./editor-player.component.scss'],
  providers: [WaveSurferService],
})
export class EditorPlayerComponent implements OnInit, OnDestroy {
  @Input() song!: Song;
  @Input() selectedNode: TreeNode<any>;
  @Input() isCustomerLibrary: boolean = false;
  @Input() isSongDatabase: boolean = false;
  @Input() peaks: SongPeaks;
  @Output() songUpdateEvent = new Subject<UpdateSongModel>();
  @Output() songUpdated = new Subject<UpdateSongModel>();

  currentTime: number = 0.001;
  duration: number = 0;
  private destroy$ = new Subject<void>();
  interval = 5;

  songTree: TreeNode<any>[];

  //markers: Markers;
  teamLabels: Label[] = [];

  selectedLabels: Label[] = [];
  otherLabels: Label[] = [];
  selectedNodes: any[];
  fadeLeftCheckboxSelected: boolean = false;
  fadeRightCheckboxSelected: boolean = false;
  usedInFolders: SongUsedInFolder[] = [];
  usedInPlaylists: SongUsedInPlayList[] = [];
  MathAbs = Math.abs;
  // create and observable for the fade in

  fadeOutBehaviourSubject = new BehaviorSubject<boolean>(
    this.fadeRightCheckboxSelected
  );
  songBehaviourSubject = new BehaviorSubject<Song>(this.song);

  songObservable = this.songBehaviourSubject.asObservable();

  startTimePreviewColor = '#667';
  endTimePreviewColor: string;
  volume: number;

  skippingBackward: boolean = false;

  isDeleting: boolean = false;
  isSaving: boolean = false;

  monitorInterval: NodeJS.Timeout;
  monitoring: boolean;
  waveClicked: boolean = false;
  spriteLoaded: boolean = false;
  sprite: Howl | null = null;
  songUrl: string;
  waveSurferReady: boolean = false;

  visiblePlayerButtons: string[] = [];
  waveSurferService: WaveSurferService;

  constructor(
    private venueLibraryService: VenueLibraryService,
    private teamService: TeamsService,
    private storageService: StorageService,
    private songDatabaseService: SongDatabaseService,
    public activeModal: NgbActiveModal,
    private musicLibraryService: MusicLibraryService,
    private dashboardService: DashboardService,
    private modalService: NgbModal,
    private injector: Injector,
    private gaService: GoogleTagManagerImplService

  ) {
    this.waveSurferService = createWaveSurferService(this.injector);
  }

  zoomLevel = 2.5;
  songform = new FormGroup({
    artist: new FormControl('', [Validators.required]),
    title: new FormControl('', [Validators.required]),
    selectedNode: new FormControl(),
  });

  private currentTimeSubject = new Subject<number>();
  currentTime$ = this.currentTimeSubject.asObservable();

  setCurrentTime(time: number) {
    this.currentTimeSubject.next(time);
  }
  getVolume() {
    if (!this.waveSurferService) return 0;
    return this.waveSurferService.getVolume();
  }

  isPlaying(): boolean {
    if (this.waveSurferService?.isPlaying() == undefined) return false;
    return this.waveSurferService!.isPlaying() ? true : false;
  }
  getCurrentTime() {
    if (!this.waveSurferService) return 0;
    return this.waveSurferService.getCurrentTime();
  }

  seeking = false;

  ngOnInit(): void {
    this.gaService.trackModalOpen('Song Editor', this.song.id);
    this.visiblePlayerButtons = [
      'cue-start-button',
      'play-fade-left-button',
      'backward-seek-10ms-button',
      'backward-seek-100ms-button',
      'play-button',
      'pause-button',
      'forward-seek-10ms-button',
      'forward-seek-100ms-button',
      'play-fade-right-button',
      'cue-mix-button',
    ];
    this.songUrl = this.venueLibraryService.getAudioStreamUrl(this.song.id);

    const self = this;
    this.duration = this.song.duration;

    this.fadeLeftCheckboxSelected = this.song.songProperties.fadeInDuration > 0;
    this.fadeRightCheckboxSelected =
      this.song.songProperties.fadeOutDuration != 0 &&
      this.song.songProperties.fadeOutDuration != null;
    this.createSprite(0, 0);

    this.setPreviewStartTimeColour();
    this.setPreviewEndTimeColour();
    this.initializePlayer();

    this.setVolume(0.85);

    this.songObservable.pipe(takeUntil(this.destroy$)).subscribe((song) => {
      // this.redrawMarkers();
      this.setPreviewStartTimeColour();
      this.setPreviewEndTimeColour();
    });

    this.songform.patchValue(this.song);
    this.selectedLabels = this.song.labels.map((label) => label);
    this.songDatabaseService.songDatabaseTree
      .pipe(takeUntil(this.destroy$))
      .subscribe((songTree: any) => {
        this.songTree = songTree;

        //Get Folder of the orignal song.
        if (this.selectedNode) {
          const node = this.findNodeById(this.songTree, this.selectedNode.data);
          if (node) {
            this.songform.patchValue({ selectedNode: node });
          }
        }
      });

    this.currentTime$.pipe(takeUntil(this.destroy$)).subscribe((time) => {
      this.waveSurferService.setTime(time);
    });
  }

  private setSelectedNode(nodeData: string): void {
    const findNode = (nodes: TreeNode[]): TreeNode | null => {
      for (const node of nodes) {
        if (node.data === nodeData) {
          return node;
        }
        if (node.children) {
          const foundNode = findNode(node.children);
          if (foundNode) {
            return foundNode;
          }
        }
      }
      return null;
    };
  }

  isRootFolder(treeNode: TreeNode<any> | undefined) {
    return treeNode?.parent === undefined;
  }

  private findNodeById(treeNodes: TreeNode[], idToFind: any): TreeNode | null {
    for (const node of treeNodes) {
      if (node.data === idToFind) {
        return node;
      }

      if (node.children) {
        const result = this.findNodeById(node.children, idToFind);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  onLabelsChanged(selectedLabels: Label[], otherLabels: Label[]) {
    this.selectedLabels = selectedLabels;
    this.otherLabels = otherLabels;
  }

  initializePlayer() {
    this.waveSurferService.getWaveSurfer(
      '#waveform',
      this.songUrl,
      this.peaks,
      this.song.duration
    );
    this.waveSurferService.setVolume(0);

    this.waveSurferService.createTimeline('#timeline', this.duration, 5);
    this.initializeListeners();
  }

  private initializeListeners() {
    this.waveSurferService.on('seeking', (e) => {
      //console.log("debug: seeking");
      if (this.waveClicked) {
        this.currentTime = this.waveSurferService.getCurrentTime() || 0;
        this.waveSurferService.cancelFade();
        this.setVolume(0.85);
        this.waveClicked = false;
      }
    });

    this.waveSurferService.on('click', (e) => {
      if (this.waveSurferReady) {
        this.waveClicked = true;
      } else {
        //console.log('waveSurfer not Ready');
      }
    });

    this.waveSurferService.on('ready', () => {
      this.waveSurferService.seekTo(0);
      this.zoomLevel = 740 / this.waveSurferService.getDuration();
      this.initializeMarkers();
      this.waveSurferReady = true;
    });

    this.waveSurferService.onRegion('region-updated', (region) => {
      //console.log(region);

      this.waveSurferService.cancelFade();
      this.setVolume(this.song.songProperties.volume);

      const time = Math.round(region.start * 100) / 100;
      this.waveClicked = true;
      if (region.id === 'Start') {
        if (time <= this.song.songProperties.mixTime) {
          this.song.songProperties.startTime = time;
          this.waveSurferService.setTime(time);
          if (this.fadeLeftCheckboxSelected) {
            this.waveSurferService.removeRegion('Fade In');
            this.drawFadeInMarker();
          }
        } else {
          this.waveSurferService.removeRegion('Start');
          this.drawStartMarker();
        }
      }

      if (region.id === 'Mix') {
        if (time >= this.song.songProperties.startTime) {
          this.song.songProperties.mixTime = time;
          this.waveSurferService.setTime(time);
          if (this.fadeRightCheckboxSelected) {
            this.waveSurferService.removeRegion('Fade Out');
            this.drawFadeOutMarker();
          }
        } else {
          this.waveSurferService.removeRegion('Mix');
          this.drawMixMarker();
        }
      }

      if (region.id === 'Fade In') {
        if (time >= this.song.songProperties.startTime) {
          this.song.songProperties.fadeInDuration =
            time - this.song.songProperties.startTime;
        } else {
          this.waveSurferService.removeRegion('Fade In');
          this.drawFadeInMarker();
        }
      }

      if (region.id === 'Fade Out') {
        this.song.songProperties.fadeOutDuration =
          time - this.song.songProperties.mixTime;
      }
      this.songBehaviourSubject.next(this.song);
      this.waveClicked = false;
    });
  }

  // currentCursorSeek: Timeout; // Replace with appropriate type

  currentCursorSeek: any;
  forwardSeek(val) {
    this.waveSurferService.cancelFade();
    this.setVolume(this.song.songProperties.volume);

    if (val == undefined) {
      val = 0.1;
    }

    const currentSeek = this.waveSurferService.getCurrentTime();
    const seekTo = currentSeek + val;
    this.waveSurferService.stop();
    this.waveSurferService.setTime(seekTo);
    this.currentSeek({ seconds: currentSeek });
    this.playSprite(currentSeek * 1000, val * 1000);
  }

  currentSeek(c) {
    //console.log(c);
    this.currentCursorSeek = c;
  }

  createSprite(start, stop) {
    this.sprite = new Howl({
      src: [this.songUrl],
      sprite: {
        seekSprite: [start, stop],
      },
      autoplay: false,
      loop: false,
      preload: true,
      html5: false,
      format: ['mp3'],
      onload: () => {
        //console.log('sprite loaded');
        this.spriteLoaded = true;
      },
    });
    this.sprite.volume(1);
    //console.log('sprite created');
  }

  playSprite(start, stop) {
    if (this.sprite?.state() != 'loaded') {
      //console.log('Sprite Playback aborted, not yet loaded');
      return;
    }

    if (this.sprite?.playing()) {
      //console.log('sprite already playing');
      return;
    }

    (this.sprite as any)._sprite.seekSprite = [start, stop];

    if (this.sprite?.state() === 'loaded') {
      this.spriteLoaded = true;
      this.sprite.play('seekSprite');
    } else {
      //console.log('sprite not loaded yet');
      this.playSprite(start, stop);
      this.spriteLoaded = false;
    }
  }

  backwardSeek(val) {
    this.waveSurferService.cancelFade();
    this.setVolume(this.song.songProperties.volume);

    if (val == undefined) {
      val = 0.1;
    }
    const currentSeek = this.waveSurferService.getCurrentTime();
    const seekTo = currentSeek - val;
    this.waveSurferService.stop();
    this.waveSurferService.setTime(seekTo);
    this.currentSeek({ seconds: seekTo });

    this.playSprite(seekTo * 1000, val * 1000);
    // this.waveSurfer.play(seekTo + 0.01);
  }

  resetStartTime() {
    this.song.songProperties.startTime = 0;
    this.songBehaviourSubject.next(this.song);
    this.waveSurferService.removeRegion('Start');
    this.drawStartMarker();
  }

  resetMixTime() {
    this.song.songProperties.mixTime = this.duration;
    this.songBehaviourSubject.next(this.song);
    this.waveSurferService.removeRegion('Mix');
    this.drawMixMarker();
  }

  resetFadeIn() {
    this.waveSurferService.removeRegion('Fade In');
  }

  resetFadeOut() {
    this.waveSurferService.removeRegion('Fade Out');
  }

  folderSelected(event: any) {
    this.selectedNode = event.node;
  }

  SaveSong() {
    this.isSaving = true;
    const team = this.teamService.getSelectedTeam();
    this.musicLibraryService.downloadFolders(team!.id);
    this.dashboardService.changeFolder(this.selectedNode.data as string);

    if (!this.fadeLeftCheckboxSelected) {
      this.song.songProperties.fadeInDuration = 0;
    }

    if (!this.fadeRightCheckboxSelected) {
      this.song.songProperties.fadeOutDuration = 0;
    }

    const updateSongModel = {
      id: this.song.id,
      artist: this.songform.value.artist,
      title: this.songform.value.title,
      labels: this.selectedLabels.map((l) => l.id),
      startTime:
        Math.round((this.song?.songProperties?.startTime ?? 0) * 100) / 100,
      mixTime:
        Math.round((this.song?.songProperties?.mixTime ?? 0) * 100) / 100,
      fadeInDuration:
        Math.round((this.song?.songProperties?.fadeInDuration ?? 0) * 100) /
        100,
      fadeOutDuration:
        Math.round((this.song?.songProperties?.fadeOutDuration ?? 0) * 100) /
        100,
      //We cannot allow to change folders from the customer library.
      //If folderId is null, the backend will ignore the change.
      folderId: this.isSongDatabase
        ? this.selectedNode.data
        : '00000000-0000-0000-0000-000000000000',
    } as UpdateSongModel;

    this.musicLibraryService
      .updateSong(updateSongModel)
      .pipe(takeUntil(this.destroy$))
      .subscribe((song) => {
        this.activeModal.close(song);
      });

    this.isSaving = true;
  }

  formatTime(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
  }

  onPause() {
    this.waveSurferService.pause();
  }

  onPlay() {
    this.setVolume(0.85);
    this.waveSurferService.play();
  }
  onGoToStart() {
    this.setVolume(0.85);
    this.waveSurferService.seekTo(0);
  }
  onGoToEnd() {
    this.setVolume(0.85);
    this.waveSurferService.seekTo(1);
  }

  onPlayFadeLeft() {
    this.waveSurferService.cancelFade();
    this.setVolume(this.song.songProperties.volume);
    const shouldPlay = this.waveSurferService.isPlaying();
    this.waveSurferService.stop();
    this.waveSurferService.setTime(this.song.songProperties.startTime);
    this.setVolume(0.85);
    if (shouldPlay == false) {
      return;
    }
    if (this.fadeLeftCheckboxSelected) {
      const fade_in_duration = this.song.songProperties.fadeInDuration * 1000;
      this.waveSurferService.fadeIn(fade_in_duration, 0.85);
    }
    this.waveSurferService.play();
  }

  onPlayFadeRight() {
    this.waveSurferService.cancelFade();
    this.setVolume(this.song.songProperties.volume);
    const self = this;
    const shouldPlay = this.waveSurferService.isPlaying();
    this.waveSurferService.stop();
    this.waveSurferService.setTime(this.song.songProperties.mixTime);
    this.waveSurferService.setVolume(0.85);

    if (this.fadeRightCheckboxSelected) {
      const seekTo =
        this.song.songProperties.fadeOutDuration < 0
          ? this.song.songProperties.mixTime +
            this.song.songProperties.fadeOutDuration
          : this.song.songProperties.mixTime;
      this.waveSurferService.setTime(seekTo);
      const fade_out_duration =
        Math.abs(this.song.songProperties.fadeOutDuration) * 1000;
      if (shouldPlay == true) {
        //    setTimeout(function() {
        self.waveSurferService!.fadeOut(fade_out_duration, 0.85);
        //  }, 0)
      }
    }

    if (shouldPlay == false) {
      return;
    }

    this.waveSurferService.play();
  }

  closeModal() {
    this.waveSurferService.stop();
    this.activeModal.close();
  }

  onZoomIn() {
    this.zoomLevel = this.zoomLevel + 740 / this.duration / 10;
    this.waveSurferService.zoom(this.zoomLevel);
    // this.increaseInterval();
  }

  onZoomOut() {
    if (this.zoomLevel <= 740 / this.duration) {
      return;
    }
    this.zoomLevel = this.zoomLevel - 740 / this.duration / 10;
    this.waveSurferService.zoom(this.zoomLevel);
    // this.decreaseInterval();
  }

  onZoomReset() {
    this.zoomLevel = 740 / this.duration;
    this.waveSurferService.zoom(this.zoomLevel);
    this.interval = 5;
    //this.waveSurferService.updateTimeline(this.song.duration, this.interval);
  }

  increaseInterval() {
    this.interval += 1;
    // this.waveSurferService.updateTimeline(this.song.duration, this.interval);
  }

  decreaseInterval() {
    this.interval -= 1;
    // this.waveSurferService.updateTimeline(this.song.duration, this.interval);
  }

  openConfirmationModal(content: TemplateRef<any>) {
    this.modalService
      .open(content, { backdrop: 'static', keyboard: false }) // backdrop and keyboard options ensure that the modal is not closed by clicking outside of the modal window
      .result.then((result) => {
        const closeResult = 'Modal closed : ' + result;
        if (result === 'Confirmed') {
        }
        if (result === 'Ok') {
          // this.modalService.dismissAll();
        }
      })
      .catch((res) => {});
  }
  deleteSongProcess() {
    this.isDeleting = true;
    this.musicLibraryService
      .deleteSong(this.song.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (deleteResult) => {
          this.processDeleteSongResult(deleteResult);
          this.isDeleting = false;
          const team = this.teamService.getSelectedTeam();
          this.musicLibraryService.downloadFolders(team!.id);
        },
        error: (error) => {
          if (error.status) {
            switch (error.status) {
              case 403:
                this.processDeleteSongResult(error.error);
                break;
              default:
                console.error(error);
            }
            this.isDeleting = false;
          }
        },
      });
  }
  deleteSong() {
    this.waveSurferService.stop();
    this.deleteSongProcess();
    //  to be confirmed that this is ok for updating th remaining songs.
    this.dashboardService.changeFolder(this.selectedNode.data as string);
  }

  processDeleteSongResult(deleteResult: DeleteSongResponse) {
    if (
      (deleteResult.usedInFolders && deleteResult.usedInFolders.length > 0) ||
      (deleteResult.usedInPlaylists && deleteResult.usedInPlaylists.length > 0)
    ) {
      this.usedInFolders = deleteResult?.usedInFolders ?? [];
      this.usedInPlaylists = deleteResult?.usedInPlaylists ?? [];

      //
    } else {
      this.isDeleting = false;
      this.activeModal.close(this.song);
      this.modalService.dismissAll();
    }
  }

  onStartCue() {
    this.song.songProperties.startTime =
      this.waveSurferService.getCurrentTime();
    this.songBehaviourSubject.next(this.song);
    this.waveSurferService.removeRegion('Start');
    this.drawStartMarker();
    if (this.fadeLeftCheckboxSelected) {
      this.waveSurferService.removeRegion('Fade In');
      this.drawFadeInMarker();
    }
  }

  onMixCue() {
    this.song.songProperties.mixTime = this.waveSurferService.getCurrentTime();
    this.songBehaviourSubject.next(this.song);
    this.waveSurferService.removeRegion('Mix');
    this.drawMixMarker();

    if (this.fadeRightCheckboxSelected) {
      this.waveSurferService.removeRegion('Fade Out');
      this.drawFadeOutMarker();
    }
  }

  private initializeMarkers() {
    if (
      this.song.songProperties.mixTime === null ||
      this.song.songProperties.mixTime === 0
    ) {
      this.song.songProperties.mixTime = this.song.duration;
      this.songBehaviourSubject.next(this.song);
    }

    this.song.songProperties.startTime ??= 0;

    this.drawStartMarker();
    this.drawMixMarker();

    if (this.fadeLeftCheckboxSelected) {
      this.drawFadeInMarker();
    }

    if (this.fadeRightCheckboxSelected) {
      this.drawFadeOutMarker();
    }

    this.song.songProperties.fadeInDuration ??= 0;
    this.song.songProperties.fadeOutDuration ??= 0;
    this.songBehaviourSubject.next(this.song);
  }

  drawFadeOutMarker() {
    const element: HTMLElement = this.drawLabelForMarker(
      'FOut',
      'rgb(255, 165, 0)',
      '0px 14px'
    );
    this.waveSurferService.addRegion(
      'Fade Out',
      this.song.songProperties.fadeOutDuration < 0
        ? this.song.songProperties.mixTime +
            this.song.songProperties.fadeOutDuration
        : this.song.songProperties.mixTime +
            this.song.songProperties.fadeOutDuration,
      'rgb(255, 165, 0)',
      element
    );
  }

  drawStartMarker() {
    const element: HTMLElement = this.drawLabelForMarker('St', '#00ffcc');

    this.waveSurferService.addRegion(
      'Start',
      this.song.songProperties.startTime,
      '#00ffcc',
      element
    );
  }
  drawMixMarker() {
    const element: HTMLElement = this.drawLabelForMarker('Mx', '#00ffcc');
    this.waveSurferService.addRegion(
      'Mix',
      this.song.songProperties.mixTime,
      '#00ffcc',
      element
    );
  }

  drawFadeInMarker() {
    const element: HTMLElement = this.drawLabelForMarker(
      'FIn',
      'rgb(255, 165, 0)'
    );
    this.waveSurferService.addRegion(
      'Fade In',
      this.song.songProperties.fadeInDuration +
        this.song.songProperties.startTime,
      'rgb(255, 165, 0)',
      element
    );
  }

  drawLabelForMarker(
    id: string,
    color: string,
    padding: string = '0px 10px'
  ): HTMLElement {
    // Apply CSS styles to make it look attractive
    const div = document.createElement('div');
    div.textContent = id; // Ensure `id` has some text to affect the width

    // Apply CSS styles
    div.style.color = 'white';
    div.style.backgroundColor = color; // Ensure `color` is defined
    div.style.padding = padding; // Horizontal padding, no vertical padding specified
    div.style.borderRadius = '4px'; // Rounded corners
    div.style.fontWeight = 'bold'; // Bold text
    div.style.fontSize = '10px'; // Font size
    div.style.textTransform = 'uppercase'; // Uppercase text
    div.style.cursor = 'pointer'; // Cursor style on hover
    div.style.display = 'flex'; // Use flex layout for inner content
    div.style.flexDirection = 'row'; // Default flex direction, can be omitted
    div.style.alignItems = 'center'; // Vertically center the text
    div.style.justifyContent = 'center'; // Horizontally center the text, useful if the div width is adjusted
    div.style.height = '15px'; // Fixed height of the div
    // Add a hover effect
    div.addEventListener('mouseenter', () => {
      div.style.backgroundColor = 'white'; // Change background color on hover
      div.style.color = color;
      div.style.transition = 'background-color 0.3s'; // Add a smooth transition
      div.style.transition = 'color 0.3s'; // Add a smooth transition
    });

    div.addEventListener('mouseleave', () => {
      div.style.backgroundColor = color; // Restore background color on mouse leave
      div.style.color = 'white';
    });

    div.style.whiteSpace = 'nowrap';
    return div;
  }

  onFadeLeftSelected() {
    this.fadeLeftCheckboxSelected = !this.fadeLeftCheckboxSelected;
    if (this.fadeLeftCheckboxSelected) {
      if (this.song.songProperties.fadeInDuration === 0) {
        this.song.songProperties.fadeInDuration = 3;
        this.songBehaviourSubject.next(this.song);
      }
      this.drawFadeInMarker();
    } else {
      this.resetFadeIn();
    }

    this.songBehaviourSubject.next(this.song);
  }

  onFadeRightSelected() {
    this.fadeRightCheckboxSelected = !this.fadeRightCheckboxSelected;
    if (this.fadeRightCheckboxSelected) {
      if (this.song.songProperties.fadeOutDuration === 0) {
        this.song.songProperties.fadeOutDuration = -3;
        this.songBehaviourSubject.next(this.song);
      }

      this.drawFadeOutMarker();
    } else {
      this.resetFadeOut();
    }
    this.songBehaviourSubject.next(this.song);
  }

  onFadeLeftDurationChanged(e) {
    let fadeInDuration = e.target.value as number;
    fadeInDuration = fadeInDuration / 1;
    this.song.songProperties.fadeInDuration = fadeInDuration;
    this.songBehaviourSubject.next(this.song);
    this.waveSurferService.removeRegion('Fade In');
    this.drawFadeInMarker();
  }

  onFadeRightDurationChanged(e) {
    let fadeOutDuration = e.target.value as number;
    fadeOutDuration = fadeOutDuration / 1;
    this.song.songProperties.fadeOutDuration = fadeOutDuration;
    this.songBehaviourSubject.next(this.song);

    this.waveSurferService.removeRegion('Fade Out');
    this.drawFadeOutMarker();
  }

  private setVolume(volume: number) {
    //console.log('volume', volume);
    if (volume >= 0) {
      this.volume = volume;
      this.waveSurferService.setVolume(volume);
    }
  }

  private setPreviewStartTimeColour() {
    if (
      !this.fadeLeftCheckboxSelected &&
      this.song.songProperties.startTime === 0
    ) {
      this.startTimePreviewColor = '#667';
    }

    if (
      this.song.songProperties.startTime !== 0 &&
      !this.fadeLeftCheckboxSelected
    ) {
      this.startTimePreviewColor = 'rgb(0, 255, 204)';
    }
    if (this.fadeLeftCheckboxSelected) {
      this.startTimePreviewColor = 'rgb(255, 165, 0)';
    }
  }

  private setPreviewEndTimeColour() {
    if (
      !this.fadeRightCheckboxSelected &&
      this.song.songProperties.mixTime === this.duration
    ) {
      this.endTimePreviewColor = '#667';
    }

    if (
      this.song.songProperties.mixTime !== this.duration &&
      !this.fadeRightCheckboxSelected
    ) {
      this.endTimePreviewColor = 'rgb(0, 255, 204)';
    }
    if (this.fadeRightCheckboxSelected) {
      this.endTimePreviewColor = 'rgb(255, 165, 0)';
    }
  }

  ngOnDestroy(): void {
    //TODO: Unable to destroy XHR in HowerJs as it's NOT exposed
    //console.log('ngOnDestroy','editor-player');
    this.songBehaviourSubject.unsubscribe();
    this.fadeOutBehaviourSubject.unsubscribe();

    this.waveSurferService.destroy();

    //console.log(this.waveSurferService);

    this.sprite?.unload();
    this.sprite = null;

    this.destroy$.next();
    this.destroy$.complete();
  }
}
