import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';

import { AppRoutingModule } from './app-routing.module';

import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

import { httpInterceptorProviders } from './shared/helpers/http.interceptor';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TreeModule } from 'primeng/tree';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FeatherIconModule } from './core/feather-icon/feather-icon.module';
import {
  MatFormField,
  MatFormFieldModule,
  MatLabel,
} from '@angular/material/form-field';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { VenueCalendarComponent } from './views/pages/venues/venue-calendar/venue-calendar.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import { PublishPlaylistModalComponent } from './views/pages/venues/venue-calendar/publish-playlist-modal/publish-playlist-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ViewSlotModalComponent } from './views/pages/venues/venue-calendar/view-slot-modal/view-slot-modal.component';
import { ContextMenuModule } from 'primeng/contextmenu';
import { VenueCalendarMenuComponent } from './views/pages/venues/venue-calendar/venue-calendar-menu/venue-calendar-menu.component';
import { ProfileModule } from './views/pages/profile/profile.module';
import { DeletePlaylistDialogComponent } from './delete-playlist-dialog/delete-playlist-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SendActivationEmailDialogComponent } from './send-activation-email-dialog/send-activation-email-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingComponent } from './views/pages/loading/loading.component';
import { MatSelectModule } from '@angular/material/select';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { MatIconModule} from "@angular/material/icon";
import { MatMenuModule} from "@angular/material/menu";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    VenueCalendarComponent,
    PublishPlaylistModalComponent,
    ViewSlotModalComponent,
    VenueCalendarMenuComponent,
    DeletePlaylistDialogComponent,
    SendActivationEmailDialogComponent,
    LoadingComponent,
  ],
  providers: [AuthGuard, httpInterceptorProviders],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LayoutModule,
    ProfileModule,
    HttpClientModule,
    TreeModule,
    NgbModule,
    TableModule,
    NgxDatatableModule,
    FullCalendarModule,
    ContextMenuModule,
    FormsModule,
    FeatherIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    FontAwesomeModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManagerId || null,
    })

  ],
  exports: [MatFormFieldModule],
})

export class AppModule {}
