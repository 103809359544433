import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditorPlayerComponent } from '../components/editor-player/editor-player.component';
import { Song } from '../models/song';
import {
  MiniPlayerService,
  SongPlayingStatus,
} from 'src/app/shared/services/mini-player.service';
import { TreeNode } from 'primeng/api';
import { Label } from '../models/label';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { PlaylistService } from 'src/app/shared/services/playlist.service';
import { PlaylistPlayerService } from 'src/app/shared/services/playlist-player.service';
import { faPenToSquare, faPlay } from '@fortawesome/free-solid-svg-icons';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import { SongPeaks } from '../models/song-peaks';

@Component({
  selector: 'app-music-item',
  templateUrl: './music-item.component.html',
  styleUrls: ['./music-item.component.scss'],
})
export class MusicItemComponent implements OnInit, OnDestroy {
  @Input() song: Song;
  @Input() hasListOption: boolean;
  @Input() hasRemoveOption: boolean;
  @Input() selectedNode: TreeNode<any>;
  @Input() isCustomerLibrary: boolean = false;
  @Input() isSongDatabase: boolean = false;
  @Input() opacity: number;
  @Input() isPlaylist: boolean = false;

  @Output() removeSongEvent = new EventEmitter<{
    content: TemplateRef<any>;
    song: Song;
    libraryType: string;
  }>();
  @Output() SongUpdated = new EventEmitter<any>();
  peaksArrived = new Subject<SongPeaks>();
  edit = faPenToSquare;
  isPlaying = false;
  assignedLabels: Label[] = [];
  playingSubscription: Subscription;
  faPlay = faPlay;
  constructor(
    private modalService: NgbModal,
    private miniPlayerService: MiniPlayerService,
    private playlistService: PlaylistService,
    private playlistPlayerService: PlaylistPlayerService,
    private venueLibraryService: VenueLibraryService
  ) {}

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    if (this.selectedNode.parent == undefined) this.hasRemoveOption = false;
    this.assignedLabels = this.song.labels;

    this.playlistService.songAddedToPlaylist$
      .pipe(takeUntil(this.destroy$))
      .subscribe((songId) => {
        //console.log(songId) ;
        if (Number(songId) == this.song.id) {
          this.song.isUsedInPlaylist = true;
        }
      });

    this.playlistService.songDeletedFromPlaylist$
      .pipe(takeUntil(this.destroy$))
      .subscribe((songId) => {
        if (Number(songId) == this.song.id) {
          this.song.isUsedInPlaylist = false;
        }
      });
    this.peaksArrived.subscribe((peaks) => {
      const modalRef = this.modalService.open(EditorPlayerComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.peaks = peaks;
      modalRef.componentInstance.song = this.song;
      modalRef.componentInstance.selectedNode = this.selectedNode;
      if (this.selectedNode)
        modalRef.componentInstance.isCustomerLibrary = this.isCustomerLibrary;
      modalRef.componentInstance.isSongDatabase = this.isSongDatabase;
      modalRef.result.then((result) => {
        //console.log('result', result);
        this.SongUpdated.emit(result);
      });
    });
  }

  openConfirmationModal(content: TemplateRef<any>) {
    this.modalService
      .open(content, {})
      .result.then((result) => {
        const closeResult = 'Modal closed : ' + result;
        //console.log('closeResult', closeResult);
      })
      .catch((res) => {});
  }

  removeSong(content: TemplateRef<any>, song: Song) {
    const libraryType = this.isCustomerLibrary
      ? 'CustomerLibrary'
      : 'SongDatabase';
    this.removeSongEvent.emit({ content, song, libraryType });
  }

  openMiniPlayer(content: TemplateRef<any>, id: number) {
    this.playingSubscription = this.miniPlayerService.playing.subscribe(
      (songPlayingStatus) => {
        if (songPlayingStatus.song.id == this.song.id) {
          this.isPlaying = songPlayingStatus.isPlaying;
        } else if (songPlayingStatus.song.id != this.song.id) {
          this.isPlaying = false;
          this.playingSubscription.unsubscribe();
        }
      }
    );
    this.miniPlayerService.setSong(this.song);
    this.miniPlayerService.playing.next(new SongPlayingStatus(this.song, true));
    this.playlistPlayerService.Stop();
  }
  openEditorPlayer() {
    this.miniPlayerService.stopPlayer();
    this.playlistPlayerService.Stop();
    this.venueLibraryService.getSongPeaks(this.song.id).subscribe((peaks) => {
      this.peaksArrived.next(peaks);
    });
  }

  dragStart(event) {
    //console.log('dragStart', event);
  }

  dragEnd(event) {
    //console.log('dragEnd', event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
