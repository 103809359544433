import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { StorageService } from './storage.service';
import { RegisterVenue } from '../views/shared/models/register-venue';

const AUTH_API = environment.apiUrl + '/api/account/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  login(username: string, password: string): Observable<any> {
    return this.http.post(
      AUTH_API + 'login',
      {
        username,
        password,
      },
      httpOptions
    );
  }
  register(email: string, nickName: string, password: string): Observable<any> {
    return this.http.post(
      AUTH_API + 'registerdj',
      {
        email,
        nickName,
        password,
      },
      httpOptions
    );
  }

  refreshToken() {
    const refreshToken = this.storageService.getRefreshToken();
    return this.http.post(
      AUTH_API + 'refreshtoken',
      { token: refreshToken },
      httpOptions
    );
  }

  logout(): Observable<any> {
    const refreshToken = this.storageService.getRefreshToken();
    return this.http.post(
      AUTH_API + 'logout',
      { refreshToken: refreshToken },
      httpOptions
    );
  }

  forgotPasswordLink(email: string): Observable<any> {
    return this.http.post(
      AUTH_API + 'forgotpasswordlink',
      { email: email },
      httpOptions
    );
  }

  activateAccount(token: string): Observable<any> {
    return this.http.post(
      AUTH_API + 'activateAccount',
      { token: token },
      httpOptions
    );
  }

  activateVenueAccount(token: string, password: string): Observable<any> {
    return this.http.post(
      AUTH_API + 'activateVenueAccount',
      { token: token, password: password },
      httpOptions
    );
  }

  resetPassword(password: string, token: string): Observable<any> {
    return this.http.post(
      AUTH_API + 'resetPassword',
      { token: token, password: password },
      httpOptions
    );
  }

  registerVenue(venue: RegisterVenue): Observable<any> {
    return this.http.post(AUTH_API + 'registervenue', venue, httpOptions);
  }

  sendVenueActivationEmail(venueId: number): Observable<any> {
    const token = this.storageService.getToken();

    return this.http.post(
      AUTH_API + 'sendVenueActivationEmail?venueId=' + venueId,
      httpOptions
    );
  }
}
