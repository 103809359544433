import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Song } from '../../models/song';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-playlist-music-item',
  templateUrl: './playlist-music-item.component.html',
  styleUrls: ['./playlist-music-item.component.scss']
})
export class PlaylistMusicItemComponent {

  constructor() { }

  //   <app-music-item
  //   #musicItem
  //   [song]="node.data"
  //   [hasListOption]="hasSongListOption"
  //   [hasRemoveOption]="hasSongRemoveOption"
  //   [selectedNode]="selectedNode"
  //   (onSongUpdated)="songUpdated($event)"
  //   (onDragStart)="nodeDragStart(node)" (onDragEnd)="nodeDragEnd(node)"
  // ></app-music-item
  // >

  @Input() song: Song;
  @Input() hasListOption = true;
  @Input() hasRemoveOption = false;
  @Input() selectedNode: TreeNode<any>;
  @Output() SongUpdated = new EventEmitter<Song>();
  @Output() DragStart = new EventEmitter<Song>();
  @Output() DragEnd = new EventEmitter<Song>();

  hasSongRemoveOption = false;
  hasSongListOption = true;

  node: any;


  onSongUpdatedEvent(song: Song) {
    this.SongUpdated.emit(song);
  }

  onDragStartEvent(song: Song) {
    this.DragStart.emit(song);
  }

  onDragEndEvent(song: Song) {
    this.DragEnd.emit(song);
  }


}
