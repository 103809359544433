import { Injectable } from "@angular/core";
import { Filter } from "src/app/views/shared/models/filter";

@Injectable({
  providedIn: 'root',
})
export class ServiceHelper {

    constructor() { }
    
    public buildFilterQueryString(filters: Filter[]): {
        filterFields: string;
        filterValues: string;
      } {
        const filterFields = filters.map((filter) => filter.field).join(',');
        const filterValues = filters.map((filter) => filter.value).join(',');
        return { filterFields, filterValues };
      }

}


