import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TeamsService } from 'src/app/shared/services/teams.service';

@Component({
  selector: 'app-team-selector',
  templateUrl: './team-selector.component.html',
  styleUrls: ['./team-selector.component.scss'],
})
export class TeamSelectorComponent implements OnInit, OnDestroy {
  constructor(private teamsService: TeamsService) {}
  teams: any[] = [];
  selectedTeam: any = null;
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.teams = this.teamsService.getLoadedTeams();
    this.selectedTeam = this.teams.find((team) => team.teamType === 'P');
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectTeam(team: any) {
    this.teamsService.setSelectedTeam(team);
  }
}
