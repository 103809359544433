import { Component, Input, OnInit } from '@angular/core';
import { EventApi, EventInput } from '@fullcalendar/angular';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PublishPlaylistModalComponent } from '../publish-playlist-modal/publish-playlist-modal.component';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import { ViewSlotModalComponent } from '../view-slot-modal/view-slot-modal.component';

@Component({
  selector: 'app-venue-calendar-menu',
  templateUrl: './venue-calendar-menu.component.html',
  styleUrls: ['./venue-calendar-menu.component.scss']
})
export class VenueCalendarMenuComponent {

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private venueLibraryService: VenueLibraryService
    ) { }

  @Input() Slot: EventInput;
  @Input() Playlist: any;
  @Input() VenueId: number;
  @Input() TeamId: number;



  viewSlot() {
    this.openViewSlotModal();
  }

  deleteSlot() {
    var result = { action: 'deleteSlot', data: this.Slot.id}
    this.activeModal.close(result);
  }

  deleteSchedule() {
    var result = { action: 'deleteSchedule', data: this.Slot._def.extendedProps.scheduleId}
    this.activeModal.close(result);
  }

  splitSlot() {
    this.openPublishPlaylistModal();

  }

  closeModal() {
    this.activeModal.close('Closed');
  }

  openPublishPlaylistModal() {

    //console.log(this.Playlist);

    const modalRef = this.modalService.open(PublishPlaylistModalComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
    //console.log(event);

    modalRef.componentInstance.venueId = this.VenueId;
    modalRef.componentInstance.teamId = this.TeamId;
    modalRef.componentInstance.event = this.Playlist;
    modalRef.componentInstance.scheduleId = this.Slot._def.extendedProps.scheduleId;
    modalRef.componentInstance.scheduleDate = this.Slot.start;


    modalRef.result.then((result) => {
      if(result == 'Closed') {
       return;
      }
      const resultData = { action: 'splitSlot', data: result }
      this.activeModal.close(resultData);
    });
  }

  openViewSlotModal() {
    const modalRef = this.modalService.open(ViewSlotModalComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.slotId = this.Slot.id;

    modalRef.result.then((result) => {
      return;
    });

  }

}
