import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from '@narik/custom-validators';
import { ControlsOf } from 'src/app/shared/helpers/form-helper';
import { RegisterVenue } from 'src/app/views/shared/models/register-venue';
import { Venue } from 'src/app/views/shared/models/venues';
import { GoogleTagManagerImplService } from 'src/app/shared/services/google-tag-manager.service';

@Component({
  selector: 'app-edit-venue',
  templateUrl: './edit-venue.component.html',
  styleUrls: ['./edit-venue.component.scss'],
})
export class EditVenueComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal,
    private gaService: GoogleTagManagerImplService ) {}

  @Input() venue: Venue | null = null;
  title: string = '';
  venueForm: FormGroup;
  ngOnInit(): void {
    this.gaService.trackModalOpen('Edit Venue', this.venue?.id);
    console.log(this.venue);
    this.venueForm = new FormGroup({
      venueName: new FormControl(this.venue ? this.venue.name : '', {
        nonNullable: true,
        validators: [Validators.required],
      }),

      email: new FormControl(this.venue ? this.venue.email : '', {
        nonNullable: true,
        validators: [Validators.required, CustomValidators.email],
      }),
      phoneNumber: new FormControl(this.venue ? this.venue.phoneNumber : '', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      contactName: new FormControl(this.venue ? this.venue.contactName : '', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      firstName: new FormControl(this.venue ? this.venue.contactName : '', {}),
    });
    if (this.venue != null) {
      this.title = 'Edit Customer';
      this.venueForm.patchValue(this.venue);
    } else {
      this.title = 'Add Customer';
      this.venue = {} as Venue;
    }
  }
  saveVenue() {
    const formValues = this.venueForm.value as RegisterVenue;
    if (this.venue != null) {
      if (formValues.contactName.split(' ').length > 1) {
        formValues.firstName = formValues.contactName.split(' ')[0];
        formValues.lastName = formValues.contactName.split(' ')[1];
      } else if (formValues.contactName.split(' ').length == 1) {
        formValues.lastName = '';
        formValues.firstName = formValues.contactName.split(' ')[0];
      }
      formValues.id = this.venue.id ?? null;
      console.log('33333');

      console.log(formValues);
    }
    this.activeModal.close(formValues);
  }
  closeModal() {
    this.activeModal.close('Closed');
  }

  isRequired(fieldName: string): boolean {
    return (
      this.venueForm.controls[fieldName].errors?.required &&
      this.venueForm.get(fieldName)?.touched
    );
  }

  get isEmailInvalid(): boolean {
    return (
      this.venueForm.controls.email.errors?.email &&
      this.venueForm.get('email')?.touched
    );
  }
}
