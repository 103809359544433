import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditorPlayerComponent } from '../components/editor-player/editor-player.component';
import { Song } from '../models/song';
import { MiniPlayerService } from 'src/app/shared/services/mini-player.service';
import { TreeNode } from 'primeng/api';
import { PlaylistMixerComponent } from '../components/playlist-mixer/playlist-mixer.component';
import { PlaylistPlayerService } from 'src/app/shared/services/playlist-player.service';
import { Subject, takeUntil } from 'rxjs';
import { faShuffle, faPlay } from '@fortawesome/free-solid-svg-icons';
import { SongPeaks } from '../models/song-peaks';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';

@Component({
  selector: 'app-playlist-item',
  templateUrl: './playlist-item.component.html',
  styleUrls: ['./playlist-item.component.scss'],
})
export class PlaylistItemComponent {
  @Input() song: Song;
  @Input() nextSong: Song;
  @Input() playlistId: number;
  @Input() songSequence: number;
  @Input() nextSongSequence: number;
  @Input() hasListOption: boolean;
  @Input() hasRemoveOption: boolean;
  @Input() hasPlayOption: boolean;
  @Input() isLastItem: boolean;
  @Input() selectedNode: TreeNode<any>;
  @Input() index: number;
  @Output() removeSongEvent = new EventEmitter<Song>();
  @Output() songUpdated = new EventEmitter<any>();
  currentIndex: number = -1;

  private destroy$ = new Subject<void>();
  songAId: number | null;
  songBId: number | null;
  peaksArrived = new Subject<SongPeaks>();

  mixingIcon = faShuffle;
  faPlay = faPlay;

  constructor(
    private modalService: NgbModal,
    private miniPlayerService: MiniPlayerService,
    private playlistPlayerService: PlaylistPlayerService,
    private venueLibraryService: VenueLibraryService
  ) {
    const playlistPlayerIndex = this.playlistPlayerService
      .getCurrentIndex$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        this.currentIndex = index;
      });

    const playlistPlayerSongA = this.playlistPlayerService
      .getSongPlayingA$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        this.songAId = id;
      });

    const playlistPlayerSongB = this.playlistPlayerService
      .getSongPlayingB$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        this.songBId = id;
      });
    this.peaksArrived.subscribe((peaks) => {
      const modalRef = this.modalService.open(EditorPlayerComponent, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.song = this.song;
      modalRef.componentInstance.selectedNode = this.selectedNode;
      modalRef.componentInstance.peaks = peaks;

      modalRef.result.then((result) => {
        //console.log('result', result);
        this.songUpdated.emit(result);
      });
    });
  }

  removeSong(song: Song) {
    this.removeSongEvent.emit(song);
  }

  openMiniPlayer(content: TemplateRef<any>, id: number) {
    this.miniPlayerService.setSong(this.song);
  }

  openEditorPlayer() {
    this.miniPlayerService.stopPlayer();
    this.venueLibraryService.getSongPeaks(this.song.id).subscribe((peaks) => {
      this.peaksArrived.next(peaks);
    });
  }

  openMixer() {
    this.miniPlayerService.stopPlayer();
    const modalRef = this.modalService.open(PlaylistMixerComponent, {
      size: 'xl',
      windowClass: 'custom-modal',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.song = this.song;
    modalRef.componentInstance.nextSong = this.nextSong;
    modalRef.componentInstance.playlistId = this.playlistId;
    modalRef.componentInstance.songSequence = this.songSequence;
    modalRef.componentInstance.nextSongSequence = this.nextSongSequence;
    modalRef.componentInstance.selectedNode = this.selectedNode;
    modalRef.componentInstance.isMixer = true;

    modalRef.result.then((result) => {
      this.songUpdated.emit(result);
    });
    this.playlistPlayerService.Stop();
  }

  playSong(index: number) {
    this.playlistPlayerService.playSong(index);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
