import { RouterModule, Routes } from "@angular/router";
import { CollaborationsComponent } from "./collaborations/collaborations.component";
import { ProfileInfoComponent } from "./profile-info/profile-info.component";
import { NgModule } from "@angular/core";
import { ProfileComponent } from "./profile.component";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [

  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: '',
        component: ProfileInfoComponent,
        data: {dj: null}
      },
      {
        path: 'collaborations',
        component: CollaborationsComponent,
      },
      // {
      //   path: 'subscriptions',
      //   component: SubscriptionsComponent,
      // },
      // {
      //   path: 'settings',
      //   component: SettingsComponent,
      // },
    ]
  }


];



@NgModule({
  declarations: [
    ProfileComponent,
    ProfileInfoComponent,
    CollaborationsComponent,
    // SubscriptionsComponent,
    // SettingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule
  ],
})
export class ProfileModule {}
