import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Song } from '../../models/song';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-player-buttons',
  templateUrl: './player-buttons.component.html',
  styleUrls: ['./player-buttons.component.scss'],
})
export class PlayerButtonsComponent implements OnInit {
  @Input() song: Song;
  _startTimePreviewColor: string;
  get startTimePreviewColor(): string {
    return this._startTimePreviewColor;
  }
  @Input() set startTimePreviewColor(value: string) {
    this._startTimePreviewColor = value;
  }
  @Input() endTimePreviewColor: string;
  @Input() isPlaying1: boolean;
  @Input() isPlaying2: boolean;
  @Input() fadeLeftCheckboxSelected: boolean;
  @Input() fadeRightCheckboxSelected: boolean;
  @Input() fadeLeftDuration: number;
  @Input() fadeRightDuration: number;
  @Input() visibleButtons: string[];
  @Input() parent: string;
  @Input() volumeLeft: number;
  @Input() volumeRight: number;

  @Output() startCueEvent = new EventEmitter<void>();
  @Output() fadeLeftSelectedEvent = new EventEmitter<void>();
  @Output() fadeLeftDurationChangedEvent = new EventEmitter<any>(); // If you want to emit a value, use EventEmitter<any>
  @Output() playFadeLeftEvent = new EventEmitter<void>();
  @Output() goToStartEvent = new EventEmitter<void>();
  @Output() backwardSeekEvent = new EventEmitter<number>();
  @Output() playEvent = new EventEmitter<void>();
  @Output() pauseEvent = new EventEmitter<void>();
  @Output() forwardSeekEvent = new EventEmitter<number>();
  @Output() goToEndEvent = new EventEmitter<void>();
  @Output() playFadeRightEvent = new EventEmitter<void>();
  @Output() fadeRightSelectedEvent = new EventEmitter<void>();
  @Output() fadeRightDurationChangedEvent = new EventEmitter<any>(); // If you want to emit a value, use EventEmitter<any>
  @Output() mixCueEvent = new EventEmitter<void>();
  @Output() volumeLeftEvent = new EventEmitter<any>();
  @Output() volumeRightEvent = new EventEmitter<any>();

  MathAbs = Math.abs;
  PlayFadeLeftButtonTitle: string;
  PlayFadeRightButtonTitle: string;
  FadeLeftDurationTitle: string;
  FadeRightDurationTitle: string;
  FadeLeftCheckboxTitle: string;
  FadeRightCheckboxTitle: string;
  LeftCueTitle: string;
  RightCueTitle: string;

  constructor() {}

  ngOnInit(): void {
    this.PlayFadeLeftButtonTitle =
      this.parent === 'player-mixer'
        ? 'Mix Time Preview'
        : 'Start Time Preview';
    this.PlayFadeRightButtonTitle =
      this.parent === 'player-mixer'
        ? 'Start Time Preview'
        : 'Mix Time Preview';
    this.FadeLeftDurationTitle =
      this.parent === 'player-mixer' ? 'Fade Out Duration' : 'Fade In Duration';
    this.FadeRightDurationTitle =
      this.parent === 'player-mixer' ? 'Fade In Duration' : 'Fade Out Duration';
    this.FadeLeftCheckboxTitle =
      this.parent === 'player-mixer' ? 'Fade Out Checkbox' : 'Fade In CheckBox';
    this.FadeRightCheckboxTitle =
      this.parent === 'player-mixer'
        ? 'Fade In CheckBox '
        : 'Fade Out Checkbox';
    this.LeftCueTitle =
      this.parent === 'player-mixer' ? 'Mix Cue' : 'Start Cue';
    this.RightCueTitle =
      this.parent === 'player-mixer' ? 'Start Cue' : 'Mix Cue';
  }

  isHidden(button: string) {
    if (this.visibleButtons === null || this.visibleButtons === undefined) {
      return false;
    }
    return !this.visibleButtons.includes(button);
  }

  onStartCue() {
    this.startCueEvent.emit();
  }
  onFadeLeftSelected() {
    this.fadeLeftSelectedEvent.emit();
  }
  onFadeLeftDurationChanged(e) {
    this.fadeLeftDurationChangedEvent.emit(e);
  }
  onPlayFadeLeft() {
    this.playFadeLeftEvent.emit();
  }
  onGoToStart() {
    this.goToStartEvent.emit();
  }
  backwardSeek(time) {
    this.backwardSeekEvent.emit(time);
  }
  onPlay() {
    this.playEvent.emit();
  }
  onPause() {
    this.pauseEvent.emit();
  }
  forwardSeek(time) {
    this.forwardSeekEvent.emit(time);
  }
  onGoToEnd() {
    this.goToEndEvent.emit();
  }
  onPlayFadeRight() {
    this.playFadeRightEvent.emit();
  }
  onFadeRightSelected() {
    this.fadeRightSelectedEvent.emit();
  }
  onFadeRightDurationChanged(e) {
    this.fadeRightDurationChangedEvent.emit(e);
  }
  onMixCue() {
    this.mixCueEvent.emit();
  }
  onVolumeLeft(e) {
    this.volumeLeftEvent.emit(e);
  }
  onVolumeRight(e) {
    this.volumeRightEvent.emit(e);
  }
}
