import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private languageSource = new BehaviorSubject<string>('us');
  currentLanguage = this.languageSource.asObservable();

  constructor() {}

  changeLanguage(language: string): void {
    this.languageSource.next(language);
  }

}

