import { Component, Input, OnInit } from '@angular/core';
import { Song } from '../../models/song';
import { WaveSurferService } from 'src/app/shared/services/wave-surfer.service';

@Component({
  selector: 'app-player-times',
  templateUrl: './player-times.component.html',
  styleUrls: ['./player-times.component.scss']
})
export class PlayerTimesComponent {

  constructor() { }

  @Input() waveSurferService: WaveSurferService;
  @Input() fadeLeftCheckboxSelected: boolean;
  @Input() fadeRightCheckboxSelected: boolean;
  @Input() startTime: number;
  @Input() mixTime: number;
  @Input() fadeInDuration: number;
  @Input() fadeOutDuration: number;

  MathAbs = Math.abs;

  resetStartTime(): void {}
  onFadeLeftSelected(): void {}
  onFadeRightSelected(): void {}
  resetMixTime(): void {}

  onButtonClick(action: Function, conditional?: boolean): void {
    if (!conditional || this.waveSurferService.isPlaying()) {
      action();
    }
  }

  // Cue button click methods
  onCueClick(time: number, colorActive: string, colorInactive: string) {
    return time > 0 ? { color: colorActive, 'border-color': colorActive } : { color: colorInactive, 'border-color': colorInactive };
  }

  // Seek methods
  seek(direction: 'forward' | 'backward', time: number): void {
    if (direction === 'forward') {
      this.waveSurferService.setTime(this.waveSurferService.getCurrentTime() + time);
    } else if (direction === 'backward') {
      this.waveSurferService.setTime(this.waveSurferService.getCurrentTime() - time);
    }
  }


}
