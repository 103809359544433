import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil, Subject, filter } from 'rxjs';
import { AuthService } from './shared/auth.service';
import { StorageService } from './shared/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { LabelsService } from './shared/services/labels.service';
import { MusicLibraryService } from './shared/services/music-library.service';
import { TeamsService } from './shared/services/teams.service';
import { Team } from './views/shared/models/team';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { GoogleTagManagerImplService } from './shared/services/google-tag-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Groovit DJ Architect';

  supportedLanguages = [
    { code: 'us', name: 'English' },
    { code: 'gr', name: 'Greek' },
    // ... other languages
  ];
  selectedLanguage = this.supportedLanguages[0].code;

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private translate: TranslateService,
    private labelsService: LabelsService,
    private musicLibraryService: MusicLibraryService,
    private teamsService: TeamsService,
    private router: Router,
    private modalService: NgbModal,
    private readonly googleAnalyticsService: GoogleTagManagerImplService
  ) {
    translate.setDefaultLang(this.selectedLanguage);
    translate.use(this.selectedLanguage);
    this.router.events
      .pipe(
        filter(
          (event: RouterEvent): event is NavigationEnd =>
            event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        sessionStorage.setItem('lastUrl', event.url);
      });
  }

  private destroy$ = new Subject<void>();

  authCheck: NodeJS.Timeout | undefined;

  ngOnInit(): void {
    if (environment.production) {
      this.googleAnalyticsService.initialize();
    }

    window.addEventListener('keydown', this.handleKeyDown.bind(this));

    this.authCheck = setInterval(() => {
      this.checkAuth();
    }, 5000);

    this.teamsService
      .getSelectedTeamUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((team) => {
        if (team) {
          this.teamsService.selectedTeam = team;
          this.getLabels(team);
        }
      });

    const tokenExpiresIn = this.storageService.getTokenExpiry()
      ? this.storageService.getTokenExpiry()
      : 300;
    setInterval(() => {
      if (this.storageService.isLoggedIn()) {
        this.refreshToken();
      }
    }, tokenExpiresIn * 100 - 100);
  }

  private getLabels(team: Team | null) {
    this.musicLibraryService
      .getLabels(team?.id!, [])
      .pipe(takeUntil(this.destroy$))
      .subscribe((labels) => {
        this.labelsService.labels = labels.labels;
      });
  }

  refreshToken() {
    this.authService
      .refreshToken()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: any) => {
          this.storageService.setUser(data);
        },
        (error) => {
          this.storageService.clean();
        }
      );
  }

  private checkAuth() {
    if (!this.router.url.includes('auth')) {
      if (!localStorage.getItem('isLoggedin')) {
        this.modalService.dismissAll();
        this.router.navigate(['/auth/login']);
      }
    }
  }
  private handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'F5' || event.keyCode === 116) {
      event.preventDefault();
      this.router.navigate(['/']);
    }
  }
  ngOnDestroy(): void {
    clearInterval(this.authCheck!);
    this.destroy$.next();
    this.destroy$.complete();
    window.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }
}
