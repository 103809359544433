import { VenueLibraryService } from './../../../../../shared/services/venue-library.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-view-slot-modal',
  templateUrl: './view-slot-modal.component.html',
  styleUrls: ['./view-slot-modal.component.scss'],
})
export class ViewSlotModalComponent implements OnInit {
  private destroy$ = new Subject<void>();

  songs: any[];

  constructor(
    public activeModal: NgbActiveModal,
    private venueLibraryService: VenueLibraryService
    ) {}
  @Input() slotId: number;

  ngOnInit(): void {
    this.venueLibraryService.GetSongsInSlot(this.slotId)
    .pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.songs = res
    }
    );
  }

  closeModal() {
    this.activeModal.close('Closed');
  }
}
