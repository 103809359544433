import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DJService } from 'src/app/shared/services/dj.service';
import { Dj } from '../../shared/models/dj';
import { Subject, take, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  dj : Dj = {
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    timeZone: '',
    currency: '',
    nickName: '',
    phoneNumber: '',
    base64ProfileImage: '',
  } as Dj;

  isEditMode: boolean = false;
  private destroy$ = new Subject<void>();


  constructor(private djService: DJService) { }  // Inject DJService

  ngOnInit(): void {
    this.loadDJ();
  }

  loadDJ() {
    // Use the DJService to fetch DJ data and set it in the component
    this.djService.getDj()
    .pipe(take(1),takeUntil(this.destroy$))
    .subscribe((dj) => {
      this.dj = dj;
    });
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {

      this.djService.UpdateProfileImage(file)
      .pipe(take(1),takeUntil(this.destroy$))
      .subscribe((image) => {
        this.dj.base64ProfileImage = image;
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
