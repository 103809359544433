import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Label } from 'src/app/views/shared/models/label';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {
  private _labelSource = new BehaviorSubject<Label[]>([]);
  private _labels: Label[];

  constructor() {}

  get labels(): Label[] {
    return this._labels;
  }

  set labels(labels: Label[]) {
    this._labels = labels;
    this._labelSource.next(labels);
  }

  get labelsSub(): Observable<Label[]> {
    return this._labelSource.asObservable();
  }
}
