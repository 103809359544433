import { VenuesService } from 'src/app/shared/services/venues.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TreeDragDropService, TreeNode } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Tree } from 'primeng/tree';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { PlaylistService } from 'src/app/shared/services/playlist.service';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import { FolderHierarchy } from 'src/app/views/shared/models/folder-hierarchy';
import { AddEditSongInPlayListModel } from 'src/app/views/shared/models/add-edit-playlist-song';
import { Options } from 'sortablejs';
import { Song } from 'src/app/views/shared/models/song';
import { PlaylistSong } from 'src/app/views/shared/models/playlist-song';
import { PlaylistPlayerService } from 'src/app/shared/services/playlist-player.service';
import { Router } from '@angular/router';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { GoogleTagManagerImplService } from 'src/app/shared/services/google-tag-manager.service';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss'],
  providers: [TreeDragDropService],
})
export class PlaylistComponent implements OnInit, OnDestroy {
  songAdded: boolean = false;
  isPlaying: boolean = false;
  isPlayingA: boolean = false;
  isPlayingB: boolean = false;
  constructor(
    private venueLibraryService: VenueLibraryService,
    private venuesService: VenuesService,
    private teamsService: TeamsService,
    private playlistService: PlaylistService,
    private playlistPlayerService: PlaylistPlayerService,
    private router: Router,
    private gaService: GoogleTagManagerImplService
  ) {}

  private destroy$ = new Subject<void>();

  @ViewChild('musicItemsTree') musicItemsTree: Tree;
  @ViewChild('treeContainer') treeContainer: ElementRef;
  @ViewChild('op') overlayPanel: OverlayPanel;
  @ViewChild('folderTree') tree: Tree;

  @Input() playlistId: number;
  @Input() playlistName: string;
  @Input() customerName: string;

  @Input() venueId: number;

  songsLoading = false;
  folderItemNodes: TreeNode[] = [];
  hasSongListOption = false;
  hasSongRemoveOption = false;
  selectedNode = {};
  loaded = false;
  folderItemsCount = 0;
  playLists: any[] = [];
  oldplayLists: any[] = [];
  playLists$: Observable<any[]>;
  playListsSubject = new BehaviorSubject<any[]>(this.playLists);
  sortableOptions: Options = { group: 'test' };
  duration: number;
  faPlay = faPlay;
  faStop = faStop;
  ngOnInit(): void {
    this.gaService.trackScreenOpen("playlist", this.playlistId);
    console.log(this.playlistId);
    console.log(this.playlistName);
    const team = this.teamsService.selectedTeam;
    const venue = this.playlistService.setCurrentVenue(this.venueId);
    this.playlistService.currentCustomer.next(this.customerName);
    this.sortableOptions.onSort = (event: any) => {
      if (this.songAdded) {
        this.songAdded = false;
        return;
      }

      const oldIndex = event.oldIndex;
      const newIndex = event.newIndex;

      const oldItem = this.oldplayLists[oldIndex];

      this.venueLibraryService
        .addEditSongInPlayList({
          playlistId: this.playlistId,
          songId: oldItem.data.id,
          sequence: newIndex + 1,
          startTime: oldItem.data.songProperties.startTime,
          mixTime: oldItem.data.songProperties.mixTime,
          fadeInDuration: oldItem.data.songProperties.fadeInDuration,
          fadeOutDuration: oldItem.data.songProperties.fadeOutDuration,
          volume: oldItem.data.songProperties.volume,
        } as AddEditSongInPlayListModel)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.getSongsInPlaylist();
        });

      const items = this.playLists.filter((item) => item !== undefined);
      this.playListsSubject.next(items);
    };

    this.sortableOptions.onAdd = (event: any) => {
      this.songAdded = true;
      const selectedItem = this.playlistService.getSelectedItem();
      if (selectedItem) {
        event.item.remove();
        this.venueLibraryService
          .addEditSongInPlayList({
            playlistId: this.playlistId,
            songId: selectedItem.data.id,
            sequence: event.newIndex + 1,
            startTime: selectedItem.data.songProperties.startTime ?? 0,
            mixTime:
              selectedItem.data.songProperties.mixTime ??
              selectedItem.data.duration,
            fadeInDuration: selectedItem.data.songProperties.fadeInDuration,
            fadeOutDuration: selectedItem.data.songProperties.fadeOutDuration,
            volume: 0.85, // The default volume is always 0.85 (85%) when adding a new song to the playlist.  This will be adjusted and saved when editing the playlist.
          } as AddEditSongInPlayListModel)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.oldplayLists.push(selectedItem);
            this.playlistService.setSongAddedToPlaylist$(selectedItem.data.id);
            this.getSongsInPlaylist();
          });
      }

      //this.playListsSubject.next(playLists);
    };

    this.sortableOptions.onUpdate = (event: any) => {
      // this.playLists =  {...this.playLists};
    };

    this.getSongsInPlaylist();

    if (this.playlistId !== undefined) {
      this.venueLibraryService
        .getSongsInPlaylist(this.playlistId, 1, 10)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {
          const songData = data.items.map((item) => {
            return {
              label: 'musicItem',
              type: 'musicItem',
              data: {
                id: item.id,
                artist: item.artist,
                title: item.title,
                duration: item.duration,
                normalized: item.normalized,
                songProperties: item.songProperties,
                labels: item.labels.map((label) => label.colour),
              } as Song,
            } as TreeNode;
          });
          const folderHeirchy = {
            label: 'root',
            type: 'root',
            expandedIcon: 'pi pi-folder-open',
            collapsedIcon: 'pi pi-folder',
            children: songData,
          } as TreeNode;

          folderHeirchy.expanded = true;
          this.folderItemNodes = songData;
        });
    }

    const playlistPlayerIndex = this.playlistPlayerService
      .getCurrentIndex$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        //console.log("PlaylistPlayerService.currentIndex=", index);
      });

    // If both are True: this.playlistPlayerService.getSongPlayingA$ and this.playlistPlayerService.getSongPlayingB$. set this.isPlaying to true, else false.
    this.playlistPlayerService
      .getSongPlayingA$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((songPlayingA) => {
        this.isPlayingA =
          songPlayingA !== null && songPlayingA > 0 ? true : false;
        this.isPlaying = this.isPlayingA || this.isPlayingB;
      });
    this.playlistPlayerService
      .getSongPlayingB$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((songPlayingB) => {
        this.isPlayingB =
          songPlayingB !== null && songPlayingB > 0 ? true : false;
        this.isPlaying = this.isPlayingA || this.isPlayingB;
      });
  }

  private mapNodes(node: FolderHierarchy[]): TreeNode[] {
    return node.map((item) => {
      return {
        label: item.label,
        data: item.id,
        songsCount: item.songsCount,
        expandedIcon: 'pi pi-folder-open',
        collapsedIcon: 'pi pi-folder',
        children: this.mapNodes(item.children),
      };
    });
  }
  dropNode(event: any) {
    //console.log(event);
  }
  songUpdated(event: any) {
    this.playlistPlayerService.setPlaylistSongs(
      this.playLists.map((item) => item.data)
    );
    this.duration = this.playLists.reduce((acc, item) => {
      return (
        acc +
        (item.data.songProperties.mixTime - item.data.songProperties.startTime)
      );
    }, 0);
  }
  pageSongs(event: any) {
    //console.log(event);
  }

  nodeDropped(event) {
    //console.log(event);
  }

  drop(event) {
    //console.log("drop",event);
  }

  onLabelListDrop(event) {
    //console.log(event);
  }

  nodeDragStart(event) {
    //console.log(event);
  }

  nodeDragStop(event) {
    let droppedItem = this.playLists[event.newIndex];
    setTimeout(() => {
      const items = this.playLists.filter((item) => item !== undefined);
      this.playListsSubject.next(items);
    }, 100);
  }

  private getSongsInPlaylist() {
    if (this.playlistId === undefined) {
      return;
    }
    this.venueLibraryService
      .getSongsInPlaylist(this.playlistId, 1, 10)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        const playlistData = data.items as PlaylistSong[];
        this.playLists = playlistData.map((item) => {
          return {
            label: 'musicItem',
            type: 'musicItem',
            data: {
              id: item.id,
              artist: item.artist,
              title: item.title,
              duration: item.duration,
              normalized: item.normalized,
              songProperties: item.songProperties,
              labels: item.labels,
            } as Song,
            playlistId: item.playlistId,
            sequence: item.sequence,
          } as TreeNode;
        });
        // Sum of all the songs in this.playLists in this.duration
        this.duration = this.playLists.reduce((acc, item) => {
          return (
            acc +
            (item.data.songProperties.mixTime -
              item.data.songProperties.startTime)
          );
        }, 0);

        this.playlistPlayerService.setPlaylistSongs(
          this.playLists.map((item) => item.data)
        );

        this.oldplayLists = [...this.playLists];
        this.playListsSubject.next(this.playLists);
      });
  }

  onItemDragged(event) {
    //console.log(event);
  }

  removeSongEvent(event) {
    this.venueLibraryService
      .deleteSongInPlaylist(this.playlistId, event.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.playlistService.setSongDeletedFromPlaylist$(event.id);
        this.getSongsInPlaylist();
      });
  }

  playPausePlaylist() {
    if (!this.isPlaying) {
      this.playlistPlayerService.playSong(0);
    } else {
      this.playlistPlayerService.Stop();
    }
  }

  ngOnDestroy(): void {
    this.playListsSubject.unsubscribe();
    this.playlistService.setCurrentVenue(0); //Unset the Current Venue when navigating away from the Playlist Page.
    this.treeContainer.nativeElement.remove();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
