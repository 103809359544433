import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Team } from 'src/app/views/shared/models/team';
import { Teams } from 'src/app/views/shared/models/teams';
import { environment } from 'src/environments/environment';

const Teams_API = environment.apiUrl + '/api/Teams';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  teams: Team[];
  selectedTeam: Team;
  private selectedTeamSub = new BehaviorSubject<Team | null>(null);

  constructor(private http: HttpClient) {}

  getTeams() {
    return this.http.get<Teams>(`${Teams_API}/GetTeams`, { responseType: 'json' });
  }

  getLoadedTeams() {
    return this.teams;
  }

  setLoadedTeams(teams: Team[]) {
    this.teams = teams;
  }

  getSelectedTeam() {
    return this.selectedTeam;
  }

  getSelectedTeamUpdates(): Observable<Team | null> {
    return this.selectedTeamSub.asObservable();
  }



  setSelectedTeam(team: Team) {
    this.selectedTeam = team;
    this.selectedTeamSub.next(team);

  }
}
