import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  catchError,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  throwError,
} from 'rxjs';
import { StorageService } from '../storage.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor, OnDestroy {
  isRefreshing: boolean = false;
  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private router: Router
  ) {}

  private destroy$ = new Subject<void>();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req.url.includes('/api/account/') &&
    !req.url.includes('/api/account/logout') &&
    !req.url.includes('/api/account/registervenue') &&
    !req.url.includes('/api/account/sendVenueActivationEmail')
      ? req
      : (req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.storageService.getToken()}`,
          },
        }));

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('/api/account/logout') &&
          !req.url.includes('/api/account/login') &&
          !req.url.includes('/api/account/refreshtoken') &&
          !req.url.includes('/api/account/registervenue') &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }
        return throwError(() => error);
      })
    );
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (this.storageService.isLoggedIn()) {
        return this.authService.refreshToken().pipe(
          switchMap((data: any) => {
            this.storageService.setUser(data);
            this.isRefreshing = false;

            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${this.storageService.getToken()}`,
              },
            });

            return next.handle(request);
          }),
          catchError((error) => {
            this.isRefreshing = false;

            if (error.status == '401' || error.status == '403') {
              this.authService
                .logout()
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                  localStorage.removeItem('isLoggedin');
                  this.router.navigate(['/auth/login']);
                });
            }

            return throwError(() => error);
          })
        );
      } else {
        this.isRefreshing = false;
        localStorage.removeItem('isLoggedin');
        this.router.navigate(['/auth/login']);
      }
    }

    return next.handle(request);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];
