import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Subject, debounceTime, of, takeUntil } from 'rxjs';
import { MusicLibraryService } from 'src/app/shared/services/music-library.service';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { Label } from '../../shared/models/label';
import { Team } from '../../shared/models/team';
import { EditLabelComponent } from './edit-label/edit-label.component';
import { Filter } from '../../shared/models/filter';
import { LabelsService } from 'src/app/shared/services/labels.service';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss'],
})
export class LabelsComponent implements OnInit {
  selectedTeam: Team | null;
  selectedLabel: Label;
  private destroy$ = new Subject<void>();
  labels: Label[];
  columns: any[] = [];
  columnMode = ColumnMode.force;
  loading = false;
  filters: Filter[] = [];
  sortedBy: string = '';
  sortedDirection: string = '';

  constructor(
    private musicLibraryService: MusicLibraryService,
    private teamService: TeamsService,
    private labelsService: LabelsService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.columns = [
      { prop: 'name', title: 'Name', allowFilter: true, filterProperty: 'TagName' },
      { prop: 'colour', title: 'Colour' },
      //{ prop: 'sequence', title: 'Sequence' },
    ];

    this.teamService
      .getSelectedTeamUpdates()
      .pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.selectedTeam = data;
        this.getLabels();
      });
  }

  onFilter(columnName: string, event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;

    var filterColumn = this.columns.find((x) => x.prop == columnName).filterProperty;

    const filterEntry = this.filters.find((x) => x.field == filterColumn);
    if (filterEntry) {
      filterEntry.field = filterColumn;
      filterEntry.value = inputValue;
    } else {
      this.filters.push({ field: filterColumn, value: inputValue });
    }

    this.getLabels();
  }

  getFilterValue(columnName: string) {
    var filterColumn = this.columns.find((x) => x.prop == columnName).filterProperty;

    const filterEntry = this.filters.find((x) => x.field == filterColumn);
    if (filterEntry) {
      return filterEntry.value;
    } else {
      return '';
    }
  }

  inputChanged(value: string) {
    return of(value);
  }
  editLabel(label: Label) {
    this.selectedLabel = label;
    this.openModal(label);
  }
  //get labels
  getLabels() {
    this.loading = true;
    if (!this.selectedTeam) {
      return;
    }
    this.musicLibraryService
      .getLabels(this.selectedTeam?.id!, this.filters)
      .pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.labels = data.labels;
        this.labelsService.labels = data.labels;
        this.loading = false;
      });
  }

  //create label
  createLabel() {
    this.musicLibraryService
      .addLabel(this.selectedLabel)
      .pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.getLabels();
      });
  }

  //update label
  updateLabel() {
    this.loading = true;
    this.musicLibraryService
      .updateLabel(this.selectedLabel)
      .pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.getLabels();
      });
  }

  onMoveUp(label: Label) {
    label.sequence = label.sequence - 1;
    this.selectedLabel = label;
    this.updateLabel();
  }

  onMoveDown(label: Label) {
    label.sequence = label.sequence + 1;
    this.selectedLabel = label;
    this.updateLabel();
  }

  openModal(label: Label | null) {
    const modalRef = this.modalService.open(EditLabelComponent, { size: 'lg',backdrop: 'static', keyboard: false});
    modalRef.componentInstance.label = label;
    modalRef.result.then((result: Label | string) => {
      if (typeof result !== 'string') {
        this.selectedLabel = result;
        if (this.selectedLabel.id) {
          this.updateLabel();
        } else {
          this.selectedLabel.sequence = 1;
          this.createLabel();
        }
      }
    });
  }

  sortBy(columnName: string, sortFn: Function) {
    this.sortedBy = columnName;
    this.sortedDirection = this.sortedDirection == 'desc' ? 'asc' : 'desc';
    sortFn();
  }
}
