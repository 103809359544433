import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabelsService } from 'src/app/shared/services/labels.service';
import { Label } from '../../models/label';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Output() colorChanged = new EventEmitter<string>();
  @Input() color: string;
  selectedColor: string;
  usedLabels: Label[];

  constructor(private labelsService: LabelsService) {}

  colors: string[] = [

  ];

  ngOnInit(): void {
    this.labelsService.labels.map((label) => this.colors.push(label.colour));

    // generate random color and exclude used colors





    this.usedLabels = this.labelsService.labels.map((label) => {
      return label;
    });



    this.colors = this.colors.filter((color) => {
      return  !this.usedLabels.some((label) => label.colour === color);
    });

    if (this.color) {
      this.selectedColor = this.color;
    }
    else {
    this.selectedColor = this.randomColor();
    }
  }

  randomColor() {
    return '#' + Math.floor(Math.random()*16777215).toString(16);
  }
  onColorSelected(event: any) {
    this.selectedColor = event.value;
    this.colorChanged.emit(event.value);
  }
  isSelectedColor(color: string): boolean {
    return color === this.selectedColor;
  }

  isColorUsed(color: string): boolean {
    return  this.usedLabels.some((label) => label.colour === color);
  }
}
