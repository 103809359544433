import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlaylistService } from '../shared/services/playlist.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-delete-playlist-dialog',
  templateUrl: './delete-playlist-dialog.component.html',
  styleUrls: ['./delete-playlist-dialog.component.scss'],
})
export class DeletePlaylistDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private playlistService : PlaylistService) {}

  ngOnInit() {
    console.log(this.data);
  }
  deletePlaylist() {

    this.playlistService.deletePlaylist(this.data.id).subscribe((response) => {
      if(response.status >= 200 && response.status < 300) {
        this.playlistService.setPlaylistDeleted$(this.data.id);
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          title: 'Playlist Deleted',
          icon: 'info',
          iconColor: 'black',
          background: '#21f367CC',
        });
      }
      //Error handling is in the playlist.service
    });
  }
}
