import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { StorageService } from 'src/app/shared/storage.service';
import { Song } from '../../models/song';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Howl } from 'howler';
import { BehaviorSubject, Subject, takeUntil, timeout } from 'rxjs';
import { AddEditSongInPlayListModel } from '../../models/add-edit-playlist-song';
import Swal from 'sweetalert2';
import { WaveSurferService } from 'src/app/shared/services/wave-surfer.service';
import { createWaveSurferService } from 'src/app/shared/helpers/wavesurferFactory';
import { GoogleTagManagerImplService } from 'src/app/shared/services/google-tag-manager.service';

@Component({
  selector: 'app-playlist-mixer',
  templateUrl: './playlist-mixer.component.html',
  styleUrls: ['./playlist-mixer.component.scss'],
  providers: [WaveSurferService],
})
export class PlaylistMixerComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() song!: Song;
  @Input() nextSong: Song;
  @Input() playlistId: number;
  @Input() songSequence: number;
  @Input() nextSongSequence: number;
  @Output() songUpdated = new EventEmitter<null>();

  duration: number;
  nextduration: number;
  songBehaviourSubject = new BehaviorSubject<Song>(this.song);

  songObservable = this.songBehaviourSubject.asObservable();

  mixTime: number;
  markers: any;
  markers2: any;
  seeking: any;

  seeking2: boolean;
  monitoring: boolean;
  monitorInterval: NodeJS.Timeout;
  waveSurferReady: boolean = false;
  waveSurferReady2: boolean = false;

  waveClicked: boolean = false;
  waveClicked2: boolean = false;
  private destroy$ = new Subject<void>();
  fadeLeftCheckboxSelected: boolean = true;
  fadeRightCheckboxSelected: boolean = true;
  interval = 5;
  sprite: Howl;
  sprite2: Howl;
  spriteLoaded: boolean;
  spriteLoaded2: boolean;
  stopTime: number = 0;
  startTimePreviewColor = '#667';
  endTimePreviewColor: string;
  visiblePlayerButtons: string[] = [
    'cue-start-button',
    'play-fade-left-button',
    'backward-seek-10ms-button',
    'backward-seek-100ms-button',
    'play-button',
    'pause-button',
    'forward-seek-10ms-button',
    'forward-seek-100ms-button',
    'play-fade-right-button',
    'cue-mix-button',
    'volume-left',
    'volume-right',
  ];

  peaks: any;
  peaks2: any;
  isPlaying: boolean = false;
  activePlayer: number = 1;
  isMixing: boolean = false;
  isFadingOut: boolean = false;
  isSaving: boolean = false;
  shouldMix: boolean = true;
  waveSurferService1: WaveSurferService;
  waveSurferService2: WaveSurferService;
  loaded = new BehaviorSubject<boolean>(false);
  loading = true;
  constructor(
    private storageService: StorageService,
    private venueLibraryService: VenueLibraryService,
    public activeModal: NgbActiveModal,
    private injector: Injector,
    private gaService: GoogleTagManagerImplService
  ) {
    this.waveSurferService1 = createWaveSurferService(this.injector);
    this.waveSurferService2 = createWaveSurferService(this.injector);
    this.loaded.subscribe((loaded) => {
      if (loaded) this.loading = false;
    });
  }

  ngOnInit(): void {
    this.gaService.trackModalOpen('Playlist Mixer', this.playlistId);
    this.fadeLeftCheckboxSelected =
      this.song.songProperties.fadeOutDuration != 0;
    this.fadeRightCheckboxSelected =
      this.nextSong.songProperties.fadeInDuration != 0;

    this.createSprite(0, 0);
    this.createSprite2(0, 0);

    this.venueLibraryService
      .getSongPeaks(this.song.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((peaks) => {
        this.peaks = peaks;

        this.initializePlayer();
      });

    this.venueLibraryService
      .getSongPeaks(this.nextSong.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((peaks) => {
        this.peaks2 = peaks;

        this.setPreviewStartTimeColour();
        this.setPreviewEndTimeColour();
        this.initializePlayer2();

        this.loaded.next(true);
      });
    this.songObservable.pipe(takeUntil(this.destroy$)).subscribe((song) => {
      // this.redrawMarkers();
      this.setPreviewStartTimeColour();
      this.setPreviewEndTimeColour();
    });
    this.startMonitor();
  }

  ngAfterViewInit(): void {
    // Assuming you have already initialized WaveSurfer
    var waveContainer = document.querySelectorAll('.waveform'); // Replace with the appropriate selector for your wave container element
    waveContainer?.forEach((s) => s.classList.add('no-scroll-bars'));
  }

  getVolume() {
    if (!this.waveSurferService1) return 0;
    return Math.round(this.waveSurferService1.getVolume() * 100) / 100;
  }

  getVolume2() {
    if (!this.waveSurferService2) return 0;
    return Math.round(this.waveSurferService2.getVolume() * 100) / 100;
  }

  runMonitor(): void {
    this.mixingProcessor();
    this.monitoring = true;
  }

  startMonitor(): void {
    if (!this.monitoring)
      this.monitorInterval = setInterval(() => this.runMonitor(), 100);
  }

  stopMonitor(): void {
    clearInterval(this.monitorInterval);
    this.monitoring = false;
  }

  closeModal() {
    this.waveSurferService1.stop();
    this.waveSurferService2.stop();
    this.activeModal.close();
  }

  initializePlayer() {
    const duration = this.song.duration!;
    this.duration = duration;

    // this.waveSurferService = WaveSurfer.create({
    //   container: this.waveformRef.nativeElement,
    //   waveColor: 'white',
    //   cursorColor: 'yellow',
    //   barHeight: 0.5,
    //   height: 70,
    // });

    const audioStreamUrl = this.venueLibraryService.getAudioStreamUrl(
      this.song.id
    );

    this.waveSurferService1.getWaveSurfer('#waveform',audioStreamUrl, this.peaks, this.song.duration);
    //this.waveSurferService1.createTimeline("#timeline",this.duration, 5);

    this.waveSurferService1.setVolume(0);
    this.setVolume(0);
    this.initializeListeners1();

    //this.onFadeRightSelected();
    //this.onFadeLeftSelected();
  }
  private setPreviewStartTimeColour() {
    if (
      !this.fadeLeftCheckboxSelected &&
      this.song.songProperties.startTime === 0
    ) {
      this.startTimePreviewColor = '#667';
    }

    if (
      this.song.songProperties.startTime !== 0 &&
      !this.fadeLeftCheckboxSelected
    ) {
      this.startTimePreviewColor = 'rgb(0, 255, 204)';
    }
    if (this.fadeLeftCheckboxSelected) {
      this.startTimePreviewColor = 'rgb(255, 165, 0)';
    }
  }

  private setPreviewEndTimeColour() {
    if (
      !this.fadeRightCheckboxSelected &&
      this.song.songProperties.mixTime === this.duration
    ) {
      this.endTimePreviewColor = '#667';
    }

    if (
      this.song.songProperties.mixTime !== this.duration &&
      !this.fadeRightCheckboxSelected
    ) {
      this.endTimePreviewColor = 'rgb(0, 255, 204)';
    }
    if (this.fadeRightCheckboxSelected) {
      this.endTimePreviewColor = 'rgb(255, 165, 0)';
    }
  }
  private mixingProcessor() {
    const triggerFadeOutTime =
      this.song.songProperties.fadeOutDuration < 0
        ? this.song.songProperties.mixTime -
          Math.abs(this.song.songProperties.fadeOutDuration)
        : this.song.songProperties.mixTime;

    const currentTime = this.waveSurferService1.getCurrentTime();

    const currentTime2 = this.waveSurferService2.getCurrentTime();

    // If the left side waveForm is clicked, we need to play music from the left side, this will only execute for the specific seek event, and on the very next event, the default
    // behaviour will again be executed in the else block.
    if (this.waveClicked || this.waveClicked2) {
      this.waveClicked = false;
      this.waveClicked2 = false;
      this.isMixing = false;
      this.isFadingOut = false;

      this.shouldMix =
        this.activePlayer == 1
          ? currentTime <= triggerFadeOutTime
            ? true
            : false
          : false;

      if (this.activePlayer == 1 && currentTime >= triggerFadeOutTime) {
        //If the user clicked the waveform to a position after the normal stop time, then we need to play the song until the end of the track, and full volume which was already set above.
        this.stopTime = this.song.duration;
      }

      this.waveSurferService1.cancelFade();
      this.waveSurferService2.cancelFade();
      this.waveSurferService1.setVolume(this.song.songProperties.volume); // We should get this from the Playlist Mixer Volume configuration
      this.waveSurferService2.setVolume(this.nextSong.songProperties.volume);

      if (this.activePlayer == 1) {
        this.waveSurferService2.stop();
        //this.waveSurferService1.setTime(currentTime);
        this.waveSurferService2.setTime(this.nextSong.songProperties.startTime);
      } else {
        this.waveSurferService1.stop();
        //this.waveSurferService2.setTime(currentTime2);
        this.waveSurferService1.setTime(this.nextSong.songProperties.startTime);
      }

      if (this.isPlaying) {
        this.activePlayer == 1
          ? this.waveSurferService1.play()
          : this.waveSurferService2.play();
      }
      return;
    } else {
      //This block is the default behavior when the left side is playing.

      // If the Fade Out checkbox is selected, we need to trigger the fadeout at the appropriate time.
      if (this.fadeLeftCheckboxSelected) {
        if (
          currentTime >= triggerFadeOutTime &&
          this.isPlaying &&
          this.shouldMix &&
          !this.isFadingOut
        ) {
          this.isFadingOut = true;
          const fade_out_duration =
            Math.abs(this.song.songProperties.fadeOutDuration) * 1000;
          if (this.isPlaying) {
            this.waveSurferService1.fadeOut(
              fade_out_duration,
              this.song.songProperties.volume
            );
          }
        }
      }

      // If the music is playing, and we have not yet started mixing the two songs, we check if the current song time hits  the mix time target, and if so, we need to start mixing.
      if (
        currentTime >= this.song.songProperties.mixTime &&
        this.isPlaying &&
        !this.isMixing &&
        this.shouldMix
      ) {
        this.activePlayer = 2;
        this.isMixing = true;
        this.waveSurferService2.setTime(this.nextSong.songProperties.startTime);
        //If the Fadein Checkbox, we need to have fade in behaviour, otherwise, we don't fade, and just set the to the tracks volume settings (default 85%)
        if (this.fadeRightCheckboxSelected) {
          this.waveSurferService2.fadeIn(
            this.nextSong.songProperties.fadeInDuration * 1000,
            this.nextSong.songProperties.volume
          );
        } else {
          this.setVolume2(this.nextSong.songProperties.volume);
        }
        this.waveSurferService2.play();
      }
    }
    // If the fade out checkbox is selected, we need to determine if we fadeout before the mix time or after the mixtime.
    //If we are before the mix time, it means the music should fade out starting at 85% volume down to zero as it hits the mix time.
    // If we do not have the fadeOut checkbox selected, we need to play the music until the end of the track on the left side player.
    if (this.fadeLeftCheckboxSelected) {
      if (!this.isMixing && this.stopTime === 0) {
        this.stopTime =
          this.song.songProperties.fadeOutDuration < 0
            ? this.song.songProperties.mixTime
            : this.song.songProperties.mixTime +
              this.song.songProperties.fadeOutDuration;
      }

      if (currentTime >= this.stopTime && this.isPlaying) {
        this.waveSurferService1.stop();
        this.isMixing = false;
      }
    }
  }

  private initializeListeners1() {
    this.waveSurferService1.on('click', (e) => {
      this.waveClicked = true;
      this.activePlayer = 1;
    });

    this.waveSurferService1.on('ready', () => {
      this.waveSurferService1?.seekTo(0);
      this.duration = this.waveSurferService1.getDuration();
      this.initializeMarkers(1, this.song, false, true, false, true);
      this.waveSurferReady = true;
    });

    this.waveSurferService1.onRegion('region-updated', (region) => {
      //console.log(region);

      //Reset the fading anytime a marker is moved
      this.waveSurferService1.cancelFade();
      this.setVolume(this.song.songProperties.volume);
      this.waveSurferService2.cancelFade();
      this.setVolume2(this.nextSong.songProperties.volume);
      this.stopTime = 0;

      const time = Math.round(region.start * 100) / 100;
      this.waveClicked = true;

      if (region.id === 'Mix') {
        if (time >= this.song.songProperties.startTime) {
          this.song.songProperties.mixTime = time;
          this.waveSurferService1.setTime(time);
          if (this.fadeLeftCheckboxSelected) {
            this.waveSurferService1.removeRegion('Fade Out');
            this.drawFadeOutMarker(1, this.song);
          }
        } else {
          this.waveSurferService1.removeRegion('Mix');
          this.drawMixMarker(1, this.song);
        }
      }

      if (region.id === 'Fade Out') {
        this.song.songProperties.fadeOutDuration =
          time - this.song.songProperties.mixTime;
      }
      this.waveClicked = false;
      this.songBehaviourSubject.next(this.song);
    });
  }

  private initializeMarkers(
    player: number,
    song: Song,
    start: boolean,
    mix: boolean,
    fadeIn: boolean,
    fadeOut: boolean
  ) {
    if (
      song.songProperties.mixTime === null ||
      song.songProperties.mixTime === 0
    ) {
      song.songProperties.mixTime = this.song.duration;
    }

    song.songProperties.startTime ??= 0;

    if (start) this.drawStartMarker(player, song);

    if (mix) this.drawMixMarker(player, song);

    if (this.fadeLeftCheckboxSelected && fadeOut) {
      this.drawFadeOutMarker(player, song);
    }

    if (this.fadeRightCheckboxSelected && fadeIn) {
      this.drawFadeInMarker(player, song);
    }

    song.songProperties.fadeInDuration ??= 0;
    song.songProperties.fadeOutDuration ??= 0;
  }

  initializePlayer2() {
    const nextduration = this.nextSong.duration!;
    this.nextduration = nextduration;

    const audioStreamUrl = this.venueLibraryService.getAudioStreamUrl(
      this.nextSong.id
    );

    this.waveSurferService2.getWaveSurfer('#waveform2', audioStreamUrl, this.peaks2, this.nextSong.duration
);
    //this.waveSurferService2.createTimeline("#timeline2",this.duration, 5);

    // this.waveSurferService2 = WaveSurfer.create({
    //   container: this.waveformRef2.nativeElement,
    //   waveColor: 'white',
    //   cursorColor: 'yellow',
    //  // backend: 'MediaElement',
    //   barHeight: 0.5,
    //   height: 70,
    //   // xhr: {
    //   //   requestHeaders: [
    //   //     {
    //   //       key: 'Authorization',
    //   //       value: 'Bearer ' + this.storageService.getToken(),
    //   //     },
    //   //   ],
    //   // },
    //   plugins: [],
    // });



    this.waveSurferService2.setVolume(0);


    this.waveSurferService2.setVolume(0);
    this.setVolume2(0);
    this.initializeListeners2();
  }

  private initializeListeners2() {
    this.waveSurferService2.on('click', (e) => {
      this.waveClicked2 = true;
      this.activePlayer = 2;
    });

    this.waveSurferService2.on('ready', () => {
      this.waveSurferService2?.setTime(this.nextSong.songProperties.startTime);
      this.nextduration = this.waveSurferService2.getDuration();
      this.initializeMarkers(2, this.nextSong, true, false, true, false);
      this.waveSurferReady2 = true;
    });

    this.waveSurferService2.onRegion('region-updated', (region) => {
      //console.log(region);

      //Reset the fading anytime a marker is moved
      this.waveSurferService1.cancelFade();
      this.setVolume(this.song.songProperties.volume);
      this.waveSurferService2.cancelFade();
      this.setVolume2(this.nextSong.songProperties.volume);

      const time = Math.round(region.start * 100) / 100;
      this.waveClicked = true;
      if (region.id === 'Start') {
        if (time <= this.nextSong.songProperties.mixTime) {
          this.nextSong.songProperties.startTime = time;
          this.waveSurferService2.setTime(time);
          if (this.fadeRightCheckboxSelected) {
            this.waveSurferService2.removeRegion('Fade In');
            this.drawFadeInMarker(2, this.nextSong);
          }
        } else {
          this.waveSurferService2.removeRegion('Start');
          this.drawStartMarker(2, this.nextSong);
        }
      }

      if (region.id === 'Fade In') {
        if (time >= this.nextSong.songProperties.startTime) {
          this.nextSong.songProperties.fadeInDuration =
            time - this.nextSong.songProperties.startTime;
        } else {
          this.waveSurferService2.removeRegion('Fade In');
          this.drawFadeInMarker(2, this.nextSong);
        }
      }
      this.songBehaviourSubject.next(this.song);

      this.waveClicked = false;
    });
  }

  onStartCue() {
    this.song.songProperties.mixTime = this.waveSurferService1.getCurrentTime();
    this.waveSurferService1.removeRegion('Mix');
    this.songBehaviourSubject.next(this.song);

    this.drawMixMarker(1, this.song);
    if (this.fadeLeftCheckboxSelected) {
      this.waveSurferService1.removeRegion('Fade Out');
      this.drawFadeOutMarker(1, this.song);
    }
  }

  onMixCue() {
    this.nextSong.songProperties.startTime =
      this.waveSurferService2.getCurrentTime();
    this.songBehaviourSubject.next(this.song);

    this.waveSurferService2.removeRegion('Start');
    this.drawStartMarker(2, this.nextSong);
    if (this.fadeRightCheckboxSelected) {
      this.waveSurferService2.removeRegion('Fade In');
      this.drawFadeInMarker(2, this.nextSong);
    }
  }

  onFadeLeftSelected() {
    this.fadeLeftCheckboxSelected = !this.fadeLeftCheckboxSelected;
    if (this.fadeLeftCheckboxSelected) {
      if (this.fadeLeftCheckboxSelected) {
        if (this.song.songProperties.fadeOutDuration === 0) {
          this.song.songProperties.fadeOutDuration = -3;
        }
      }
      this.drawFadeOutMarker(1, this.song);
    } else {
      this.resetFadeOut();
    }
    this.songBehaviourSubject.next(this.song);
  }

  onFadeLeftDurationChanged(e) {
    let fadeOutDuration = e.target.value as number;
    fadeOutDuration = fadeOutDuration / 1;
    this.song.songProperties.fadeOutDuration = fadeOutDuration;
    this.waveSurferService1.removeRegion('Fade Out');
    this.drawFadeOutMarker(1, this.song);
  }

  resetStartTime() {}
  resetMixTime() {}

  resetFadeIn() {
    this.songBehaviourSubject.next(this.song);

    this.waveSurferService2.removeRegion('Fade In');
    this.songBehaviourSubject.next(this.song);
  }

  resetFadeOut() {
    this.songBehaviourSubject.next(this.song);

    this.waveSurferService1.removeRegion('Fade Out');
  }

  onPlayFadeLeft() {
    this.waveSurferService1.cancelFade();
    this.waveSurferService2.cancelFade();
    const self = this;
    const shouldPlay = this.isPlaying;
    this.isPlaying = false;
    this.isMixing = false;
    this.shouldMix = true;
    this.isFadingOut = false;
    this.activePlayer = 1;
    this.waveSurferService1.stop();
    this.waveSurferService2.stop();

    const fadeOutSeekTo =
      this.song.songProperties.fadeOutDuration < 0
        ? this.song.songProperties.mixTime +
          this.song.songProperties.fadeOutDuration -
          5
        : this.song.songProperties.mixTime - 5;
    this.waveSurferService1.setTime(fadeOutSeekTo);
    this.waveSurferService2.setTime(this.nextSong.songProperties.startTime);
    this.waveSurferService1.setVolume(this.song.songProperties.volume);
    this.waveSurferService2.setVolume(0);

    if (shouldPlay == false) return;

    this.waveSurferService1.play();
    this.isPlaying = true;
    this.songBehaviourSubject.next(this.song);
  }

  onGoToStart() {}

  private setVolume(volume: number) {
    if (volume >= 0) {
      this.waveSurferService1.setVolume(volume);
    }
  }

  private setVolume2(volume: number) {
    if (volume >= 0) {
      this.waveSurferService2.setVolume(volume);
    }
  }

  backwardSeek(val) {
    this.waveSurferService1.cancelFade();
    this.setVolume(this.song.songProperties.volume);
    this.waveSurferService2.cancelFade();
    this.setVolume2(this.nextSong.songProperties.volume);

    if (val == undefined) {
      val = 0.1;
    }
    const currentWaveSurfer =
      this.activePlayer === 1
        ? this.waveSurferService1
        : this.waveSurferService2;
    const currentSeek = currentWaveSurfer!.getCurrentTime();
    const seekTo = currentSeek - val;
    this.isPlaying = false;
    currentWaveSurfer?.stop();
    currentWaveSurfer.setTime(seekTo);
    if (this.activePlayer === 1) this.playSprite(seekTo * 1000, val * 1000);
    else this.playSprite2(seekTo * 1000, val * 1000);
  }

  onPlay() {
    if (this.activePlayer === 1) {
      this.setVolume(this.song.songProperties.volume);
      this.waveSurferService1.setTime(this.waveSurferService2.getCurrentTime());
      this.waveSurferService1.play();
    } else {
      this.setVolume2(this.nextSong.songProperties.volume);
      this.waveSurferService2.setTime(this.waveSurferService2.getCurrentTime());
      this.waveSurferService2.play();
    }
    this.isPlaying = true;
  }

  onPause() {
    this.waveSurferService1.pause();
    this.waveSurferService2.pause();
    this.isPlaying = false;
    this.isMixing = false;
    this.isFadingOut = false;
  }

  forwardSeek(val) {
    //Reset the fading anytime a marker is moved
    this.waveSurferService1.cancelFade();
    this.setVolume(this.song.songProperties.volume);
    this.waveSurferService2.cancelFade();
    this.setVolume2(this.nextSong.songProperties.volume);

    if (val == undefined) {
      val = 0.1;
    }
    const currentWaveSurfer =
      this.activePlayer === 1
        ? this.waveSurferService1
        : this.waveSurferService2;
    const currentSeek = currentWaveSurfer!.getCurrentTime();
    const seekTo = currentSeek + val;
    this.isPlaying = false;
    currentWaveSurfer?.stop();
    currentWaveSurfer.setTime(seekTo);
    if (this.activePlayer === 1) this.playSprite(seekTo * 1000, val * 1000);
    else this.playSprite2(seekTo * 1000, val * 1000);
  }

  onVolumeLeft($event) {
    this.song.songProperties.volume = $event.target.value / 100;
  }

  onVolumeRight($event) {
    this.nextSong.songProperties.volume = $event.target.value / 100;
  }

  createSprite(start, stop) {
    const audioStreamUrl = this.venueLibraryService.getAudioStreamUrl(
      this.song.id
    );
    this.sprite = new Howl({
      src: [audioStreamUrl],
      sprite: {
        seekSprite: [start, stop],
      },
      autoplay: false,
      loop: false,
      preload: true,
      html5: false,
      format: ['mp3'],
      onload: () => {
        //console.log('sprite loaded');
        this.spriteLoaded = true;
      },
    });
    this.sprite.volume(1);
    //console.log('sprite created');
  }

  createSprite2(start, stop) {
    const audioStreamUrl = this.venueLibraryService.getAudioStreamUrl(
      this.nextSong.id
    );
    this.sprite2 = new Howl({
      src: [audioStreamUrl],
      sprite: {
        seekSprite: [start, stop],
      },
      autoplay: false,
      loop: false,
      preload: true,
      html5: false,
      format: ['mp3'],
      onload: () => {
        //console.log('sprite2 loaded');
        this.spriteLoaded2 = true;
      },
    });
    this.sprite2.volume(1);
    //console.log('sprite2 created');
  }

  playSprite(start, stop) {
    if (this.sprite?.state() != 'loaded') {
      //console.log('Sprite Playback aborted, not yet loaded');
      return;
    }

    if (this.sprite?.playing()) {
      //console.log('sprite already playing');
      return;
    }

    (this.sprite as any)._sprite.seekSprite = [start, stop];

    if (this.sprite?.state() === 'loaded') {
      this.spriteLoaded = true;
      this.sprite.play('seekSprite');
    } else {
      //console.log('sprite not loaded yet');
      this.playSprite(start, stop);
      this.spriteLoaded = false;
    }
  }

  playSprite2(start, stop) {
    if (this.sprite2?.state() != 'loaded') {
      //console.log('Sprite2 Playback aborted, not yet loaded');
      return;
    }

    if (this.sprite2?.playing()) {
      //console.log('sprite2 already playing');
      return;
    }

    (this.sprite2 as any)._sprite.seekSprite = [start, stop];

    if (this.sprite2?.state() === 'loaded') {
      this.spriteLoaded2 = true;
      this.sprite2.play('seekSprite');
    } else {
      //console.log('sprite2 not loaded yet');
      this.playSprite2(start, stop);
      this.spriteLoaded2 = false;
    }
  }

  onGoToEnd() {
    this.waveSurferService1.setTime(this.song.duration);
  }

  onPlayFadeRight() {
    this.waveSurferService1.cancelFade();
    this.waveSurferService2.cancelFade();
    const shouldPlay = this.isPlaying;
    this.isPlaying = false;
    this.isMixing = false;
    this.isFadingOut = false;
    this.activePlayer = 2;
    this.waveSurferService1.stop();
    this.waveSurferService2.setTime(this.nextSong.songProperties.startTime);
    this.setVolume2(0.0);

    const self = this;

    if (self.fadeRightCheckboxSelected && shouldPlay) {
      const fade_in_duration =
        self.nextSong.songProperties.fadeInDuration * 1000;
      this.waveSurferService2.fadeIn(
        fade_in_duration,
        this.nextSong.songProperties.volume
      );
      this.waveSurferService2.play();
      this.isPlaying = true;
      return;
    }

    if (shouldPlay == false) return;

    this.setVolume2(this.nextSong.songProperties.volume);
    this.waveSurferService2.play();
    this.isPlaying = true;
    this.songBehaviourSubject.next(this.song);
  }

  onFadeRightSelected() {
    this.fadeRightCheckboxSelected = !this.fadeRightCheckboxSelected;
    if (this.fadeRightCheckboxSelected) {
      if (this.nextSong.songProperties.fadeInDuration === 0) {
        this.nextSong.songProperties.fadeInDuration = 3;
      }
      this.drawFadeInMarker(2, this.nextSong);
    } else {
      this.resetFadeIn();
    }
    this.songBehaviourSubject.next(this.song);
  }

  onFadeRightDurationChanged(e) {
    let fadeInDuration = e.target.value as number;
    fadeInDuration = fadeInDuration / 1;
    this.nextSong.songProperties.fadeInDuration = fadeInDuration;
    this.waveSurferService2.removeRegion('Fade In');
    this.drawFadeInMarker(2, this.nextSong);
  }

  drawFadeOutMarker(player: number, song: Song) {
    const element: HTMLElement = this.drawLabelForMarker(
      'FOut',
      'rgb(255, 165, 0)'
    );
    this.waveSurferService1.addRegion(
      'Fade Out',
      song.songProperties.fadeOutDuration < 0
        ? song.songProperties.mixTime + song.songProperties.fadeOutDuration
        : song.songProperties.mixTime + song.songProperties.fadeOutDuration,
      'rgb(255, 165, 0)',
      element
    );
  }

  drawStartMarker(player: number, song: Song) {
    const element: HTMLElement = this.drawLabelForMarker('St', '#00ffcc');

    this[`waveSurferService` + player].addRegion(
      'Start',
      song.songProperties.startTime,
      '#00ffcc',
      element
    );
  }
  drawMixMarker(player: number, song: Song) {
    const element: HTMLElement = this.drawLabelForMarker('Mx', '#00ffcc');
    this[`waveSurferService` + player].addRegion(
      'Mix',
      song.songProperties.mixTime,
      '#00ffcc',
      element
    );
  }

  drawFadeInMarker(player: number, song: Song) {
    const element: HTMLElement = this.drawLabelForMarker(
      'FIn',
      'rgb(255, 165, 0)'
    );
    this[`waveSurferService` + player].addRegion(
      'Fade In',
      song.songProperties.fadeInDuration + song.songProperties.startTime,
      'rgb(255, 165, 0)',
      element
    );
  }

  drawLabelForMarker(id: string, color: string): HTMLElement {
    const element = document.createElement('div');
    const span = document.createElement('span');
    element.appendChild(span);
    span.textContent = id;

    // Apply CSS styles to make it look attractive
    span.style.color = 'white';
    span.style.backgroundColor = color;
    span.style.padding = '2px 4px'; // Increased padding for a softer look
    span.style.borderRadius = '4px'; // Rounded corners
    span.style.fontWeight = 'bold'; // Bold text
    span.style.fontSize = '10px'; // Increased font size
    span.style.textTransform = 'uppercase'; // Uppercase text
    span.style.cursor = 'pointer'; // Change cursor on hover

    // Add a hover effect
    span.addEventListener('mouseenter', () => {
      span.style.backgroundColor = 'white'; // Change background color on hover
      span.style.color = color;
      span.style.transition = 'background-color 0.3s'; // Add a smooth transition
      span.style.transition = 'color 0.3s'; // Add a smooth transition
    });

    span.addEventListener('mouseleave', () => {
      span.style.backgroundColor = color; // Restore background color on mouse leave
      span.style.color = 'white';
    });

    span.style.whiteSpace = 'nowrap';
    return element;
  }

  SavePlaylistSongs() {
    this.isSaving = true;

    if (!this.fadeLeftCheckboxSelected) {
      this.song.songProperties.fadeOutDuration = 0;
    }

    if (!this.fadeRightCheckboxSelected) {
      this.nextSong.songProperties.fadeInDuration = 0;
    }

    const playlistSongLeft: AddEditSongInPlayListModel = {
      playlistId: this.playlistId, //TODO: get playlist id
      songId: this.song.id,
      startTime: this.song.songProperties.startTime,
      mixTime: this.song.songProperties.mixTime,
      fadeOutDuration: this.song.songProperties.fadeOutDuration,
      fadeInDuration: this.song.songProperties.fadeInDuration,
      volume: this.song.songProperties.volume,
      sequence: this.songSequence,
    };
    const playlistSongRight: AddEditSongInPlayListModel = {
      playlistId: this.playlistId,
      songId: this.nextSong.id,
      startTime: this.nextSong.songProperties.startTime,
      mixTime: this.nextSong.songProperties.mixTime,
      fadeOutDuration: this.nextSong.songProperties.fadeOutDuration,
      fadeInDuration: this.nextSong.songProperties.fadeInDuration,
      volume: this.nextSong.songProperties.volume,
      sequence: this.nextSongSequence,
    };

    this.SavePlaylistSong(playlistSongLeft);
    this.SavePlaylistSong(playlistSongRight);
  }

  SavePlaylistSong(playlistSong: AddEditSongInPlayListModel) {
    this.venueLibraryService
      .addEditSongInPlayList(playlistSong)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.isSaving = false;
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Successfully updated Playlist',
            icon: 'success',
            iconColor: 'black',
            background: '#21f367CC',
          });

          this.closeModal();
        },
        (error) => {
          this.isSaving = false;
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            title: `Failed to update Playlist `,
            icon: 'error',
            iconColor: 'black',
            background: '#EF5350CC',
          });
        }
      );
  }

  ngOnDestroy() {
    this.stopMonitor();
    this.waveSurferService1.destroy();
    this.waveSurferService2.destroy();
    this.destroy$.next();
    this.destroy$.complete();
    this.songBehaviourSubject.unsubscribe();
  }
}
