import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Subscriptions, Subscription } from 'src/app/views/shared/models/subscriptions';
import { environment } from 'src/environments/environment';

const Payments_API = environment.paymentUrl + '/api/Payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  isInitialized = false;
  redirectUrl: Subject<string> = new Subject<string>();

  subscriptions: BehaviorSubject<Subscriptions | null> = new BehaviorSubject<Subscriptions | null>(null);

  constructor(private http: HttpClient) {}

  createPaymentSession(venueId: number) {
    const params = new HttpParams().set('venueId', venueId.toString());
    return this.http.post(`${Payments_API}/CreatePaymentSession`, null, {
      params,
      responseType: 'text' as 'json'
    });
  }

  cancelSubscription(venueId: number) {
    const params = new HttpParams().set('venueId', venueId.toString());
    return this.http.post<string>(`${Payments_API}/CancelSubscription`, null, { params, responseType: 'json' });
  }

  getSubscriptions(): Observable<Subscriptions> {
    return this.http.get<Subscription[]>(`${Payments_API}/GetSubscriptions`, { responseType: 'json' })
      .pipe(
        map((data: Subscription[]) => {
          return { subscription: data };
        }),
        catchError(error => {
          console.error('Error fetching subscriptions:', error);
          return of({ subscription: [] } as Subscriptions); // Return an empty subscription object on error
        })
      );
  }

  setSubscriptions(subscriptions: Subscriptions) {
    this.subscriptions.next(subscriptions);
    this.isInitialized = true;
  }

}
