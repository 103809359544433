import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { GoogleTagManagerImplService } from 'src/app/shared/services/google-tag-manager.service';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { VenuesService } from 'src/app/shared/services/venues.service';
import { CreatePlaylistModel } from 'src/app/views/shared/models/create-playlist';
import { Team } from 'src/app/views/shared/models/team';
import { Venue } from 'src/app/views/shared/models/venues';

@Component({
  selector: 'app-edit-playlist',
  templateUrl: './edit-playlist.component.html',
  styleUrls: ['./edit-playlist.component.scss'],
})
export class EditPlaylistComponent implements OnInit, OnDestroy {
  selectedTeam: Team;
  venues: Venue[];

  @Input() data: any;
  @Input() seasons: Season[];
  @Input() moodsOfDay: MoodOfDay[];

  constructor(
    private venueService: VenuesService,
    private teamsService: TeamsService,
    public activeModal: NgbActiveModal,
    private gaService: GoogleTagManagerImplService,
  ) {
    this.selectedTeam = this.teamsService.getSelectedTeam();

    this.venueService.storedVenuesSubscription().subscribe((data) => {
      this.venues = data;
    });
  }

  private destroy$ = new Subject<void>();

  title = 'Add Playlist';
  isEmailInvalid = false;
  playlistForm: FormGroup;

  ngOnInit(): void {
    this.gaService.trackModalOpen('Edit Playlist', this.data?.venueid);
    this.data ? (this.title = 'Edit Playlist') : (this.title = 'Add Playlist');
    this.playlistForm = new FormGroup({
      venueId: new FormControl(this.data ? this.data.venueid : 0, [
        Validators.required,
      ]),
      name: new FormControl(this.data ? this.data.name : '', [
        Validators.required,
      ]),
      description: new FormControl(this.data ? this.data.description : '', [
        Validators.required,
      ]),
      seasonId: new FormControl(
        this.data
          ? this.seasons.filter((s) => s.id === this.data.seasonId)[0].id
          : -1,
        [Validators.required]
      ),
      moodOfDayId: new FormControl(
        this.data
          ? this.moodsOfDay.filter((m) => m.id === this.data.moodId)[0].id
          : -1,
        [Validators.required]
      ),
    });
  }

  isRequired(fieldName: string): boolean {
    return (
      this.playlistForm.controls[fieldName].errors?.required &&
      this.playlistForm.get(fieldName)?.touched
    );
  }
  savePlaylist() {
    const formValues = this.playlistForm.value as CreatePlaylistModel;
    formValues.teamId = this.selectedTeam.id;
    formValues.venueId = Number(formValues.venueId);
    formValues.id = this.data ? this.data.id : null;
    formValues.seasonId = Number(formValues.seasonId);
    formValues.moodOfDayId = Number(formValues.moodOfDayId);
    formValues.moodOfDay = this.moodsOfDay.filter(
      (m) => m.id === formValues.moodOfDayId
    )[0].name;
    formValues.season = this.seasons.filter(
      (m) => m.id === formValues.seasonId
    )[0].name;

    this.activeModal.close(formValues);
  }
  closeModal() {
    this.activeModal.close('Closed');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

interface MoodOfDay {
  id: number;
  name: string;
}
interface Season {
  id: number;
  name: string;
}
