import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Venue } from '../../shared/models/venues';
import { EditVenueComponent } from './edit-venue/edit-venue.component';
import { Filter } from '../../shared/models/filter';
import { AuthService } from 'src/app/shared/auth.service';
import { RegisterVenue } from '../../shared/models/register-venue';
import { Team } from '../../shared/models/team';
import { Page } from '../../shared/models/page';
import { Subject, forkJoin, take, takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { faPen, faCalendar, faEuroSign, faRedo, faBan, faEnvelope, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { VenueLibraryService } from '../../../shared/services/venue-library.service';
import { PlaylistService } from '../../../shared/services/playlist.service';
import { VenuesService } from '../../../shared/services/venues.service';
import { TeamsService } from '../../../shared/services/teams.service';
import { MusicLibraryService } from '../../../shared/services/music-library.service';
import { SendActivationEmailDialogComponent } from '../../../send-activation-email-dialog/send-activation-email-dialog.component';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { Subscription } from '../../shared/models/subscriptions';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss'],
})
export class VenuesComponent implements OnInit, OnDestroy {

  members: any[] = [];
  panelShow = true;
  options = {};
  loading = false;
  columns: any = {};
  faPen: IconDefinition = faPen;
  faCalendar: IconDefinition = faCalendar;
  faEuroSign: IconDefinition = faEuroSign;
  faRedo: IconDefinition = faRedo;
  faEnvelope: IconDefinition = faEnvelope;
  faBan: IconDefinition = faBan;
  modalService: NgbModal;
  columnsWithFeatures: any;
  columnMode = ColumnMode.force;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  sortedBy: string;
  sortedDirection: string;
  selectedVenue: Venue | null;
  data: Venue[];
  filters: Filter[] = [];
  selectedTeam: Team | null;
  page = new Page();
  displayedColumns: string[] = [
    'position',
    'name',
    'contact',
    'email',
    'phone',
    'actions',
  ];
  dataSource = new MatTableDataSource<VenueListItem>;
  totalCount: number;
  constructor(
    private venuesService: VenuesService,
    private teamsService: TeamsService,
    private playlistService: PlaylistService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private teamService: TeamsService,
    private paymentService: PaymentService,
    public musicLibraryService: MusicLibraryService,
    public venueLibraryService: VenueLibraryService
  ) {}

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    if (this.venuesService.isInitialized === false || this.paymentService.isInitialized === false) {
      this.router.navigate(['/loading'], { state: { url: '/venues' } });
      return;
    }
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.options = {
      loader: true,
    };
    this.columns = [
      {
        prop: 'name',
        title: 'Venue Name',
        allowFilter: true,
        filterProperty: 'Name',
      },
      {
        prop: 'contactName',
        title: 'Contact Name',
        allowFilter: true,
        filterProperty: 'contactName',
      },
      {
        prop: 'email',
        title: 'Email',
        allowFilter: true,
        filterProperty: 'Email',
      },
      {
        prop: 'phoneNumber',
        title: 'Phone Number',
        allowFilter: true,
        filterProperty: 'phoneNumber',
      },
    ];
    this.setPage({ offset: 0 });

    forkJoin({
      venues: this.venuesService.storedVenuesSubscription().pipe(take(1)),
      subscriptions: this.paymentService.getSubscriptions().pipe(take(1))
    }).subscribe({
      next: ({ venues, subscriptions }) => {
        if(subscriptions == null) {
            this.paymentService.isInitialized = false;
            this.router.navigate(['/loading'], { state: { url: '/venues' } });
        }
        this.totalCount = Math.ceil(venues.length / this.page.size);
        this.data = venues.sort((a, b) => a.id - b.id);

        let dataToShow: VenueListItem[] = [];
        let sequence = 1;

        for (let venue of this.data) {
          let venueSubscription = subscriptions.subscription.find(sub => sub.venueId === venue.id);
          dataToShow.push(
            new VenueListItem(
              sequence++,
              venue.name,
              venue.contactName,
              venue.email,
              venue.phoneNumber,
              venue.id,
              venue.activationDate != null,
              venue.activationDate,
              venue.activationRequestDate,
              venueSubscription // subscription
            )
          );
        }

        this.dataSource = new MatTableDataSource<VenueListItem>(dataToShow);
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching data', error);
        this.loading = false;
      }
    });
  }



  onPaginateChange(event?: PageEvent) {
    console.log(event);
    console.log(this.totalCount);
    this.page.pageNumber = event?.pageIndex ?? 0;
    this.page.size = event?.pageSize ?? 10;
    this.GetVenues();
  }
  private GetVenues() {
    this.loading = true;
    this.teamsService
      .getSelectedTeamUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((team) => {
        if (team == null) {
          return;
        }
        this.selectedTeam = team;
      });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  sortBy(columnName: string, sortFn: Function) {
    this.sortedBy = columnName;
    this.sortedDirection = this.sortedDirection == 'desc' ? 'asc' : 'desc';
    sortFn();
  }
  activate(venue: Venue) {
    console.log(venue);
    this.dialog.open(SendActivationEmailDialogComponent, {
      data: venue,
    });
    this.dialog.afterAllClosed.subscribe((data) => {
      venue.activationRequestDate = new Date();
    });
  }
  editVenue(e) {}
  openModal(venue: Venue | null) {
    const modalRef = this.modalService.open(EditVenueComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    this.selectedVenue = venue;
    console.log(venue);
    modalRef.componentInstance.label = venue;
    modalRef.componentInstance.venue = venue;

    modalRef.result.then((result: RegisterVenue) => {
      console.log(result);
      if (result.id === null) {
        console.log('create');
        this.createVenue(result);
      } else {
        this.updateVenue(result);
      }
    });
  }



  updateVenue(model: RegisterVenue) {
    this.venuesService
      .UpdateVenue(model)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.venuesService
          .getVenues(this.selectedTeam!.id)
          .subscribe((data) => {
            this.venuesService.setVenues(data);

            this.venueLibraryService.downloadFoldersForTeam(
              this.selectedTeam!.id
            );
            this.musicLibraryService.downloadFolders(this.selectedTeam!.id);
            this.teamService.getSelectedTeamUpdates().subscribe(() => {});
            this.venueLibraryService
              .getTeamPlaylists(this.selectedTeam!.id, false, 1, 1000, [])
              .subscribe((data: any) => {
                this.venueLibraryService.data = data;
              });
          });
        this.loading = false;
      });
  }
  onFilter(columnName: string, event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;

    var filterColumn = this.columns.find(
      (x) => x.prop == columnName
    ).filterProperty;

    const filterEntry = this.filters.find((x) => x.field == filterColumn);
    if (filterEntry) {
      filterEntry.field = filterColumn;
      filterEntry.value = inputValue;
    } else {
      this.filters.push({ field: filterColumn, value: inputValue });
    }

    this.GetVenues();
  }

  getFilterValue(columnName: string) {
    var filterColumn = this.columns.find(
      (x) => x.prop == columnName
    ).filterProperty;

    const filterEntry = this.filters.find((x) => x.field == filterColumn);
    if (filterEntry) {
      return filterEntry.value;
    } else {
      return '';
    }
  }

  createVenue(result: RegisterVenue) {
    result.teamId = this.selectedTeam!.id;
    this.loading = true;
    this.authService
      .registerVenue(result)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.venuesService
          .getVenues(this.selectedTeam!.id)
          .subscribe((data) => {
            this.venuesService.setVenues(data);

            this.venueLibraryService.downloadFoldersForTeam(
              this.selectedTeam!.id
            );
            this.musicLibraryService.downloadFolders(this.selectedTeam!.id);
            this.teamService.getSelectedTeamUpdates().subscribe(() => {});
            this.venueLibraryService
              .getTeamPlaylists(this.selectedTeam!.id, false, 1, 1000, [])
              .subscribe((data: any) => {
                this.venueLibraryService.data = data;
              });
          });
        this.loading = false;
      });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.GetVenues();
  }

  openCalendar(venue: VenueListItem) {
    this.playlistService.currentCustomer.next(venue.name);
    this.router.navigate(['/venue-calendar', venue.id]);
  }

  subscribeVenue(element) {
    this.paymentService.createPaymentSession(element.id).subscribe(response => {
      this.paymentService.isInitialized = false;
      window.location.href = response as unknown as string;
    });
  }

  cancelSubscription(element) {
    this.paymentService.cancelSubscription(element.id).subscribe(response => {
      this.loading = true;
      this.paymentService.isInitialized = false;
       this.paymentService.getSubscriptions().subscribe(subscriptions => {
          this.paymentService.setSubscriptions(subscriptions);
          this.venuesService.getVenues(this.selectedTeam!.id).subscribe(venues => {
            this.venuesService.setVenues(venues);
          });
        });
        this.loading = false;
    });
  }

  reactivateSubscription(element) {
    this.subscribeVenue(element);
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
class VenueListItem {
  constructor(
    public index: number,
    public name: string,
    public contactName: string,
    public email: string,
    public phoneNumber: string,
    public id: number,
    public active: boolean,
    public activationDate: Date | null,
    public activationRequestDate: Date | null,
    public subscription: Subscription | undefined
  ) {}
}
