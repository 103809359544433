import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MusicLibraryService } from 'src/app/shared/services/music-library.service';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { Team } from '../../models/team';
import { Label } from '../../models/label';
import { LabelsService } from 'src/app/shared/services/labels.service';

@Component({
  selector: 'app-label-selector',
  templateUrl: './label-selector.component.html',
  styleUrls: ['./label-selector.component.scss'],
})
export class LabelSelectorComponent implements OnInit {
  private destroy$ = new Subject<void>();
  selectedTeam: Team | null;
  loading: boolean;
  @Input() selectedLabels: Label[] = [];
  @Input() otherLabels: Label[] = [];
  @Output() LabelsChanged = new EventEmitter<{selectedLabels: Label[], otherLabels:Label[]}>();
  sortablejsOptions: any;
  teamLabels: Label[] = [];
  constructor(
    private musicLibraryService: MusicLibraryService,
    private teamService: TeamsService,
    private labelsService: LabelsService
  ) {

  }

  ngOnInit(): void {
    this.teamService
      .getSelectedTeamUpdates()
      .pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.selectedTeam = data;
      });

      this.labelsService.labelsSub
      .pipe(takeUntil(this.destroy$)).subscribe((labels) => {
        this.teamLabels = labels;
        this.otherLabels = this.teamLabels.filter((label) => !this.selectedLabels.some((l) => l.id === label.id));
      });
  }

   onLabelDrop(event: any, label: Label) {

    this.LabelsChanged.emit({selectedLabels: this.selectedLabels, otherLabels: this.otherLabels});
  }

  toggleLabelSelection(label: Label) {
    const index = this.selectedLabels.findIndex((l) => l.id === label.id);
    if (index > -1) {
      this.selectedLabels.splice(index, 1);
      this.otherLabels.push(label);
    } else {
      this.selectedLabels.push(label);
      this.otherLabels.splice(this.otherLabels.indexOf(label), 1);
    }

    this.LabelsChanged.emit({selectedLabels: this.selectedLabels, otherLabels: this.otherLabels});
  }


}
