import { Component, Inject, OnInit } from '@angular/core';
import { Page } from '../views/shared/models/page';
import { AuthService } from '../shared/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Venue } from '../views/shared/models/venues';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-send-activation-email-dialog',
  templateUrl: './send-activation-email-dialog.component.html',
  styleUrls: ['./send-activation-email-dialog.component.scss'],
})
export class SendActivationEmailDialogComponent {
  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<SendActivationEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Venue
  ) {}

  sendEmail() {
    this.authService
      .sendVenueActivationEmail(this.data.id)
      .subscribe((data) => {
        console.log(data);
        this.dialogRef.close();
        console.log('email sent');
      });
  }
}
