import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { GoogleTagManagerService} from 'angular-google-tag-manager';
import { filter, takeUntil } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerImplService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService)
  {}

  public initialize() {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$)
    ).subscribe((event) => {
      const gtmTag = {
        event: 'page',
        pageName: event.urlAfterRedirects // use urlAfterRedirects for the final URL
      };
      this.gtmService.pushTag(gtmTag);
    });
  }


  // use gtag.js to send Google Analytics Events
  public event(action: string, eventCategory?: string, eventLabel?: string, value?: string) {
    // Constructing the event data object to send to GTM
    const eventData = {
        event: action, // Use 'event' key to specify the event action/type
        event_category: eventCategory,
        event_label: eventLabel,
        value: value
    };

    // Pushing the event data to GTM
    this.gtmService.pushTag(eventData);

    // Logging to the console for debugging purposes
    console.log('Sending Google Analytics tracking for: ', action);
    console.log('Event Category: ', eventCategory, 'Event Label: ', eventLabel, 'Value: ', value);
    console.log('Google Analytics property ID: ', environment.googleTagManagerId);
}


  public trackModalOpen(modalName: string, entityId?: number) {
    this.event('modal_open', 'Modal Interaction', modalName, entityId?.toString());
  }

  public trackScreenOpen(screenName: string, entityId?: number) {
    this.event('screen_open', 'Screen Navigation', screenName, entityId?.toString());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}
