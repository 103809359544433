import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Dj } from 'src/app/views/shared/models/dj';
import { environment } from 'src/environments/environment';

const DJ_API = environment.apiUrl + '/api/Djs/';

@Injectable({
  providedIn: 'root',
})
export class DJService {

  private djSubject = new BehaviorSubject<Dj | null>(null);

  public dj$ = this.djSubject.asObservable();


  constructor(private http: HttpClient) {}

  getDj(): Observable<Dj> {
    return this.http.get<Dj>(DJ_API + 'Get', { responseType: 'json' }).pipe(
      tap((dj) => {
        this.djSubject.next(dj);
      })
    );
  }


  updateDJ(dj: Dj): Observable<Dj> {
    return this.http.post<Dj>(DJ_API + 'UpdateDj', dj, { responseType: 'json' }).pipe(
      tap((updatedDj) => {
        this.djSubject.next(updatedDj);
      })
    );
  }

  UpdateProfileImage(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<string>(DJ_API+'UpdateProfileImage', formData, {
      responseType: 'text' as 'json',
    }).pipe(
      tap((profileImage) => {
        this.djSubject.next({...this.djSubject.value, base64ProfileImage: profileImage} as Dj);
      }
    ));
  }

  isDjLoaded(): boolean {
    return this.djSubject.value !== null;
  }


}
