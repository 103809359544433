import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { AuthService } from 'src/app/shared/auth.service';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { Teams } from '../../shared/models/teams';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { MiniPlayerService } from 'src/app/shared/services/mini-player.service';
import { Subject, takeUntil } from 'rxjs';
import { DJService } from 'src/app/shared/services/dj.service';
import { Dj } from '../../shared/models/dj';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[] = [];
  teams: any[] = [];
  showMiniPlayer: boolean = true;
  languages = [
    { code: 'us', name: 'English' },
    { code: 'gr', name: 'Ελληνικά' },
    // ... other languages
  ];
  selectedLanguage: any = this.languages[0];
  dj: Dj | null;

  /**
   * Fixed header menu on scroll
   */
  @HostListener('window:scroll', ['$event']) getScrollHeight() {
    if (window.matchMedia('(min-width: 992px)').matches) {
      let header: HTMLElement = document.querySelector(
        '.horizontal-menu'
      ) as HTMLElement;
      if (window.scrollY >= 60) {
        header.parentElement!.classList.add('fixed-on-scroll');
      } else {
        header.parentElement!.classList.remove('fixed-on-scroll');
      }
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private authService: AuthService,
    private teamsService: TeamsService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private miniPlayerService: MiniPlayerService,
    private djService: DJService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd || val instanceof NavigationStart) {
        this.showMiniPlayer =
          val.url.includes('dashboard') ||
          (val.url.includes('playlist') &&
            val.url.includes('playlists') == false) ||
          val.url === '/';
      }
    });
  }

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.menuItems = MENU;
    this.selectLanguage(this.selectedLanguage);
    if (this.teamsService.getLoadedTeams() === undefined) {
      this.teamsService
        .getTeams()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: Teams) => {
          this.teamsService.setLoadedTeams(data.teams);
          this.teams = data.teams;
        });
    } else {
      this.teams = this.teamsService.getLoadedTeams();
    }

    // This subscription will update `this.dj` whenever the DJ data changes.
    this.djService.dj$.pipe(takeUntil(this.destroy$)).subscribe((dj) => {
      this.dj = dj;
    });

    // Only call getDj() if the DJ hasn't been loaded yet.
    if (!this.djService.isDjLoaded()) {
      this.djService.getDj().pipe(takeUntil(this.destroy$)).subscribe();
    }

    // /**
    //  * closing the header menu after route change in tablet/mobile devices
    //  */
    // if (window.matchMedia('(max-width: 991px)').matches) {
    //   this.router.events.forEach((event) => {
    //     if (event instanceof NavigationEnd) {
    //       document
    //         .querySelector('.horizontal-menu .bottom-navbar')!
    //         .classList.remove('header-toggled');
    //     }
    //   });
    // }

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.miniPlayerService.stopPlayer();
      }
    });
  }

  selectLanguage(language: any) {
    this.selectedLanguage = language;
    this.languageService.changeLanguage(language.code);
    this.translateService.use(language.code);
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subMenus !== undefined ? item.subMenus.length > 0 : false;
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    e.preventDefault();

    this.authService
      .logout()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {});

    localStorage.removeItem('isLoggedin');

    if (!localStorage.getItem('isLoggedin')) {
      this.router.navigate(['/auth/login']);
    }
  }

  /**
   * Toggle header menu in tablet/mobile devices
   */
  toggleHeaderMenu() {
    document
      .querySelector('.horizontal-menu .bottom-navbar')!
      .classList.toggle('header-toggled');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
