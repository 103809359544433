import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import WaveSurfer from 'wavesurfer.js';
//import { Peaks } from 'wavesurfer.js/types/backend';
import { Song } from '../../models/song';

import { DatePipe } from '@angular/common';
import {
  MiniPlayerService,
  SongPlayingStatus,
} from 'src/app/shared/services/mini-player.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject, Subscription } from 'rxjs';
import { StorageService } from 'src/app/shared/storage.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import {
  IconDefinition,
  faPause,
  faPlay,
  faStop,
} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-mini-player',
  templateUrl: './mini-player.component.html',
  styleUrls: ['./mini-player.component.scss'],
})
export class MiniPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('waveform', { static: false }) waveformRef: ElementRef;

  private waveSurfer: WaveSurfer | null = null;
  currentTime: number;
  isPlaying: boolean = false;
  duration: number;
  playerError: string = '';
  // songdata: Peaks = [];
  region: any;
  song: Song | null = null;
  playerLoaded: boolean = false;
  alert1closed: boolean = false;
  peaks: any;
  canBeVisible: boolean = true;
  loading: boolean = true;
  stop = faStop;
  playIcon: IconDefinition = faPlay;
  pauseIcon: IconDefinition = faPause;
  private destroy$ = new Subject<void>();
  private routerSubscription: Subscription;
  constructor(
    private router: Router,
    private venueLibraryService: VenueLibraryService,
    private datePipe: DatePipe,
    private miniPlayerService: MiniPlayerService,
    private storageService: StorageService
  ) {
    this.routerSubscription = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd || val instanceof NavigationStart) {
        this.canBeVisible =
          val.url.includes('dashboard') ||
          (val.url.includes('playlist') &&
            val.url.includes('playlists') == false) ||
          val.url === '/';
        this.stopAudio();
      }
    });
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  initializePlayer() {
    this.waveSurfer = WaveSurfer.create({
      container: this.waveformRef.nativeElement,
      waveColor: 'white',
      progressColor: 'grey',
      cursorColor: 'yellow',
      // backend: 'MediaElement',
      height: 30,
      barHeight: 0.5,
      hideScrollbar: true,
      // xhr: {
      //   requestHeaders: [
      //     {
      //       key: 'Authorization',
      //       value: 'Bearer ' + this.storageService.getToken(),
      //     },
      //   ],
      // },
    });

    this.waveSurfer.setVolume(0.85);

    const audioStreamUrl = this.venueLibraryService.getAudioStreamUrl(
      this.song!.id
    );
    let songLoaded = true;
    // this.waveSurfer.on('error', (error) => {
    //   songLoaded = false;
    //   this.playerError = 'ERROR: Could not load song.';
    //   this.playerLoaded = false;
    //   this.waveSurfer!.destroy();
    //   this.waveSurfer = null;
    // });

    this.waveSurfer.on('ready', () => {
      if (this.playerError === '') {
        this.playerLoaded = true;
        if (!this.loading) this.playAudio();
      }
    });

    this.waveSurfer.on('audioprocess', () => {
      this.currentTime = this.waveSurfer!.getCurrentTime();
    });

    if (songLoaded) {
      this.waveSurfer.load(audioStreamUrl, this.peaks, this.song?.duration);
    }
  }

  handleLoadError(error: Error) {
    if (error.message) {
      this.playerError = error.message;
      this.alert1closed = false;
      console.error('Failed to load the audio:', error.message);
    }
  }

  closeAlert() {
    this.alert1closed = true;
    this.playerError = '';
  }

  toggleAudio() {
    if (this.isPlaying) {
      this.pauseAudio();
    } else if (this.song != null) {
      this.playAudio();
    }
  }

  playAudio() {
    if (this.song != null)
      this.miniPlayerService.playing.next(
        new SongPlayingStatus(this.song, true)
      );
    if (this.song != null) this.waveSurfer?.play()?.then(() => {});
    this.isPlaying = true;
  }

  pauseAudio() {
    this.waveSurfer!.pause();
    this.isPlaying = false;
    if (this.song != null)
      this.miniPlayerService.playing.next(
        new SongPlayingStatus(this.song, false)
      );
  }
  getIcon(): IconDefinition {
    return this.isPlaying ? this.pauseIcon : this.playIcon;
  }

  stopAudio() {
    if (this.waveSurfer) {
      this.waveSurfer!.stop();
      this.isPlaying = false;
      if (this.song != null)
        this.miniPlayerService.playing.next(
          new SongPlayingStatus(this.song, false)
        );
    }
  }

  formatTime(time: number): string {
    return this.datePipe.transform(time, 'mm:ss.SSS') ?? '';
  }
  ngOnInit(): void {
    this.miniPlayerService
      .songUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((song) => {
        if (song) {
          //if (this.song === song) return;

          this.song = song;
          this.venueLibraryService
            .getSongPeaks(this.song.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((peaks) => {
              this.peaks = peaks;

              if (this.waveSurfer === null) {
                this.playerError = '';
                this.initializePlayer();
              } else {
                this.waveSurfer!.load(
                  this.venueLibraryService.getAudioStreamUrl(this.song!.id),
                  this.peaks,
                  this.song?.duration
                );
                this.playAudio();
              }
            });
        }
      });

    this.miniPlayerService
      .stopPlayerUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.stopAudio();
        }
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.waveformRef.nativeElement.remove();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
