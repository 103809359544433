import { style } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import { MenuItem, TreeNode, TreeNodeDragEvent } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { Tree } from 'primeng/tree';
import { stringify } from 'querystring';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  last,
  of,
  skip,
  take,
  takeUntil,
} from 'rxjs';
import { SortableEvent } from 'sortablejs';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { MusicLibraryService } from 'src/app/shared/services/music-library.service';
import { PlaylistService } from 'src/app/shared/services/playlist.service';
import { SongDatabaseService } from 'src/app/shared/services/song-database.service';
import { TeamsService } from 'src/app/shared/services/teams.service';
import { VenueLibraryService } from 'src/app/shared/services/venue-library.service';
import { VenuesService } from 'src/app/shared/services/venues.service';
import { StorageService } from 'src/app/shared/storage.service';
import {
  DeleteSongResponse,
  SongUsedInPlayList,
} from 'src/app/views/shared/models/delete-song-response';
import { FolderHierarchy } from 'src/app/views/shared/models/folder-hierarchy';
import { Label } from 'src/app/views/shared/models/label';
import { Song } from 'src/app/views/shared/models/song';
import { Team } from 'src/app/views/shared/models/team';
import { Venue } from 'src/app/views/shared/models/venues';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import {
  faPen,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { dateSelectionJoinTransformer } from '@fullcalendar/core';
const Files_API = environment.apiUrl + '/files/';
class FilterLabel {
  constructor(public label: Label, public isSelected: boolean) {}
}
@Component({
  selector: 'app-library-view',
  templateUrl: './library-view.component.html',
  styleUrls: ['./library-view.component.scss'],
})
export class LibraryViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('treeContainer') treeContainer: ElementRef;
  @ViewChild('musicItemsTree') musicItemsTree: Tree;
  @ViewChild('op') overlayPanel: OverlayPanel;
  @ViewChild('folderTree') folderTree: Tree;
  @ViewChild('playlistTree') playlistTree: ElementRef;
  @ViewChild('ConfirmDeleteFolderModal')
  confirmDeleteFolderModal: TemplateRef<NgbModal>;
  @ViewChild('paginator') paginator: Paginator;
  @ViewChild('overlayInput') overlayInput: ElementRef;
  @Input() labels: Label[];
  @Input() libraryTree: TreeNode[] = [];
  @Input() menuItems: MenuItem[];
  @Input() folderContextMenuItems: MenuItem[];
  @Input() defaultTitle: string;
  @Input() showToggle: boolean;
  @Input() toggleLibrary: BehaviorSubject<boolean>;
  @Input() droppableNodes: boolean;
  @Input() draggableScope: string;
  @Input() droppableScope: string;
  @Input() hasSongListOption: boolean;
  @Input() hasSongRemoveOption: boolean;
  @Input() hasFilters: boolean;
  @Input() hasMemberFilter: boolean;
  @Input() hasUsedSongsFilter: boolean;
  @Input() type: string;

  @Output() ToggleDatabase = new EventEmitter<boolean>();
  scrollInterval: any;
  selectedColor: string;
  selectedNode: TreeNode;
  loading = true;
  songsLoading: boolean;
  subscriptionsCleanUp: Subscription[] = [];
  expandedVenueNodeKeys: Set<string> = new Set();
  expandedSongDBNodeKeys: Set<string> = new Set();
  filterLabels: FilterLabel[];
  filterUsedSongsInPlaylist: boolean = false;
  filterUsedSongsLabel: string = 'Hide Used';
  orderedFilterLabels: FilterLabel[] = [];
  private destroy$ = new Subject<void>();
  folderItemsCount: any;
  folderPageCount: number;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  folderItemNodes: any;
  pageSize: any = 50;
  currentPage: any = 1;
  showUpload: boolean;
  faPen = faPen;
  uppy: Uppy = new Uppy({
    debug: true,
    autoProceed: true,
    restrictions: {
      allowedFileTypes: ['.mp3'],
    },
  });
  folderActionPlaceholder: string;
  folderName: string;
  folderAction: string;
  dashboardProps = {
    plugins: ['Webcam'],
    hideProgressAfterFinish: true,
  };
  venues: Venue[];
  staticSongList: any[] = [];
  filterForm = new FormGroup({
    searchString: new FormControl(''),
    orderBy: new FormControl('Artist'),
    tags: new FormControl([]),
  });

  isSongDatabase: boolean = false;
  isPlaylist: boolean = false;
  isCustomerLibrary: boolean = false;

  usedInPlaylists: SongUsedInPlayList[];
  currentVenue: any;
  libraryService: MusicLibraryService | VenueLibraryService;

  constructor(
    private storageService: StorageService,
    private teamService: TeamsService,
    private songDatabaseService: SongDatabaseService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private venuesService: VenuesService,
    private dashboardService: DashboardService,
    private playlistService: PlaylistService,
    private modalService: NgbModal,
    private router: Router,
    private musicLibraryService: MusicLibraryService,
    private venueLibraryService: VenueLibraryService
  ) {
    window.addEventListener('beforeunload', this.onBeforeUnload.bind(this));

    // create on navigate to handler
    const s = this.playlistService
      .currentVenueEvents$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((venue) => {
        this.currentVenue = venue;
        const tagIds = this.orderedFilterLabels
          .filter((f) => f.isSelected)
          .map((s) => s.label.id);

        let orderBy = this.filterForm.value.orderBy;
        let desc = false;
        if ((this.filterForm.value.orderBy as string).includes('_desc')) {
          orderBy = this.filterForm.value.orderBy?.replace('_desc', '');
          desc = true;
        }
        // if (venue && venue != 0) {
        //   this.populateSongsInFolder(
        //     this.selectedNode.data,
        //     this.currentVenue, //VenueId, When first Initializing, the Venue
        //     this.filterForm.value.searchString!,
        //     this.filterUsedSongsInPlaylist, // Filter Songs Based on Venue Selected
        //     1,
        //     this.pageSize,
        //     orderBy ?? 'Artist',
        //     desc,
        //     tagIds,
        //     'top'
        //   );
        // }
      });
    this.subscriptionsCleanUp.push(s);
  }
  ngOnDestroy() {
    this.cleanup();
    window.removeEventListener('beforeunload', this.onBeforeUnload.bind(this));
  }
  private onBeforeUnload(event: BeforeUnloadEvent): void {
    // Cleanup for browser refresh
    this.cleanup();
  }

  private cleanup(): void {
    // Perform cleanup operations
    this.treeContainer?.nativeElement.remove();
    this.playlistTree?.nativeElement.remove();
    for (let i = 0; i < this.subscriptionsCleanUp.length; i++) {
      this.subscriptionsCleanUp[i].unsubscribe();
      console.log('unsubscribing from ' + i);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  editLabels() {
    this.router.navigate(['/labels']);
  }
  scrollContainerRight() {
    const container = document.getElementById('scrollContainer');
    if (container) {
      console.log('Right 25');
      container.scrollLeft += 25; // Adjust the scroll amount as needed
    }
  }
  scrollContainerLeft() {
    const container = document.getElementById('scrollContainer');
    if (container) {
      console.log('Left 25');
      container.scrollLeft -= 25; // Adjust the scroll amount as needed
    }
  }

  startScroll(direction: string) {
    const container = document.getElementById('scrollContainer');
    if (container) {
      const scrollAmount = 25; // Adjust the scroll amount as needed
      this.scrollInterval = setInterval(() => {
        if (direction === 'right') {
          container.scrollLeft += scrollAmount;
        } else if (direction === 'left') {
          container.scrollLeft -= scrollAmount;
        }
      }, 100); // Adjust the interval as needed
    }
  }

  stopScroll() {
    clearInterval(this.scrollInterval);
  }

  changeView() {
    console.log(`changing to ${!this.toggleLibrary.value}`);
    this.toggleLibrary.next(!this.toggleLibrary.value);
  }

  ngOnInit(): void {
    // go one by one to the subscriptionsCleanUp array and unsubscribe from each synchronous observable and then clear the array
    // do it with for not foreach because foreach will not work with unsubscribe

    for (let i = 0; i < this.subscriptionsCleanUp.length; i++) {
      this.subscriptionsCleanUp[i].unsubscribe();
      console.log('unsubscribing from ' + i);
    }
    this.subscriptionsCleanUp = [];

    this.initializeUppy();
    this.libraryService =
      this.defaultTitle === 'songDatabase'
        ? this.musicLibraryService
        : this.venueLibraryService;
    const s = this.venuesService
      .storedVenuesSubscription()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {});
    this.subscriptionsCleanUp.push(s);

    this.subscribeToFolders();
    if (!this.menuItems) return;

    this.menuItems[0].command = (event) => this.addFolderClicked(event);
    this.menuItems[1].command = (event) => this.renameFolderClicked(event);
    this.menuItems[2].command = (event) => this.deleteFolderClicked(event);
    if (this.menuItems[3])
      this.menuItems[3].command = () => this.uploadClicked();
    const s1 = this.toggleLibrary
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe((v) => {
        this.defaultTitle = !v ? 'customerLibrary' : 'songDatabase';
        console.log(
          `calling change toogleLibrary to ${this.toggleLibrary.value}`
        );

        this.libraryService = v
          ? this.musicLibraryService
          : this.venueLibraryService;
        this.isPlaylist = this.router.url === '/playlist';

        if (v) this.getLibrary();
        else if (!v && this.hasFilters)
          this.getCustomerLibrary(
            this.teamService.getSelectedTeam(),
            this.venueLibraryService
          );
        this.languageService.currentLanguage
          .pipe(takeUntil(this.destroy$))
          .subscribe((language) => {
            this.translateService.use(language);
          });

        this.dashboardService.changeFolderEvents$
          .pipe(takeUntil(this.destroy$))
          .subscribe((folderId) => {
            if (
              folderId !== undefined &&
              folderId !== null &&
              folderId !== ''
            ) {
              if (this.defaultTitle === 'songDatabase') {
                this.selectedNode = this.findNodeById(
                  this.libraryTree,
                  folderId
                )!;
              }
            }
            if (!this.selectedNode) {
              this.selectedNode = this.libraryTree[0];
            }
            setTimeout(() => {
              if (this.libraryService instanceof VenueLibraryService)
                this.restoreExpandedNodes(
                  this.libraryTree,
                  this.expandedVenueNodeKeys
                );
              else
                this.restoreExpandedNodes(
                  this.libraryTree,
                  this.expandedSongDBNodeKeys
                );
              this.folderSelected(null);
            }, 500);
          });
      });
    this.subscriptionsCleanUp.push(s1);

    if (this.isPlaylist) this.toggleLibrary.next(false);
    setTimeout(() => {
      const customerName = this.playlistService.currentCustomer.getValue();
      if (!customerName) return;
      this.selectedNode = this.libraryTree.find(
        (c) => c.label === customerName
      )!;

      if (this.selectedNode) {
        this.libraryTree = this.libraryTree.filter(
          (c) => c.label !== customerName
        );

        // Add the selectedNode to the beginning of the array
        this.libraryTree.unshift(this.selectedNode);
      }
      if (!this.selectedNode) {
        this.selectedNode = this.libraryTree[0];
      }
      this.folderSelected(null);
    }, 100);
    this.subscribeToRemoveSongFromMusicTreeEvent();
    this.filterForm.valueChanges
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((data: any) => {
        const tagIds = this.orderedFilterLabels
          .filter((f) => f.isSelected)
          .map((s) => s.label.id);

        let orderBy = this.filterForm.value.orderBy;
        let desc = false;
        if ((this.filterForm.value.orderBy as string).includes('_desc')) {
          orderBy = this.filterForm.value.orderBy?.replace('_desc', '');
          desc = true;
        }
        let folderId = this.selectedNode.data;
        this.populateSongsInFolder(
          folderId,
          this.currentVenue, //VenueId, When first Initializing, the Venue
          this.filterForm.value.searchString!,
          this.filterUsedSongsInPlaylist, // Filter Songs Based on Venue Selected
          1,
          this.pageSize,
          orderBy ?? 'Artist',
          desc,
          tagIds,
          'top'
        );
        setTimeout(() => this.paginator?.changePage(0));
      });
    if (this.libraryService instanceof MusicLibraryService) {
      this.isSongDatabase = true;
      this.isCustomerLibrary = false;
    }

    if (this.libraryService instanceof VenueLibraryService) {
      this.filterForm.value.orderBy = 'Artist';
      this.isCustomerLibrary = true;
      this.isSongDatabase = false;
    }
  }

  subscribeToFolders() {
    const s1 = this.venueLibraryService
      .getFoldersForTeam()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data.length === 0) {
          return;
        }
        if (this.defaultTitle === 'customerLibrary') {
          if (this.selectedNode === null || this.selectedNode === undefined)
            this.selectedNode = this.libraryTree[0];
          this.getLibrary();
          const serializedData = sessionStorage.getItem(
            'expandedVenueNodeKeys'
          );
          if (serializedData) {
            const keys = JSON.parse(serializedData);
            this.expandedVenueNodeKeys = new Set(keys);
          }
          this.restoreExpandedNodes(
            this.libraryTree,
            this.expandedVenueNodeKeys
          );
          data.forEach((element) => {
            this.mapNodes(element.folders);
          });

          const tagIds = this.orderedFilterLabels
            .filter((f) => f.isSelected)
            .map((s) => s.label.id);

          let orderBy = this.filterForm.value.orderBy;
          let desc = false;
          if ((this.filterForm.value.orderBy as string).includes('_desc')) {
            orderBy = this.filterForm.value.orderBy?.replace('_desc', '');
            desc = true;
          }
        }
      });

    const s2 = this.musicLibraryService
      .getFolders()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!data || !data.children || data.children.length === 0) {
          //return;
        }
        if (this.defaultTitle === 'songDatabase') {
          this.getLibrary();
          const serializedData = sessionStorage.getItem(
            'expandedSongDBNodeKeys'
          );
          if (serializedData) {
            const keys = JSON.parse(serializedData);
            this.expandedSongDBNodeKeys = new Set(keys);
          }
          this.restoreExpandedNodes(
            this.libraryTree,
            this.expandedSongDBNodeKeys
          );
          this.mapNodes(data.children);
          if (this.selectedNode === null || this.selectedNode === undefined)
            this.selectedNode = this.libraryTree[0];
          const tagIds = this.orderedFilterLabels
            .filter((f) => f.isSelected)
            .map((s) => s.label.id);

          let orderBy = this.filterForm.value.orderBy;
          let desc = false;
          if ((this.filterForm.value.orderBy as string).includes('_desc')) {
            orderBy = this.filterForm.value.orderBy?.replace('_desc', '');
            desc = true;
          }
          if (!this.selectedNode) this.selectedNode = this.libraryTree[0];
          // this.populateSongsInFolder(
          //   this.selectedNode.data,
          //   this.currentVenue, //VenueId, When first Initializing, the Venue
          //   this.filterForm.value.searchString!,
          //   this.filterUsedSongsInPlaylist, // Filter Songs Based on Venue Selected
          //   1,
          //   this.pageSize,
          //   orderBy ?? 'Artist',
          //   desc,
          //   tagIds,
          //   'top'
          // );
        }
      });
    const s3 = this.venueLibraryService
      .getFolders()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (this.defaultTitle === 'customerLibrary') {
          if (this.selectedNode === null || this.selectedNode === undefined)
            this.selectedNode = this.libraryTree[0];
          this.getLibrary();
          const serializedData = sessionStorage.getItem(
            'expandedVenueNodeKeys'
          );
          if (serializedData) {
            const keys = JSON.parse(serializedData);
            this.expandedVenueNodeKeys = new Set(keys);
          }
          this.restoreExpandedNodes(
            this.libraryTree,
            this.expandedVenueNodeKeys
          );
          this.mapNodes(data.children);
        }
      });
    this.subscriptionsCleanUp.push(s1);
    this.subscriptionsCleanUp.push(s2);
    this.subscriptionsCleanUp.push(s3);
  }
  selectFilter(filter: FilterLabel) {
    filter.isSelected = !filter.isSelected;
    if (filter.isSelected) this.orderedFilterLabels.push(filter);
    else {
      const index = this.orderedFilterLabels.indexOf(filter, 0);
      if (index > -1) {
        this.orderedFilterLabels.splice(index, 1);
      }
    }
    const tagIds = this.orderedFilterLabels
      .filter((f) => f.isSelected)
      .map((r) => r.label.id);

    let desc = false;

    this.populateSongsInFolder(
      this.selectedNode.data,
      this.currentVenue, //VenueId, When first Initializing, the Venue
      this.filterForm.value.searchString!,
      this.filterUsedSongsInPlaylist, // Filter Songs Based on Venue Selected
      1,
      this.pageSize,
      '',
      desc,
      tagIds,
      'top'
    );
  }

  nodeExpanded(event: any) {
    if (this.libraryService instanceof VenueLibraryService) {
      this.saveExpandedVenueNodes(this.libraryTree);
    } else {
      this.saveExpandedSongDBNodes(this.libraryTree);
    }
  }

  nodeCollapsed(event: any) {
    if (this.libraryService instanceof VenueLibraryService) {
      this.saveExpandedVenueNodes(this.libraryTree);
    } else {
      this.saveExpandedSongDBNodes(this.libraryTree);
    }
  }

  filterUsedSongs(event$: any) {
    this.filterUsedSongsInPlaylist = !this.filterUsedSongsInPlaylist;
    this.filterUsedSongsLabel = this.filterUsedSongsInPlaylist
      ? 'Unhide Used'
      : 'Hide Used';
    const tagIds = this.orderedFilterLabels
      .filter((f) => f.isSelected)
      .map((r) => r.label.id);

    let desc = false;

    this.populateSongsInFolder(
      this.selectedNode.data,
      this.currentVenue, //VenueId, When first Initializing, the Venue
      this.filterForm.value.searchString!,
      this.filterUsedSongsInPlaylist, // Filter Songs Based on Venue Selected
      1,
      this.pageSize,
      '',
      desc,
      tagIds,
      'top'
    );
  }

  private subscribeToRemoveSongFromMusicTreeEvent() {
    this.dashboardService.removeItemFromTree$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (this.folderItemNodes !== undefined && event !== null) {
          if (event !== null) {
            if (this.libraryService instanceof VenueLibraryService) {
              this.folderItemNodes = this.folderItemNodes.filter(
                (item) => item.data.id !== event.song.id
              );
              this.removeSongFromVenueFolder(event.song.id);
            }
            this.modalService.dismissAll();
          }
        }
      });
  }

  toggleDatabase() {
    this.ToggleDatabase.emit(true);
  }

  ngAfterViewInit() {
    const s1 = this.folderTree.dragDropService.dragStop$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        //if (this.defaultTitle === 'songDatabase') {  // Filtering and re-population should happen for both Customer and Song Database

        if (data.subNodes) {
          let orderBy = this.filterForm.value.orderBy;
          let desc = false;
          if ((this.filterForm.value.orderBy as string).includes('_desc')) {
            orderBy = this.filterForm.value.orderBy?.replace('_desc', '');
            desc = true;
          }

          const tagIds = this.orderedFilterLabels
            .filter((f) => f.isSelected)
            .map((s) => s.label.id);
          this.populateSongsInFolder(
            this.selectedNode.data,
            this.currentVenue, //VenueId, When first Initializing, the Venue
            this.filterForm.value.searchString!,
            this.filterUsedSongsInPlaylist, // Filter Songs Based on Venue Selected
            this.currentPage,
            this.pageSize,
            orderBy ?? 'Artist',
            desc,
            tagIds,
            undefined,
            true
          );
        }
        // }
        this.addToCustomerFolder(data);
      });
    this.subscriptionsCleanUp.push(s1);
  }

  nodeDropped(event: TreeNodeDragEvent) {
    this.addToCustomerFolder(event);
  }
  addToCustomerFolder(event) {
    if (event.dropNode && event.dropNode.parent === undefined) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        title: `Cannot drop to a root folder.`,
        icon: 'error',
        iconColor: 'black',
        background: '#EF5350CC',
      });
      this.filterMusicItemNodes(event);

      return;
    }

    if (event.dropNode) {
      this.filterMusicItemNodes(event);
      this.folderItemNodes = Array.from(this.staticSongList);
      this.selectedNode = event.dropNode;
      if (this.folderItemNodes.indexOf(event.dragNode) != -1) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          title: `${event.dragNode.data.artist} - ${event.dragNode.data.title}, already added to customer folder ${this.selectedNode.label}.`,
          icon: 'error',
          iconColor: 'black',
          background: '#EF5350CC',
        });
      }

      const team = this.teamService.getSelectedTeam();
      if (this.libraryService instanceof VenueLibraryService) {
        this.addToCustomerLibrary(event, team, this.libraryService);
      }
      this.folderSelected(null);
    }
  }

  private filterMusicItemNodes(event: any) {
    event.dropNode.children = event.dropNode?.children.filter((node) => {
      return node.type != 'musicItem';
    });
  }

  addToCustomerLibrary(
    event: any,
    team: Team,
    libraryService: VenueLibraryService
  ) {
    libraryService
      .addSongToCustomerLibrary(
        event.dropNode.data,
        event.dragNode.data.id,
        team.id,
        1
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          let showTime = 5000;
          let extraText = '';
          let notificationText = `${event.dragNode.data.artist} - ${event.dragNode.data.title}, added to customer folder ${this.selectedNode.label}.`;
          let color = '#21f367CC';
          if (result && result.length > 0) {
            color = '#FFEE58CC';
            extraText = `\nAlso exists in folder${
              result.length > 1 ? 's' : ''
            }: ${result.join(', ')}`;
            showTime = 10000;
          }
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: showTime,
            timerProgressBar: true,
            title: notificationText,
            html: '<span style="color: gray">' + extraText + ' </span>',
            icon: 'success',
            iconColor: 'black',
            background: color,
          });
          this.venueLibraryService.downloadFoldersForTeam(team!.id);
          this.getLibrary();
        },
        (error) => {
          //console.log(
          //  (error.error as string).includes('Song already exists in folder')
          //);
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            title: `${event.dragNode.data.artist} - ${event.dragNode.data.title}, already added to customer folder ${this.selectedNode.label}.`,
            icon: 'warning',
            iconColor: 'black',
            background: '#EF5350CC',
          });
        }
      );
  }

  private initializeUppy() {
    const self = this;
    this.uppy.use(Tus, {
      endpoint: Files_API,
      onBeforeRequest: async (req) => {
        return req.setHeader(
          'Authorization',
          `Bearer ` + this.storageService.getToken()
        );
      },
      onShouldRetry(err, retryAttempt, options, next) {
        if (err?.message.includes('401') || retryAttempt <= 5) {
          return true;
        }

        return next(err);
      },
      onAfterResponse(req, res) {
        //console.log('onAfterResponse');
        if (res.getStatus() === 401) {
          //console.log('onAftersponse. 401 error');
          return req.setHeader(
            'Authorization',
            `Bearer ` + self.storageService.getToken()
          );
        }
      },
    });

    this.uppy.on('file-added', (file) => {
      this.uppy.setFileMeta(file.id, {
        folderId: this.selectedNode.data,
      });
      const team = this.teamService.getSelectedTeam();
      this.uppy.setFileMeta(file.id, { teamId: team.id });
    });

    this.uppy.on('upload-error', (file, error) => {
      if (error.message.includes('Duplicate')) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          title: `${file?.name} already exists in the folder.`,
          icon: 'warning',
          iconColor: 'black',
          background: '#EF5350CC',
        });
      }
    });
  }

  // private subscribeToTeamUpdates() {
  //   this.teamService
  //     .getSelectedTeamUpdates()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(() => {});
  //   const team = this.teamService.getSelectedTeam();

  //   this.venuesService
  //     .getVenues(team.id)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((data) => {
  //       this.venues = data;
  //     });
  // }

  pageSongs(event) {
    console.log('pageSongs');
    let orderBy = this.filterForm.value.orderBy;
    let desc = false;
    if ((this.filterForm.value.orderBy as string).includes('_desc')) {
      orderBy = this.filterForm.value.orderBy?.replace('_desc', '');
      desc = true;
    }

    this.currentPage = event.page;
    const nextPage = event.page + 1;
    const tagIds = this.orderedFilterLabels
      .filter((f) => f.isSelected)
      .map((s) => s.label.id);

    this.populateSongsInFolder(
      this.selectedNode.data,
      this.currentVenue, // VenueId
      this.filterForm.value.searchString!,
      this.filterUsedSongsInPlaylist, // Filter Songs based on Venue for Playlist
      nextPage,
      50,
      orderBy ?? 'Artist',
      desc,
      tagIds,
      'top'
    );
    this.currentPage = nextPage;
  }

  exitUpload() {
    this.showUpload = false;
    this.uppy.cancelAll();
    const team = this.teamService.getSelectedTeam();
    const s1 = this.venueLibraryService
      .downloadFoldersForTeam$(team!.id)
      .subscribe((d) => {
        this.pageSongs({ page: 0 });
        this.musicLibraryService.downloadFolders(
          this.teamService.getSelectedTeam()!.id
        );
        // this.dashboardService.changeFolder(this.selectedNode.data);
      });
    this.subscriptionsCleanUp.push(s1);
  }

  folderActionClicked() {
    if (this.folderAction === 'Add') {
      this.addFolder();
    } else if (this.folderAction === 'Rename') {
      this.renameFolder();
    }
    if (
      this.libraryService instanceof MusicLibraryService ||
      this.libraryService instanceof VenueLibraryService
    ) {
      const team = this.teamService.getSelectedTeam();
      if (this.libraryService instanceof VenueLibraryService)
        this.venueLibraryService.downloadFoldersForTeam(team!.id);
      else {
        this.musicLibraryService.downloadFolders(team!.id);
      }
    }
  }

  folderSelected(event) {
    let orderBy = this.filterForm.value.orderBy;
    let desc = false;
    if ((this.filterForm.value.orderBy as string).includes('_desc')) {
      orderBy = this.filterForm.value.orderBy?.replace('_desc', '');
      desc = true;
    }
    const tagIds = this.orderedFilterLabels
      .filter((f) => f.isSelected)
      .map((s) => s.label.id);
    if (this.libraryTree.length === 0) return;
    if (!this.selectedNode) {
      this.selectedNode = this.libraryTree[0];
    }
    this.playlistService
      .currentVenueEvents$()
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((venue) => {
        this.currentVenue = venue;
        this.populateSongsInFolder(
          this.selectedNode.data,
          this.currentVenue, // VenueId
          this.filterForm.value.searchString!,
          this.filterUsedSongsInPlaylist, // Filter Songs based on Venue for Playlist
          1, // If Changing folders, page is always 1
          50,
          orderBy ?? 'Artist',
          desc,
          tagIds,
          'top',
          true
        );
      });
    setTimeout(() => this.paginator?.changePage(0));
  }

  contextMenuSelected(event) {
    if (event.node.parent === undefined) {
      this.folderContextMenuItems = this.menuItems.filter((item) => {
        return item.label === 'New Folder';
      });
    } else if (this.defaultTitle === 'customerLibrary') {
      this.folderContextMenuItems = this.menuItems.filter(
        (item) => item.label !== 'Upload'
      );
    } else {
      this.folderContextMenuItems = this.menuItems;
    }
  }

  addFolderClicked(event: any) {
    this.folderActionPlaceholder = 'New Folder Name';
    this.folderAction = 'Add';
    this.folderName = '';
    this.overlayPanel.toggle(event);
    this.setFolderOverlay(event);
    setTimeout(() => this.overlayInput.nativeElement.focus(), 200);
  }

  renameFolderClicked(event: any) {
    this.folderActionPlaceholder = this.selectedNode.label
      ? this.selectedNode.label
      : '';
    this.folderName = this.selectedNode.label ? this.selectedNode.label : '';
    this.folderAction = 'Rename';
    this.overlayPanel.toggle(event);
    this.setFolderOverlay(event, 50);
    setTimeout(() => this.overlayInput.nativeElement.focus(), 200);
  }

  deleteFolderClicked(event: any) {
    //show the modal
    this.modalService.open(this.confirmDeleteFolderModal, {
      ariaLabelledBy: 'Delete Folder Confirmation',
      backdrop: 'static',
      keyboard: false,
    });
  }

  deleteFolderConfirmed(content: TemplateRef<NgbModal>, event: any) {
    this.modalService.dismissAll();
    if (
      this.libraryService instanceof MusicLibraryService ||
      this.libraryService instanceof VenueLibraryService
    ) {
      this.libraryService
        .deleteFolder(this.selectedNode.data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.processDeleteFolderResult();
            const team = this.teamService.getSelectedTeam();
            if (this.libraryService instanceof VenueLibraryService)
              this.venueLibraryService.downloadFoldersForTeam(team!.id);
            else this.musicLibraryService.downloadFolders(team!.id);
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              title: 'Folder has been successfully deleted',
              icon: 'info',
              iconColor: 'black',
              background: '#21f367CC',
            });
          },
          (error) => {
            const errorMessage = error.error.substring(
              error.error.indexOf(':') + 1,
              error.error.indexOf('\n')
            );
            this.getLibrary();
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              title: errorMessage,
              icon: 'error',
              iconColor: 'black',
              background: '#EF5350CC',
            });
          }
        );
    }
  }

  processDeleteFolderResult() {
    const parentNode = this.selectedNode.parent;
    parentNode?.children?.splice(
      parentNode.children.indexOf(this.selectedNode),
      1
    );
  }

  uploadClicked() {
    this.showUpload = true;
  }

  private setFolderOverlay(event: any, menuOffset: number = 0) {
    this.overlayPanel.onShow.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const contextMenu = document.querySelector(
        '.song-library-overlay'
      ) as HTMLElement;
      contextMenu.style.left = event.originalEvent.x + 'px';
      contextMenu.style.top = event.originalEvent.y - menuOffset + 'px';
    });
  }

  nodeActionKeyboardEvent(event: KeyboardEvent) {
    if (event.key !== 'Enter') return;

    if (this.folderAction === 'Add') {
      this.addFolder();
    } else if (this.folderAction === 'Rename') {
      this.renameFolder();
    }
    if (
      this.libraryService instanceof MusicLibraryService ||
      this.libraryService instanceof VenueLibraryService
    ) {
      const team = this.teamService.getSelectedTeam();
      if (this.libraryService instanceof VenueLibraryService)
        this.venueLibraryService.downloadFoldersForTeam(team!.id);
      else {
        this.musicLibraryService.downloadFolders(team!.id);
      }
    }
  }

  addFolder() {
    const team = this.teamService.getSelectedTeam();
    this.libraryService
      .createFolder(team.id, this.selectedNode.data, this.folderName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        //this.venueLibraryService.downloadFoldersForTeam(team!.id);
        if (this.libraryService instanceof VenueLibraryService)
          this.venueLibraryService.downloadFoldersForTeam(team!.id);
        else this.musicLibraryService.downloadFolders(team!.id);

        // this.getLibrary();
      });

    this.selectedNode.expanded = true;
    this.folderName = '';
    this.overlayPanel.toggle(null);
  }

  renameFolder() {
    this.libraryService
      .renameFolder(this.selectedNode.data, this.folderName)
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          // Handle the error here
          console.error('An error occurred:', error);
          // Return an empty observable or handle it as needed
          return of();
        })
      )
      .subscribe(() => {
        const team = this.teamService.getSelectedTeam();
        if (this.defaultTitle === 'customerLibrary') {
          this.venueLibraryService.downloadFoldersForTeam(team!.id);
        } else {
          this.musicLibraryService.downloadFolders(team!.id);
        }
        // this.getLibrary();
      });

    this.overlayPanel.toggle(null);
  }

  getLibrary() {
    this.loading = true;

    const team = this.teamService.getSelectedTeam();
    if (team === undefined) return;

    if (this.libraryService instanceof MusicLibraryService) {
      this.getMusicLibrary(team, this.libraryService);
    } else if (this.libraryService instanceof VenueLibraryService) {
      this.getCustomerLibrary(team, this.libraryService);
    }
  }
  getDataSubscription: Subscription;

  private getLibraryData(
    team: Team | undefined,
    libraryService: VenueLibraryService | MusicLibraryService,
    processLibraryData: (data: any) => void
  ) {
    team = this.teamService.getSelectedTeam();

    this.loading = true;
    const method =
      libraryService instanceof VenueLibraryService
        ? 'getFoldersForTeam'
        : 'getFolders';
    if (
      !team ||
      libraryService[method](team) === undefined ||
      libraryService[method](team!) === undefined
    )
      return;
    libraryService[method](team!.id)
      .pipe(take(1))
      .subscribe((data) => {
        if (data === null || data === undefined) return;
        processLibraryData(data);
        this.loading = false;
        if (this.libraryService instanceof VenueLibraryService)
          this.restoreExpandedNodes(
            this.libraryTree,
            this.expandedVenueNodeKeys
          );
        else
          this.restoreExpandedNodes(
            this.libraryTree,
            this.expandedSongDBNodeKeys
          );
        if (this.selectedNode === null || this.selectedNode === undefined) {
          this.selectedNode = this.libraryTree[0];
        } else {
          const matchedNode = this.findNodeById(
            this.libraryTree,
            this.selectedNode.data
          );

          if (matchedNode !== null && matchedNode !== undefined) {
            this.selectedNode = this.findNodeById(
              this.libraryTree,
              this.selectedNode.data
            )!;
          } else {
            this.selectedNode = this.libraryTree[0];
          }
        }
        if (this.selectedNode?.data) {
          let orderBy = this.filterForm.value.orderBy;
          let desc = false;
          if ((this.filterForm.value.orderBy as string).includes('_desc')) {
            orderBy = this.filterForm.value.orderBy?.replace('_desc', '');
            desc = true;
          }
          const tagIds = this.orderedFilterLabels
            .filter((f) => f.isSelected)
            .map((s) => s.label.id);

          this.populateSongsInFolder(
            this.selectedNode.data,
            this.currentVenue, // VenueId
            this.filterForm.value.searchString!,
            this.filterUsedSongsInPlaylist, // Filter Songs based on Venue for Playlist
            this.currentPage,
            this.pageSize,
            orderBy!,
            desc,
            tagIds,
            'top',
            true
          );
        }
      });
  }

  private getCustomerLibrary(
    team: Team | undefined,
    libraryService: VenueLibraryService
  ) {
    this.getLibraryData(team, libraryService, (data) => {
      const venueRootFolders: any = [];
      const customerDatabase: any = [];

      data.forEach((item) => {
        item.folders.forEach((folder) => {
          customerDatabase.push(folder);
        });

        const entry = {
          nodeId: item.folders[0].id,
          venueId: item.venueId,
        };
        venueRootFolders.push(entry);
      });

      this.libraryTree = this.mapNodes(customerDatabase);

      this.libraryTree.forEach((node) => {
        node.expandedIcon = 'pi pi-user';
        node.collapsedIcon = 'pi pi-user';
      });
    });
  }

  private getMusicLibrary(
    team: Team | undefined,
    libraryService: MusicLibraryService
  ) {
    this.getLibraryData(team, libraryService, (data) => {
      const songFoldersDatabase: FolderHierarchy[] = [];
      songFoldersDatabase.push(data);

      let expandRoot = false;
      if (this.libraryTree.length === 0) {
        expandRoot = true;
      }

      this.libraryTree = this.mapNodes(songFoldersDatabase);
      this.songDatabaseService.songTree = this.libraryTree;
      let hasExpanded = this.libraryTree.filter((n) => n.expanded).length !== 0;
      if (expandRoot || !hasExpanded) {
        this.libraryTree[0].expanded = true;
      }
    });
  }

  private mapNodes(node: FolderHierarchy[]): TreeNode[] {
    return node.map((item) => {
      return {
        label: item.label,
        data: item.id,
        songsCount: item.songsCount,
        expandedIcon: 'pi pi-folder-open',
        collapsedIcon: 'pi pi-folder',
        children: this.mapNodes(item.children),
      };
    });
  }

  private restoreExpandedNodes(nodes: TreeNode[], keys: Set<string>): void {
    nodes.forEach((node) => {
      if (keys.has(node.data)) {
        node.expanded = true;
        if (node.children) {
          this.restoreExpandedNodes(node.children, keys);
        }
      }
    });
  }

  expandRoot() {
    const node = this.findTopLevelNode(this.libraryTree, this.selectedNode);
    node!.expanded = true;
  }

  expandAll() {
    this.libraryTree.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  expandRecursive(node: TreeNode<any>, arg1: boolean) {
    node.expanded = arg1;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, arg1);
      });
    }
  }

  findTopLevelNode(
    treeNodes: TreeNode[],
    targetChildNode: TreeNode
  ): TreeNode | null {
    if (targetChildNode?.parent === undefined) {
      return targetChildNode;
    }
    let parentNode = this.findRootNode(treeNodes, targetChildNode);
    if (!parentNode) {
      return null;
    }

    while (parentNode) {
      const grandparentNode = this.findRootNode(treeNodes, parentNode);
      if (!grandparentNode) {
        return parentNode;
      }
      parentNode = grandparentNode;
    }

    return null;
  }

  findRootNode(
    treeNodes: TreeNode[],
    targetChildNode: TreeNode
  ): TreeNode | null {
    for (const node of treeNodes) {
      if (node.children) {
        if (node.children.includes(targetChildNode)) {
          return node;
        }

        const parentNode = this.findRootNode(node.children, targetChildNode);
        if (parentNode) {
          return parentNode;
        }
      }
    }

    return null;
  }

  songUpdated(event) {
    this.getLibrary();
    return;
  }
  private findNodeById(treeNodes: TreeNode[], idToFind: any): TreeNode | null {
    for (const node of treeNodes) {
      if (node.data === idToFind) {
        return node;
      }

      if (node.children) {
        const result = this.findNodeById(node.children, idToFind);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }
  private saveExpandedVenueNodes(nodes: TreeNode[]): void {
    nodes.forEach((node) => {
      if (node.expanded) {
        this.expandedVenueNodeKeys.add(node.data);

        if (node.children) {
          this.saveExpandedVenueNodes(node.children);
        }
      } else {
        this.expandedVenueNodeKeys.delete(node.data);
      }
    });
    const serializedData = JSON.stringify(
      Array.from(this.expandedVenueNodeKeys)
    );

    // Save the serialized data in session storage
    sessionStorage.setItem('expandedVenueNodeKeys', serializedData);
  }
  private saveExpandedSongDBNodes(nodes: TreeNode[]): void {
    nodes.forEach((node) => {
      if (node.expanded) {
        this.expandedSongDBNodeKeys.add(node.data);
        if (node.children) {
          this.saveExpandedSongDBNodes(node.children);
        }
      } else {
        this.expandedSongDBNodeKeys.delete(node.data);
      }
    });
    const serializedData = JSON.stringify(
      Array.from(this.expandedSongDBNodeKeys)
    );

    // Save the serialized data in session storage
    sessionStorage.setItem('expandedSongDBNodeKeys', serializedData);
  }
  lastParametersToPopulate: string = '';
  cancelFolderLoad$ = new Subject();
  populateSongsInFolder(
    folderId: string,
    venueId: number,
    searchString?: string,
    filterUsedSongs?: boolean,
    pageNo?: number,
    pageSize?: number,
    orderBy?: string,
    desc: boolean = false,
    tagIds?: number[],
    scrollTo?: string,
    forced = false
  ) {
    const parametersToPopulate = `${folderId}, ${venueId}, ${searchString}, ${filterUsedSongs}, ${pageNo}, ${pageSize}, ${orderBy}, ${desc}, ${tagIds?.join(
      ','
    )}, ${scrollTo}, ${forced}`;
    if (this.lastParametersToPopulate === parametersToPopulate && !forced) {
      return;
    }
    // This will cancel the previous request as now we are making a new request that will override the previous one
    this.cancelFolderLoad$.next(0);
    this.lastParametersToPopulate = parametersToPopulate;
    console.log('populateSongsInFolder ' + this.lastParametersToPopulate);

    this.isPlaylist = this.router.url === '/playlist';
    if (this.isPlaylist && this.currentVenue === 0) return;
    let scrollToTop = true;
    if (this.currentPage === pageNo) {
      scrollToTop = false;
    }
    this.songsLoading = false;
    this.libraryService
      .getSongsInFolder(
        folderId,
        venueId,
        searchString,
        pageNo,
        pageSize,
        orderBy,
        desc,
        tagIds,
        filterUsedSongs
      )
      .pipe(take(1), takeUntil(this.cancelFolderLoad$))
      .subscribe((data) => {
        this.folderItemsCount = data.totalCount;
        this.folderPageCount = data.totalCount / this.pageSize;
        if (!this.filterLabels) {
          this.filterLabels = this.labels.map((l) => new FilterLabel(l, false));
        }
        this.folderItemNodes = data.items.map((item) => {
          return {
            label: 'musicItem',
            type: 'musicItem',
            opacity: 1,
            data: {
              id: item.id,
              artist: item.artist,
              title: item.title,
              duration: item.duration,
              normalized: item.normalized,
              isUsedInPlaylist: item.isUsedInPlaylist,
              songProperties: item.songProperties,
              labels: item.labels,
            } as Song,
          };
        });
        const tagIds = this.orderedFilterLabels
          .filter((f) => f.isSelected)
          .map((s) => s.label.id);
        if (
          this.folderItemNodes.length === 0 &&
          this.selectedNode.parent != null &&
          this.isSongDatabase &&
          searchString === '' &&
          tagIds.length === 0
        ) {
          this.showUpload = true;
        } else {
          this.showUpload = false;
        }
        this.songsLoading = false;

        // put this action in the back of the event loop, to give some time to the UI controls to be created
        setTimeout(() => {
          if (
            this.musicItemsTree !== undefined &&
            this.treeContainer?.nativeElement.offsetHeight
          ) {
            const parentHeight = this.treeContainer.nativeElement.offsetHeight;

            if (this.folderItemsCount > 50) {
              const treeHeight = parentHeight - 35; // Subtract 50px from the parent height
              this.musicItemsTree.scrollHeight = `${treeHeight}px`;
              this.folderTree.scrollHeight = `${treeHeight}px`;
              console.warn(`2: ${treeHeight}`);
            } else {
              console.warn(`1: ${parentHeight}`);
              this.musicItemsTree.scrollHeight = `${parentHeight}px`;
              this.folderTree.scrollHeight = `${parentHeight}px`;
            }
          }
          if (this.playlistTree !== undefined) {
            const parentHeight = this.treeContainer.nativeElement.offsetHeight;
          }

          if (this.musicItemsTree !== undefined && scrollToTop)
            if (scrollTo == 'top') {
              this.musicItemsTree.scrollTo({ top: 0 });
            }

          if (this.playlistTree !== undefined) {
            const parentHeight = this.treeContainer.nativeElement.offsetHeight;

            if (this.folderItemsCount > 50) {
              const treeHeight = parentHeight - 35; // Subtract 50px from the parent height
              this.playlistTree.nativeElement.style.height = `${treeHeight}px`;
            } else {
              this.playlistTree.nativeElement.style.height = `${parentHeight}px`;
            }
          }
        }, 50);
      });
  }
  nodeDragStart(event, node) {
    if (
      event.currentTarget &&
      event.currentTarget.children &&
      event.currentTarget.children[0]
    ) {
      node.opacity = 0.4;
    }
    this.playlistService.setSelectedItem(node);
  }

  onItemDragged(event: any) {}
  dragLeave(event: any, node: any) {
    node.opacity = 1.0;
  }

  nodeDragEnd(event, node: any) {
    node.opacity = 1.0;
  }

  removeSongEvent(event) {
    if (event.libraryType === 'CustomerLibrary')
      this.dashboardService.removeItemFromTree(event);
  }

  removeSongFromVenueFolder(songId) {
    if (this.libraryService instanceof VenueLibraryService) {
      this.folderItemNodes = this.folderItemNodes.filter(
        (item) => item.data.id !== songId
      );
      this.libraryService
        .deleteSongFromFolder(this.selectedNode.data, songId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result) => {
            //console.log('removeSongFromVenueFolder', result);
            this.processDeleteSongResult(result);
            const team = this.teamService.getSelectedTeam();
            this.venueLibraryService.downloadFoldersForTeam(team!.id);
            this.getLibrary();

            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              title: 'Song has been removed from the folder',
              icon: 'info',
              iconColor: 'black',
              background: '#21f367CC',
            });
          },
          (error) => {
            const errorMessage = error.error.substring(
              error.error.indexOf(':') + 1,
              error.error.indexOf('\n')
            );
            this.getLibrary();
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              title: errorMessage,
              icon: 'error',
              iconColor: 'black',
              background: '#2196F3CC',
            });
          }
        );
    }
  }

  processDeleteSongResult(deleteResult: DeleteSongResponse) {
    if (deleteResult?.usedInPlaylists.length > 0) {
      this.usedInPlaylists = deleteResult.usedInPlaylists;
      //console.log('usedInPlaylists', this.usedInPlaylists);
      //this.activeModal.close();
    }
  }
}
