import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSecondFormat'
})
export class MinuteSecondFormatPipe implements PipeTransform {

  transform(value: number): string {
    if(value === null || value === undefined ) return '';
    const datePipe = new DatePipe('en-US');
    const date = new Date(value * 1000); // convert seconds to milliseconds
    return datePipe.transform(date, 'mm:ss.SS')?? '';
  }

}
