import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Song } from 'src/app/views/shared/models/song';

@Injectable({
  providedIn: 'root',
})
export class MiniPlayerService {
  public playing = new Subject<SongPlayingStatus>();

  song: Song;
  // add observable of song

  private song$ = new BehaviorSubject<Song | null>(null);
  private stopPlayer$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  setSong(song: Song) {
    this.song = song;
    this.song$.next(this.song);
  }

  getSong() {
    return this.song;
  }

  songUpdates(): Observable<Song | null> {
    return this.song$.asObservable();
  }

  stopPlayer() {
    this.stopPlayer$.next(true);
    this.song$.next(null);
  }

  stopPlayerUpdates(): Observable<boolean> {
    return this.stopPlayer$.asObservable();
  }
}

export class SongPlayingStatus {
  constructor(public song: Song, public isPlaying: boolean) {}
}
